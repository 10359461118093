import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Form, Input, Label, Image } from 'semantic-ui-react';
import { isEqual, eq } from 'lodash';
import formatter from '../../../utils/formatter';
import { FadeInView } from '../../FadeInView';
import i18n from '../utils/i18n.json';
import IMG_SEE_ON from '../assets/seeOn.png';
import IMG_SEE_OFF from '../assets/seeOff.png';

const {
  errorRequired,
  errorConfirm,
  errorNotMatch,
  errorMinCharacters,
  errorConfirm2,
  errorConfirm3,
  labelConfirm,
} = i18n.confirmPassword;

const { Row, Column } = Grid;


class ConfirmPasswordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      secondValue: '',
      valid: false,
      message: errorRequired[props.language].replace('#label',props.label.toLowerCase()),
      dirty: false,
      firstErrorVisible: false,
      secondErrorVisible: false,
      seePassword: false,
      firstInputFocused: false
    };
  }


  // -----------------------------
  // ------ life cycle events ----
  // -----------------------------
  componentDidMount() {
    this.setForm();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !isEqual(nextState, this.state) || !isEqual(clean(nextProps), clean(this.props));
  }

  componentDidUpdate(prevProps, prevState) {
    if (!eq(prevState, this.state)) {
      this.setForm();
    }
  }


  // -----------------------
  // ------ user events ----
  // -----------------------
  onChange(e) {
    if (!e.target.value) {
      this.setState({
        value: '',
        valid: false,
        message: errorRequired[this.props.language].replace('#label',this.props.label.toLowerCase()),
        firstErrorVisible: this.props.showErrors,
        secondErrorVisible: false,
      });
    } else if (!this.props.max || (this.props.max && e.target.value.length <= this.props.max)) {
      this.setState({ value: e.target.value, secondErrorVisible: false });

      if (this.state.dirty && e.target.value !== this.state.secondValue) {
        this.setState({
          valid: false,
          message: errorNotMatch[this.props.language],
          firstErrorVisible: false,
          secondErrorVisible: this.props.showErrors,
        });
      } else if (e.target.value === this.state.secondValue) {
        this.setState({
          valid: true,
          message: '',
          firstErrorVisible: false,
          secondErrorVisible: false,
        });
      }
    }
  }

  onBlur() {
    const { value } = this.state;
    this.setState({ firstInputFocused: false });

    if (!value) {
      this.setState({
        valid: false,
        message: errorRequired[this.props.language].replace('#label',this.props.label.toLowerCase()),
        firstErrorVisible: this.props.showErrors,
        secondErrorVisible: false,
      });
    } else if (this.props.min && value.length < this.props.min) {
      const error = errorMinCharacters[this.props.language].replace('#label',this.props.label.toLowerCase());
      this.setState({
        valid: false,
        message: error.replace('#min',this.props.min),
        firstErrorVisible: this.props.showErrors,
        secondErrorVisible: false,
      });
    } else if (value && !this.state.secondValue) {
      this.setState({
        valid: false,
        message: errorConfirm[this.props.language],
        firstErrorVisible: false,
        secondErrorVisible: this.props.showErrors,
      });
    }
  }

  onSecondChange(e) {
    this.setState({ secondValue: e.target.value, dirty: true });

    // validar inputs
    if (this.state.value !== '' && this.state.value === e.target.value) {
      this.setState({
        valid: true,
        message: '',
        firstErrorVisible: false,
        secondErrorVisible: false,
      });
    } else {
      this.setState({
        valid: false,
        message: errorNotMatch[this.props.language],
        firstErrorVisible: false,
        secondErrorVisible: this.props.showErrors,
      });
    }
  }

  onSecondBlur() {
    const { secondValue } = this.state;

    if (!secondValue) {
      this.setState({
        valid: false,
        message: errorConfirm2[this.props.language],
        firstErrorVisible: false,
        secondErrorVisible: this.props.showErrors,
      });
    } else if (this.props.min && secondValue.length < this.props.min) {
      this.setState({
        valid: false,
        message: errorConfirm3[this.props.language].replace('#min',this.props.min),
        firstErrorVisible: false,
        secondErrorVisible: this.props.showErrors,
      });
    }
  }

  setForm() {
    this.props.setFormData(this.props.name, this.state);
  }

  getValue() {
    return this.state.value;
  }

  dirtInput() {
    this.setState({ dirty: true });
  }

  resetInput() {
    this.setState({
      value: '',
      secondValue: '',
      valid: false,
      message: errorRequired[this.props.language].replace('#label',this.props.label.toLowerCase()),
      dirty: false,
      firstErrorVisible: false,
      secondErrorVisible: false,
    });
  }

  showError() {
    this.setState({ firstErrorVisible: true });
  }


  // --------------------------
  // ------ render methods ----
  // --------------------------
  renderFirstErrorLabel() {
    const { firstErrorVisible, firstInputFocused, message } = this.state;

    if (firstErrorVisible && !firstInputFocused) {
      return (
        <FadeInView style={styles.popUpContainer}>
          <Label basic pointing color="red" style={styles.popUpErrorLabel}>
            { message }
          </Label>
        </FadeInView>
      );
    }

    return null;
  }

  renderSecondErrorLabel() {
    const { secondErrorVisible, message } = this.state;

    if (secondErrorVisible) {
      return (
        <FadeInView style={styles.popUpContainer}>
          <Label basic pointing color="red" style={styles.popUpErrorLabel}>
            { message }
          </Label>
        </FadeInView>
      );
    }

    return null;
  }

  renderInputPassword() {
    const invalidInput = this.state.dirty && !this.state.valid;

    let className = '';
    let finalInputStyle = { ...styles.input, ...this.props.inputStyle };
    let styleInputInside = {};

    if (this.props.className) {
      className = `${this.props.className} ${invalidInput ? 'invalid' : 'valid'}`;
    } else {
      className = invalidInput ? 'invalid' : 'valid';
    }

    if (invalidInput) {
      finalInputStyle = { ...this.props.inputStyle, ...styles.errorInput };
      styleInputInside = { ...styles.errorInputInside };
    }

    const IMG_SEE = this.state.seePassword ? IMG_SEE_ON : IMG_SEE_OFF;
    const typeString = this.state.seePassword ? 'text' : 'password';

    return (
      <Grid textAlign='right' style={{ padding: 14 }}>
        <Row style={{ border: '1px solid #DEDEDF', padding: 0, borderRadius: 4, ...finalInputStyle }}>
          <Column width={13} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <input
              type={typeString}
              value={this.state.value}
              name={this.props.name}
              className={className}
              onChange={this.onChange.bind(this)}
              onBlur={this.onBlur.bind(this)}
              placeholder={this.props.placeholder}
              style={{ border: 'none', ...styleInputInside }}
              onFocus={() => { this.setState({ firstInputFocused: true }); }}
            />
          </Column>

          <Column
            width={3}
            style={{ paddingRight: 0, paddingTop: 10, ...styleInputInside }}
            onClick={() => this.setState({ seePassword: !this.state.seePassword })}
          >
            <Image src={IMG_SEE} style={{ width: 30, cursor: 'pointer' }}/>
          </Column>
        </Row>
      </Grid>
    );
  }

  renderInputConfirmPassword() {
    const invalidInput = this.state.dirty && !this.state.valid;
    let className = '';
    let finalInputStyle = { ...styles.input, ...this.props.inputStyle };
    let styleInputInside = {};

    if (this.props.className) {
      className = `${this.props.className} ${invalidInput ? 'invalid' : 'valid'}`;
    } else {
      className = invalidInput ? 'invalid' : 'valid';
    }

    if (invalidInput) {
      finalInputStyle = { ...this.props.inputStyle, ...styles.errorInput };
      styleInputInside = { ...styles.errorInputInside };
    }

    const IMG_SEE = this.state.seePassword ? IMG_SEE_ON : IMG_SEE_OFF;
    const typeString = this.state.seePassword ? 'text' : 'password';

    return (
      <Grid textAlign='right' style={{ padding: 14 }}>
        <Row style={{ border: '1px solid #DEDEDF', padding: 0, borderRadius: 4, ...finalInputStyle }}>
          <Column width={13} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <input
              type={typeString}
              value={this.state.secondValue}
              name={`confirmacion${this.props.name}`}
              className={className}
              onChange={this.onSecondChange.bind(this)}
              onBlur={this.onSecondBlur.bind(this)}
              placeholder={this.props.secondPlaceholder}
              style={{ border: 'none', ...styleInputInside }}
            />
          </Column>

          <Column
            width={3}
            style={{ paddingRight: 0, paddingTop: 10, ...styleInputInside }}
            onClick={() => this.setState({ seePassword: !this.state.seePassword })}
          >
            <Image src={IMG_SEE} style={{ width: 30, cursor: 'pointer' }}/>
          </Column>
        </Row>
      </Grid>
    );
  }

  renderInputs() {
    const invalidInput = this.state.dirty && !this.state.valid;
    let className = '';
    let finalLabelStyle = { ...styles.label, ...this.props.labelStyle };
    let finalInputStyle = { ...styles.input, ...this.props.inputStyle };
    const secondLabel = this.props.secondLabel ? `${this.props.secondLabel} *` : '';

    if (this.props.className) {
      className = `${this.props.className} ${invalidInput ? 'invalid' : 'valid'}`;
    } else {
      className = invalidInput ? 'invalid' : 'valid';
    }

    if (invalidInput) {
      finalLabelStyle = { ...this.props.labelStyle, ...styles.errorLabel };
      finalInputStyle = { ...this.props.inputStyle, ...styles.errorInput };
    }

    if (this.props.display === 'row' && !this.props.inlineLabel) {
      return [
        <Row key={1}>
          <Column textAlign="left">
            <label style={{ ...finalLabelStyle, fontWeight: 'bold' }}>
              { this.props.label } *
            </label>

            { this.renderInputPassword() }
            { this.renderFirstErrorLabel() }
          </Column>
        </Row>,
        <Row key={2}>
          <Column textAlign="left">
            <label style={{ ...finalLabelStyle, fontWeight: 'bold' }}>
              { secondLabel || labelConfirm[this.props.language] }
            </label>

            { this.renderInputConfirmPassword() }
            { this.renderSecondErrorLabel() }
          </Column>
        </Row>,
      ];
    }

    if (this.props.display === 'row' && this.props.inlineLabel) {
      return [
        <Row key={1}>
          <Column textAlign="left">
            <Input
              label={this.props.label}
              type="password"
              value={this.state.value}
              name={this.props.name}
              className={className}
              onChange={this.onChange.bind(this)}
              onBlur={this.onBlur.bind(this)}
              placeholder={this.props.placeholder}
              style={finalInputStyle}
            />

            { this.renderFirstErrorLabel() }
          </Column>
        </Row>,
        <Row key={2}>
          <Column textAlign="left">
            <Input
              label={secondLabel || labelConfirm[this.props.language]}
              type="password"
              value={this.state.secondValue}
              name={`confirmacion${this.props.name}`}
              className={className}
              onChange={this.onSecondChange.bind(this)}
              onBlur={this.onSecondBlur.bind(this)}
              placeholder={this.props.secondPlaceholder}
              style={finalInputStyle}
            />

            { this.renderSecondErrorLabel() }
          </Column>
        </Row>,
      ];
    }

    if (this.props.display === 'column' && !this.props.inlineLabel) {
      return (
        <Row>
          <Column width={8} textAlign="left">
            <label style={{ ...finalLabelStyle, fontWeight: 'bold' }}>
              { this.props.label } *
            </label>

            { this.renderInputPassword() }
            { this.renderFirstErrorLabel() }
          </Column>

          <Column width={8}>
            <label style={{ ...finalLabelStyle, fontWeight: 'bold' }}>
              { secondLabel || labelConfirm[this.props.language] }
            </label>

            { this.renderInputConfirmPassword() }
            { this.renderSecondErrorLabel() }
          </Column>
        </Row>
      );
    }

    if (this.props.display === 'column' && this.props.inlineLabel) {
      return (
        <Row>
          <Column width={8} textAlign="left">
            <Input
              label={`${this.props.label} *`}
              type="password"
              value={this.state.value}
              name={this.props.name}
              className={className}
              onChange={this.onChange.bind(this)}
              onBlur={this.onBlur.bind(this)}
              placeholder={this.props.placeholder}
              style={finalInputStyle}
            />

            { this.renderFirstErrorLabel() }
          </Column>

          <Column width={8}>
            <Input
              label={ secondLabel || labelConfirm[this.props.language]}
              type="password"
              value={this.state.secondValue}
              name={`confirmacion${this.props.name}`}
              className={className}
              onChange={this.onSecondChange.bind(this)}
              onBlur={this.onSecondBlur.bind(this)}
              placeholder={this.props.secondPlaceholder}
              style={finalInputStyle}
            />

            { this.renderSecondErrorLabel() }
          </Column>
        </Row>
      );
    }

    return null;
  }

  render() {
    return (
      <Form.Field>
        <Grid stackable>
          { this.renderInputs() }
        </Grid>
      </Form.Field>
    );
  }
}


// estilos
const styles = {
  label: {

  },
  errorLabel: {
    color: '#9F3A38',
  },
  input: {

  },
  errorInput: {
    background: 'rgba(224, 180, 180, 0.48)',
    border: '1px solid #9F3A38',
    color: '#9F3A38',
  },
  errorInputInside: {
    background: 'rgba(224, 180, 180, 0.48)',
    color: '#9F3A38',
  },
  popUpContainer: {
    position: 'absolute',
    top: 55,
    left: 0,
    zIndex: 100,
  },
  popUpErrorLabel: {
    fontSize: 13,
    textAlign: 'center',
    boxShadow: 'rgba(100, 100, 100, 0.25) 0px 2px 4px',
  },
  strength: {
    borderBottom: '5px solid rgb(200,200,200)',
    transition: 'all 250ms ease'
  }
};


ConfirmPasswordInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  secondLabel: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  secondPlaceholder: PropTypes.string.isRequired,
  display: PropTypes.oneOf(['row', 'column']).isRequired,
  inlineLabel: PropTypes.bool,
  showErrors: PropTypes.bool,
  className: PropTypes.string,
  labelStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  setFormData: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  language: PropTypes.string,
};


// exportar componente
export default ConfirmPasswordInput;
