import axios from 'axios';
import { API_URL } from '../../../utils/constants';

export function userLogin(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/users/login`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
}

export function caprepaLogin(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/users/caprepa/login`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
}

