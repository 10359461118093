import { call, put } from 'redux-saga/effects';
import * as calls from '../calls/index.js';
import * as actionsTypes from '../../store/types';
import notifications from '../../../../utils/notifications';
import eventManager from '../../../../utils/eventManager';


export function* getPhonesCat(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesData', value: true } });

    const request = calls.getPhonesCat(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesData', value: false } });
      yield put({ type: actionsTypes.SET_PHONES, payload: serverResponse.data });
    } else {
      notifications.error('Datos no encontrados');
      yield put({ type: actionsTypes.UNSET_PHONES, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesData', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getPhonesFullCat(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesFullData', value: true } });

    const request = calls.getPhonesFullCat(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesFullData', value: false } });
      yield put({ type: actionsTypes.SET_ALL_PHONES, payload: serverResponse.data });
    } else {
      notifications.error('Datos no encontrados');
      yield put({ type: actionsTypes.UNSET_ALL_PHONES, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesFullData', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getPhoneByImei(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phoneByImei', value: true } });

    const request = calls.getPhoneByImei(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phoneByImei', value: false } });
      yield put({ type: actionsTypes.SET_PHONE_BY_IMEI, payload: serverResponse.data  });
    } else {
      notifications.error(serverResponse.message);
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phoneByImei', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  };
};

export function* getPhoneDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phoneDetail', value: true } });

    const request = calls.getPhoneDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phoneDetail', value: false } });
      yield put({ type: actionsTypes.SET_PHONE_DETAIL, payload: serverResponse.data });
    } else {
      notifications.error('Teléfono no encontrado');
      yield put({ type: actionsTypes.SET_PHONE_DETAIL, payload: null });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phoneDetail', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getPhonesArray(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesDetail', value: true } });

    const request = calls.getPhonesArray(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesDetail', value: false } });
      yield put({ type: actionsTypes.SET_PHONE_DETAIL_ARRAY, payload: serverResponse.data });
    } else {
      notifications.error('Teléfono no encontrado');
      yield put({ type: actionsTypes.SET_PHONE_DETAIL_ARRAY, payload: null });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesDetail', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getPhonesByScore(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phoneByScore', value: true } });

    const request = calls.getPhonesByScore(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phoneByScore', value: false } });
      yield put({ type: actionsTypes.SET_PHONE_BY_SCORE, payload: serverResponse.data });
    } else {
      notifications.error(serverResponse.message);
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phoneByScore', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getPhonesByBrand(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesByBrand', value: true } });

    const request = calls.getPhonesByBrand(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesByBrand', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_BY_BRAND, payload: serverResponse.data });
    } else {
      notifications.error(serverResponse.message);
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesByBrand', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getPhonesBrandsCat(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'brands', value: true } });

    const request = calls.getPhonesBrandsCat(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'brands', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_BRANDS, payload: serverResponse.data });
    } else {
      notifications.error('Marcas no encontradas');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'brands', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getPhonesBrandsWarr(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyBrands', value: true } });

    const request = calls.getPhonesBrandsWarr(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyBrands', value: false } });
      yield put({ type: actionsTypes.SET_WARRANTY_PHONES_BRANDS, payload: serverResponse.data });
    } else {
      notifications.error('Marcas no encontradas');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyBrands', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getPhonesStoragesCat(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'storages', value: true } });

    const request = calls.getPhonesStoragesCat(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'storages', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_STORAGES, payload: serverResponse.data });
    } else {
      notifications.error('Modelos no encontrados');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'storages', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getPhonesRearCamerasCat(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'rear_cameras', value: true } });

    const request = calls.getPhonesRearCamerasCat(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'rear_cameras', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_REAR_CAMERAS, payload: serverResponse.data });
    } else {
      notifications.error('cameras no encontrados');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'rear_cameras', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getPhonesFrontalCamerasCat(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'frontal_cameras', value: true } });

    const request = calls.getPhonesFrontalCamerasCat(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'frontal_cameras', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_FRONTAL_CAMERAS, payload: serverResponse.data });
    } else {
      notifications.error('cameras no encontrados');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'frontal_cameras', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getPhonesSIMsCat(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'SIMs', value: true } });

    const request = calls.getPhonesSIMsCat(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'SIMs', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_SIMS, payload: serverResponse.data });
    } else {
      notifications.error('SIMs no encontrados');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'SIMs', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getPhonesOSsCat(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'OSs', value: true } });

    const request = calls.getPhonesOSsCat(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'OSs', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_OS, payload: serverResponse.data });
    } else {
      notifications.error('Modelos no encontrados');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'OSs', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getPhonesScreensCat(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'screens', value: true } });

    const request = calls.getPhonesScreensCat(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'screens', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_SCREENS, payload: serverResponse.data });
    } else {
      notifications.error('screens no encontrados');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'screens', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getPhonesServicesCat(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'services', value: true } });

    const request = calls.getPhonesServicesCat(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'services', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_SERVICES, payload: serverResponse.data });
    } else {
      notifications.error('services no encontrados');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'services', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* savePhone(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savePhone', value: true } });

    const request = calls.savePhone(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savePhone', value: false } });
      notifications.success('Teléfono guardado');
      eventManager.emit('saved-phone', action.payload.imei);
    } else {
      notifications.error(serverResponse.message);
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savePhone', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* savePhoneData(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingPhoneData', value: true } });

    const request = calls.savePhoneData(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingPhoneData', value: false } });
      yield put({ type: actionsTypes.SET_PHONE_DATA, payload: serverResponse.data });
      eventManager.emit('saved-phonedata', serverResponse.data);
    } else {
      notifications.error(serverResponse.message);
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingPhoneData', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getCategoryPhonesByBrand(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'categoryphonebybrandData', value: true } });

    const request = calls.getCategoryPhonesByBrand(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;
    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'categoryphonebybrandData', value: false } });
      yield put({ type: actionsTypes.SET_CAT_PHONE_BY_BRAND, payload: serverResponse.data  });
    } else {
      notifications.error('Error al traer informacion');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'sepomexInfo', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  };
};
