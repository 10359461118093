import { takeLatest, all } from 'redux-saga/effects';
import * as actionsTypes from '../store/types';
import * as workers from './workers';

export default function* watcherSaga() {
  yield all([
    takeLatest(actionsTypes.GET_USER_BY_SESSION, workers.getUserDataBySession),
    takeLatest(actionsTypes.SAVE_SUPPLIER, workers.saveSupplier),
    takeLatest(actionsTypes.GET_SUPPLIERS_TABLE, workers.getSuppliersTable),
    takeLatest(actionsTypes.GET_SUPPLIER_DETAIL, workers.getSupplierDetail),
    takeLatest(actionsTypes.GET_SUPPLIERS_TYPES, workers.getSuppliersTypes),
    takeLatest(actionsTypes.GET_SUPPLIERS_CATEGORIES, workers.getSuppliersCategories),
    takeLatest(actionsTypes.GET_SUPPLIERS_SUBCATEGORIES, workers.getSuppliersSubcategories),
    takeLatest(actionsTypes.GET_SUPPLIER_ADDRESSES, workers.getSupplierAddresses),
    takeLatest(actionsTypes.SAVE_SUPPLIER_ADDRESS, workers.saveSupplierAddress),
    takeLatest(actionsTypes.DELETE_SUPPLIER_ADDRESS, workers.deleteSupplierAddress),
    takeLatest(actionsTypes.GET_SEPOMEX_INFO, workers.getSepomexInfo),
    takeLatest(actionsTypes.UPDATE_SUPPLIER_ADDRESSES, workers.updateSupplierAddresses),
    takeLatest(actionsTypes.GET_SUPPLIER_CONTACTS, workers.getSupplierContacts),
    takeLatest(actionsTypes.SAVE_SUPPLIER_CONTACT, workers.saveSupplierContact),
    takeLatest(actionsTypes.DELETE_SUPPLIER_CONTACT, workers.deleteSupplierContact),
    takeLatest(actionsTypes.UPDATE_SUPPLIER_CONTACTS, workers.updateSupplierContacts),
    takeLatest(actionsTypes.GET_CONTACT_TYPES, workers.getContactTypes),
    takeLatest(actionsTypes.SAVE_CONTACT_TYPE, workers.saveContactType),
    takeLatest(actionsTypes.SAVE_SUPPLIERS_TYPES, workers.saveSupplierType),
    takeLatest(actionsTypes.GET_ALL_SUPPLIERS_CATEGORIES, workers.getAllSuppliersCategories),
    takeLatest(actionsTypes.SAVE_SUPPLIERS_CATEGORIES, workers.saveSupplierCategory),
    takeLatest(actionsTypes.GET_SUPPLIERS_CATEGORY_DETAIL, workers.getSupplierCategoryDetail),
    takeLatest(actionsTypes.GET_ALL_SUPPLIERS_SUBCATEGORIES, workers.getAllSuppliersSubCategories),
    takeLatest(actionsTypes.SAVE_SUPPLIERS_SUBCATEGORIES, workers.saveSupplierSubCategory),
    takeLatest(actionsTypes.GET_SUPPLIERS_TYPES_DETAIL, workers.getSuppliersTypeDetail),
    takeLatest(actionsTypes.DELETE_SUPPLIERS_TYPES, workers.deleteSupplierType),
    takeLatest(actionsTypes.DELETE_SUPPLIERS_CATEGORIES, workers.deleteSupplierCategory),
    takeLatest(actionsTypes.DELETE_SUPPLIERS_SUBCATEGORIES, workers.deleteSupplierSubCategory),
    takeLatest(actionsTypes.GET_ADITIONAL_FORM, workers.getAditionalFormData),

    takeLatest(actionsTypes.GET_INVENTORY, workers.getInvetory),
    takeLatest(actionsTypes.GET_BRANDS, workers.getBrands),
    takeLatest(actionsTypes.GET_MODELS, workers.getModels),
    takeLatest(actionsTypes.GET_PHONE_DETAIL, workers.getPhoneDetail),
    takeLatest(actionsTypes.SAVE_PHONE, workers.savePhone),
    takeLatest(actionsTypes.SAVE_BRAND, workers.saveBrand),
    takeLatest(actionsTypes.DELETE_PHONE_IMAGE, workers.deletePhoneImage),
    // =====================================================
    takeLatest(actionsTypes.GET_PAYROLL_APPLICATIONS, workers.getPayrollApplications),
    takeLatest(actionsTypes.UPDATE_PAYROLL_APPLICATION, workers.updatePayrollApplication),

    takeLatest(actionsTypes.GET_PAYROLL_PAYMENTS, workers.getPayrollPayments),
    takeLatest(actionsTypes.UPDATE_PAYROLL_PAYMENTS, workers.updatePayrollPayments),
    takeLatest(actionsTypes.SAVE_PAYROLL_PAYMENTS_BY_FILE, workers.savePayrollPaymentsByFile),
    takeLatest(actionsTypes.SAVE_INVENTORY_BY_FILE, workers.saveInventoryByFile),

    // =====================================================
    takeLatest(actionsTypes.GET_CAPITAL_APPLICATIONS, workers.getCapitalApplications),
    takeLatest(actionsTypes.UPDATE_CAPITAL_APPLICATION, workers.updateSupplierContacts),

    // =====================================================
    takeLatest(actionsTypes.GET_CAPREPA_APPLICATIONS, workers.getCaprepaApplications),
    takeLatest(actionsTypes.UPDATE_CAPREPA_APPLICATION, workers.updateCaprepaApplication),

    // =====================================================
    takeLatest(actionsTypes.GET_PHONES_STORAGES, workers.getPhonesStorage),
    takeLatest(actionsTypes.GET_PHONES_FRONTAL_CAMERAS, workers.getPhonesFrontalCamera),
    takeLatest(actionsTypes.GET_PHONES_REAR_CAMERAS, workers.getPhonesRearCamera),
    takeLatest(actionsTypes.GET_PHONES_SIMS, workers.getPhonesSIM),
    takeLatest(actionsTypes.GET_PHONES_OS, workers.getPhonesOS),
    takeLatest(actionsTypes.GET_PHONES_SCREENS, workers.getPhonesScreen),
    takeLatest(actionsTypes.GET_PHONES_SERVICES, workers.getPhonesService),

    // =====================================================
    takeLatest(actionsTypes.GET_USER_DETAIL, workers.getUsersDetail),
    takeLatest(actionsTypes.SAVE_USER, workers.saveUser),
    takeLatest(actionsTypes.GET_BRANCHES_LIST, workers.getBranchesList),
    takeLatest(actionsTypes.SAVE_BRANCH, workers.saveBranch),
    takeLatest(actionsTypes.GET_ROLES_LIST, workers.getRolesList),
    takeLatest(actionsTypes.GET_ROLE_DETAIL, workers.getRoleDetail),
    takeLatest(actionsTypes.GET_BRANCH_DETAIL, workers.getBranchDetail),
    takeLatest(actionsTypes.GET_ALL_WARRANTY_STATUS, workers.getAllWarrantyStatus),
    takeLatest(actionsTypes.SAVE_ROLE, workers.saveRole),
    takeLatest(actionsTypes.GET_REGION_LIST, workers.getRegionsList),
    takeLatest(actionsTypes.GET_FINANCINGS_DATA, workers.getFinancingsData),
    takeLatest(actionsTypes.GET_FINISHED_APPLICATIONS, workers.getFinishedApplications),
    takeLatest(actionsTypes.UPDATE_CONTRACT_STATUS, workers.updateContractStatus),

    // =====================================================
    takeLatest(actionsTypes.GET_EMAIL_USER_DETAIL, workers.getEmailsUsersDetail),

    // =====================================================
    takeLatest(actionsTypes.SAVE_TIERS, workers.saveTiers),

    // =====================================================
    takeLatest(actionsTypes.GET_BRANDS_LIST, workers.getBrandsList),
    takeLatest(actionsTypes.SAVE_EMAIL_USER, workers.saveEmailUser),
    takeLatest(actionsTypes.GET_COLAB_APPLICATIONS, workers.getColabApplications),

    takeLatest(actionsTypes.GET_TIERS, workers.getTiers),



  ]);
}
