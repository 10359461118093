import axios from 'axios';
import { API_URL } from '../../../../utils/constants';


export function getSupplierContacts(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/contacts/by-supplier`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function saveSupplierContact(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/contacts/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function deleteSupplierContact(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/contacts/delete`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function updateSupplierContacts(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/contacts/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getContactTypes(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/suppliers/contacts-types/get-all`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function saveContactType(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/contacts-types/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};
