import axios from 'axios';
import { API_URL } from '../../../../utils/constants';


export function saveBranch(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/branches/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getBranchesList(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/branches/get-all`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getBranchDetail(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/branches/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};