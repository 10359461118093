import React, { Component } from 'react';
import { List, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { FadeInView } from './FadeInView';
import formatter from '../utils/formatter';


class PasswordStrength extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !isEqual(clean(nextProps), clean(this.props));
  }

  hasLowerCase() {
    const { password } = this.props;
    return /[a-z]/.test(password);
  }

  hasUpperCase() {
    const { password } = this.props;
    return /[A-Z]/.test(password);
  }

  hasNumber() {
    const { password } = this.props;
    return /[0-9]/.test(password);
  }

  hasSpecialCharacter() {
    const { password } = this.props;
    return /[!@#$%^&*()\-_=? ]/.test(password);
  }

  hasThreeRules() {
    const hasLowerCase = this.hasLowerCase();
    const hasUpperCase = this.hasUpperCase();
    const hasNumber = this.hasNumber();
    const hasSpecialCharacter = this.hasSpecialCharacter();
    let count = 0;

    if (hasLowerCase) {
      count += 1;
    }

    if (hasUpperCase) {
      count += 1;
    }

    if (hasNumber) {
      count += 1;
    }

    if (hasSpecialCharacter) {
      count += 1;
    }

    return count > 2;
  }

  isValidLength() {
    const { password } = this.props;
    return password.length > 7;
  }

  isValid() {
    return this.isValidLength() && this.hasThreeRules();
  }

  render() {
    const { password, style } = this.props;

    if (!password) {
      return <div />;
    }

    const validLength = this.isValidLength();
    const hasLowerCase = this.hasLowerCase();
    const hasUpperCase = this.hasUpperCase();
    const hasNumber = this.hasNumber();
    const hasSpecialCharacter = this.hasSpecialCharacter();
    const hasThreeRules = this.hasThreeRules();

    return (
      <div style={style}>
        <FadeInView>
          <Message>
            <Message.Header>
              Your password must contain:
            </Message.Header>

            <List>
              <List.Item>
                <List.Icon
                  name={validLength ? 'check circle' : 'remove circle'}
                  style={{ color: validLength ? 'green' : 'gray' }}
                />

                <List.Content>
                  At least 8 characters
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Icon
                  name={hasThreeRules ? 'check circle' : 'remove circle'}
                  style={{ color: hasThreeRules ? 'green' : 'gray' }}
                />

                <List.Content>
                  At least 3 of the following:

                  <List>
                    <List.Item style={{ marginBottom: 2 }}>
                      <List.Icon
                        name={hasLowerCase ? 'check circle' : 'remove circle'}
                        style={{ color: hasLowerCase ? '#7798BF' : 'gray' }}
                      />

                      <List.Content>
                        Lower case letters (a-z)
                      </List.Content>
                    </List.Item>

                    <List.Item style={{ marginBottom: 2 }}>
                      <List.Icon
                        name={hasUpperCase ? 'check circle' : 'remove circle'}
                        style={{ color: hasUpperCase ? '#7798BF' : 'gray' }}
                      />

                      <List.Content>
                        Upper case letters (A-Z)
                      </List.Content>
                    </List.Item>

                    <List.Item style={{ marginBottom: 2 }}>
                      <List.Icon
                        name={hasNumber ? 'check circle' : 'remove circle'}
                        style={{ color: hasNumber ? '#7798BF' : 'gray' }}
                      />

                      <List.Content>
                        Numbers (0-9)
                      </List.Content>
                    </List.Item>

                    <List.Item>
                      <List.Icon
                        name={hasSpecialCharacter ? 'check circle' : 'remove circle'}
                        style={{ color: hasSpecialCharacter ? '#7798BF' : 'gray' }}
                      />

                      <List.Content>
                        Special characters (ex. !@#_)
                      </List.Content>
                    </List.Item>
                  </List>
                </List.Content>
              </List.Item>
            </List>
          </Message>
        </FadeInView>
      </div>
    );
  }
}


PasswordStrength.propTypes = {
  password: PropTypes.string,
  style: PropTypes.object
};


export { PasswordStrength };
