import axios from 'axios';
import { API_URL } from '../../../../utils/constants';

//* Vistas que lo utilizan

// Guarda un nuevo folio de garantías
//* Warranty/AddRequest
//* Warranty/Request
//* Warranty/Swap
export function saveInvoice(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae el listado de todos los status que puede tener una garantía
//* layout
export function getAllWarrantyStatus() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/warrantiesStatus/get-all`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

// Trae el listado de todas las garantías que tengan el status especificado
//* Warranty/Deliver
//* Warranty/Recived
export function getInvoiceTableByStatus(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/by-option`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      iOpcion: 1,
      iValBusq: body.status
    }
  });
};

// Cambia el status asociado al folio de garantías
//* Warranty/Detail
export function changeInvoiceStatus(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/mod`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Cambia el equipo asociado a un folio de garantía por otro indicado
//* Warranty/Swap
export function saveInvoiceSwap(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/swap`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Obtiene el detalle completo de un folio de garantía a través de su IMEI
//* Warranty/Detail
//* Warranty/Search
export function getWarrantyDetail(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/by-imei`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Obtiene los archivos asociados a una garantía especificada mediante un ID
//* Warranty/AddRequest
//* Warranty/DocsPreview
//* Warranty/Request
export function getWarrantyEvidence(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidenceOfGuarantees/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Sube un archivo asociado a una evidencia
//* Warranty/DocsPreview
export function uploadWarrantyEvidence(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidenceOfGuarantees/saveFile`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Descarga un archivo mediante un URL
//* Warranty/AddRequest
//* Warranty/DocsPreview
//* Warranty/Request
export function getWarrantyEvidenceUrl(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidenceOfGuarantees/downloadfile`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Obtiene los registros de logs asociados a la garantía especificada por un ID
//* Warranty/Historic
export function getWarrantyLogs(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/logWarranties/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Obtiene el listado de garantías asociadas a una sucursal
//* Home
export function getBranchWarranties(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/get-warranties-by-branch`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Valida si el IMEI proporcionado está vinculado a una garantía //? NUEVO
//* Warranty/Branch
//* Warranty/Branch/AddRequest
export function getBranchWarranty(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranty-branch/get-phone`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Valida si el IMEI proporcionado está vinculado a un financiamiento //? NUEVO
//*
export function getCustomerWarranty(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/financing/validate-search`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Obtiene los registros de movimientos de garantías asociados a una sucursal
//* Home
export function getBranchLogs(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/logWarranties/get-logs-by-branch`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Guarda una nueva garantía de sucursal
//* Warranty/Branch/AddRequest
export function saveWarrantyBranch(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranty-branch/save`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: body
  });
};

// Trae una garantía asociada a la sucursal donde se dio de alta
//* Warranty/Branch/AddRequest
export function getBranchWarrantyByImei(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/phones/warranty/branch/by-imei`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae los datos asociados a un financiamiento mediante la especificación de un IMEI
//* Warranty/Swap
export function getWarrantySwap(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/by-imei`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae un listado que contiene todas las posibles fallas que puede tener un equipo en garantía
//* Warranty/AddRequest
//* Warranty/Branch/AddRequest
//* Warranty/Detail
//* Warranty/Recoveries/AddRecoveries
//* Warranty/Request
export function getAccesories() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/accesories/get-all`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

// Trae un listado que contiene todas las posibles fallas que puede tener un equipo en garantía
//* Warranty/AddRequest
//* Warranty/Branch/AddRequest
//* Warranty/Detail
//* Warranty/Request
export function getFailures() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/failures/get-all`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

// Trae un listado que contiene todos los posibles síntomas que puede tener un equipo en garantía
//* Warranty/Detail
export function getSymptoms() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/symptoms/get-all`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};



// Actualiza la información de una garantía
//! REVISAR (NO SE USA)
export function updateWarrantyDetail(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/modAllInfo`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};
