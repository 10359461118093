import axios from 'axios';
import { API_URL } from '../../../../utils/constants';

export function getPhonesCat(employee_id) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-cataloge?employee_id=${employee_id}`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

export function getPhoneDetail(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getPhoneBySku(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/by-sku`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getPhonesBrandsCat() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-brand`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

export function getPhonesStoragesCat(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-rom`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getPhonesFrontalCamerasCat(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-frontal-camera`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getPhonesRearCamerasCat(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-rear-camera`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getPhonesSIMsCat(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-sim`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getPhonesOSsCat(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-os`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getPhonesScreensCat(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-screen`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getPhonesServicesCat(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-service`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getTopPhones(employee_id) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/favorites?employee_id=${employee_id}`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export function getActiveBrands() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-brand-active`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

export function getRelatedPhones(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/related`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};
