import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Grid, Divider, Button, Icon } from "semantic-ui-react";
import { BLACK, BLUE, WHITE } from "../colors";

const { Row, Column } = Grid;

class ReusableModal extends Component {
  // ------------------------
  // ---- ON HANDLERS   ----
  // ------------------------
  closeModal() {
    this.props.onClose();
  }


  // ------------------------
  // ---- render methods ----
  // ------------------------

  renderContent() {
    const {
      mobile, title, actionLabel,
      children, noButtons, showDivider,
      showTitle, accentColor
    } = this.props;

    return (
      <div>
        {this.renderClose()}
        <Grid centered>
          {
            showTitle ? (
              <Row centered style={{ paddingTop: 10, paddingBottom: 0 }}>
                <Column
                  width={16}
                  textAlign='center'
                  style={{
                    borderRadius: 10,
                    color: 'black',
                    fontFamily: 'poppins',
                    fontSize: mobile ? 30 : 25
                  }}
                >
                  <span>{title}</span>
                </Column>
              </Row>
            ) : null
          }

          { showDivider ? <Divider /> : null }

          <Row centered>
            <Column width={16} style={{ color: BLACK }}>
              {children}
            </Column>
          </Row>

          {
            noButtons ? null : (
              <Row centered>
                <Column width={16}>
                  <div style={{ width: '100%', textAlign: 'right' }}>
                    <Button
                      onClick={this.props.onCancel ? this.props.onCancel.bind() : this.closeModal.bind(this)}
                      style={{ backgroundColor: WHITE, color: accentColor ? accentColor : BLUE }}>
                      {this.props.cancelLabel ? this.props.cancelLabel : 'Cancelar'}
                    </Button>
                    <Button
                      loading={this.props.loading}
                      disabled={this.props.disabled}
                      onClick={() => { this.props.onClick() }}
                      style={{ backgroundColor: accentColor ? accentColor : BLUE, color: WHITE, borderRadius: 15 }}>
                      {actionLabel}
                    </Button>
                  </div>
                </Column>
              </Row>
            )
          }

        </Grid>
      </div>

    )
  }


  renderClose() {
    return (
      <div
        style={{
          zIndex: 1000,
          position: "absolute",
          top: 10,
          right: 10,
          // background: "rgb(230,230,230)",
          borderRadius: 20,
          cursor: "pointer",
          padding: 15,
          color: BLACK
        }}
        onClick={this.closeModal.bind(this)}
      >
        <div
          style={{ fontSize: 18, position: "absolute", top: 1, left: 10 }}
        >
          <Icon name="times" size="small"/>
        </div>
      </div>
    );
  }

  render() {
    const { visible, style } = this.props;

    return (
      <Modal
        basic
        className="hide-scroll"
        size="large"
        onClose={this.closeModal.bind(this)}
        open={visible}
        style={{
          width: 400,
          borderRadius: 35,
          ...style
        }}
      >
        <Modal.Content>
          {this.renderContent()}
        </Modal.Content>
      </Modal>
    )
  }
}

ReusableModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  mobile: PropTypes.bool,
  title: PropTypes.string,
  actionLabel: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  cancelLabel: PropTypes.string,
  noButtons: PropTypes.bool,
  showDivider: PropTypes.bool,
  showTitle: PropTypes.bool,
  accentColor: PropTypes.string,

};

export { ReusableModal };
