import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Label, Image } from 'semantic-ui-react';
import { isEqual, eq } from 'lodash';
import formatter from '../../../utils/formatter';
import amexIcon from '../assets/american-express-icon.png';
import masterCardIcon from '../assets/master-card-icon.png';
import visaIcon from '../assets/visa-icon.png';
import { FadeInView } from '../../FadeInView';
import i18n from '../utils/i18n.json';

const ASCENDING = 'ascending';
const DESCENDING = 'descending';
const VISA_MASK = 'xxxx-xxxx-xxxx-xxxx';
const AMEX_MASK = 'xxxx-xxxxxx-xxxxx';

const creditCardTypes = {
  jcb: 'jcb',
  amex: 'amex',
  dinersClub: 'diners_club',
  visa: 'visa',
  masterCard: 'mastercard',
  discover: 'discover',
  maestro: 'maestro',
  unknown: 'unknown',
};

const { errorRequired, errorNumberCharacters, errorInvalidCharacters } = i18n.creditCard;


class CreditCardInput extends Component {
  constructor(props) {
    super(props);
    const existDefaultValue = props.defaultValue !== undefined && props.defaultValue !== null && props.defaultValue !== '';

    let valid;
    if (existDefaultValue) {
      valid = true;
    } else {
      valid = !props.required;
    }

    let value = '';
    if (existDefaultValue) {
      value = this.applyMask(props.defaultValue);
    }

    this.state = {
      value,
      valid,
      message: props.required ? errorRequired[props.language].replace('#label',props.label.toLowerCase()) : '',
      dirty: false,
      mask: VISA_MASK,
      creditCardType: creditCardTypes.unknown,
      errorVisible: false,
    };
  }


  // -----------------------------
  // ------ life cycle events ----
  // -----------------------------
  componentDidMount() {
    this.setForm();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const existDefaultValue = nextProps.defaultValue !== undefined && nextProps.defaultValue !== null && nextProps.defaultValue !== '';

    if (!this.state.dirty && existDefaultValue) {
      let value = nextProps.defaultValue;
      value = this.applyMask(nextProps.defaultValue);
      this.setState({ value, valid: true });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !isEqual(nextState, this.state) || !isEqual(clean(nextProps), clean(this.props));
  }

  componentDidUpdate(prevProps, prevState) {
    if (!eq(prevState, this.state)) {
      this.setForm();
    }

    if (prevState.value !== this.state.value) {
      this.getCreditCardType();
    }

    if (prevState.creditCardType !== this.state.creditCardType) {
      this.setNewMask();
    }
  }


  // -----------------------
  // ------ user events ----
  // -----------------------
  onChange(e) {
    const { value, mask } = this.state;
    const regex = /[^\d]/g;
    const incomingValueArray = e.target.value.split('');
    const currentValueArray = value.split('');
    const direction = incomingValueArray.length > currentValueArray.length ? ASCENDING : DESCENDING;
    const lastCharacterIndex = incomingValueArray.length - 1;
    const lastCharacter = incomingValueArray[lastCharacterIndex];
    const isPlaceholder = this.isPlaceholder(lastCharacterIndex);
    const isNumeric = !regex.test(lastCharacter);
    const isFirstCharacter = incomingValueArray.length === 1;
    let validCharacter = true;

    if (isPlaceholder && !isNumeric) {
      validCharacter = false;
    } else if (!isPlaceholder && isFirstCharacter && !isNumeric) {
      validCharacter = false;
    }

    if (direction === DESCENDING) {
      validCharacter = true;
    }

    if (validCharacter) {
      const maskArray = mask.split('');
      const newValueArray = [];
      const placeholdersPositions = this.getPlaceholdersPositions();
      let charactersPlaced = 0;

      if (direction === ASCENDING) {
        const rawValueArray = maskArray.map((character, index) => {
          if (character === 'x') {
            let characterToPlace;

            if (!charactersPlaced && incomingValueArray.length === 1) {
              charactersPlaced += 1;
              characterToPlace = incomingValueArray[0];
            } else if (incomingValueArray[index]) {
              charactersPlaced += 1;
              characterToPlace = incomingValueArray[index];

              if (regex.test(characterToPlace)) {
                validCharacter = false;
              }
            }

            return characterToPlace;
          } else if (maskArray[index] !== incomingValueArray[index]) {
            incomingValueArray.splice(index, 0, maskArray[index]);
          }

          return character;
        });

        if (charactersPlaced === placeholdersPositions.length) {
          rawValueArray.forEach((character) => { newValueArray.push(character); });
        } else {
          const lastIndex = placeholdersPositions[charactersPlaced];

          rawValueArray.forEach((character, index) => {
            if (index < lastIndex) {
              newValueArray.push(character);
            }
          });
        }
      } else {
        while (incomingValueArray.length) {
          const lastIndex = incomingValueArray.length - 1;

          if (this.isPlaceholder(lastIndex)) {
            break;
          } else {
            incomingValueArray.pop();
          }
        }

        incomingValueArray.forEach((character) => {
          newValueArray.push(character);
        });
      }

      const finalValue = newValueArray.reduce((sum, currentItem) => (
        sum + currentItem
      ), '');

      if (validCharacter) {
        this.setState({
          value: finalValue,
          valid: true,
          dirty: true,
          errorVisible: false,
        });
      }
    }
  }

  onBlur() {
    const { value } = this.state;
    const placeholdersPositions = this.getPlaceholdersPositions();
    const valueArray = value.split('');
    const regex = /[^\d]/g;
    const newState = { valid: true, message: '', dirty: true };

    for (let index = 0; index < placeholdersPositions.length; index += 1) {
      const placeholderIndex = placeholdersPositions[index];
      const currentCharacter = valueArray[placeholderIndex];

      if (!currentCharacter) {
        const error = errorNumberCharacters[this.props.language].replace('#label',this.props.label.toLowerCase());

        newState.valid = false;
        newState.message = error.replace('#number',placeholdersPositions.length);

        if (this.props.showErrors) {
          newState.errorVisible = true;
        }

        break;
      }

      if (regex.test(currentCharacter)) {
        newState.valid = false;
        newState.message = errorInvalidCharacters[this.props.language].replace('#label',this.props.label.toLowerCase());

        if (this.props.showErrors) {
          newState.errorVisible = true;
        }

        break;
      }
    }

    this.setState(newState);
  }

  setForm() {
    this.props.setFormData(this.props.name, this.state);
  }

  setNewMask() {
    const { creditCardType } = this.state;

    if (creditCardType === creditCardTypes.amex) {
      this.setState({ mask: AMEX_MASK });
    } else {
      this.setState({ mask: VISA_MASK });
    }
  }

  getValue() {
    let numericValue = '';
    const valueArray = this.state.value.toString().split('');
    const placeholdersPositions = this.getPlaceholdersPositions();

    placeholdersPositions.forEach((placeholderPosition) => {
      numericValue += valueArray[placeholderPosition];
    });

    return numericValue ? parseFloat(numericValue) : '';
  }

  getPlaceholdersPositions() {
    const { mask } = this.state;
    const maskArray = mask.split('');
    const placeholdersPositions = [];

    maskArray.forEach((character, index) => {
      if (character === 'x') {
        placeholdersPositions.push(index);
      }
    });

    return placeholdersPositions;
  }

  getCreditCardType() {
    let creditCardType;
    let creditCardNumber = this.state.value;

    const jcb_regex = new RegExp('^(?:2131|1800|35)[0-9]{0,}$');
    const amex_regex = new RegExp('^3[47][0-9]{0,}$');
    const diners_regex = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$');
    const visa_regex = new RegExp('^4[0-9]{0,}$');
    const mastercard_regex = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$');
    const maestro_regex = new RegExp('^(5[06789]|6)[0-9]{0,}$');
    const discover_regex = new RegExp('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$');

    // get rid of anything but numbers
    creditCardNumber = creditCardNumber.replace(/\D/g, '');

    if (creditCardNumber.match(jcb_regex)) {
      creditCardType = creditCardTypes.jcb;
    } else if (creditCardNumber.match(amex_regex)) {
      creditCardType = creditCardTypes.amex;
    } else if (creditCardNumber.match(diners_regex)) {
      creditCardType = creditCardTypes.dinersClub;
    } else if (creditCardNumber.match(visa_regex)) {
      creditCardType = creditCardTypes.visa;
    } else if (creditCardNumber.match(mastercard_regex)) {
      creditCardType = creditCardTypes.masterCard;
    } else if (creditCardNumber.match(discover_regex)) {
      creditCardType = creditCardTypes.discover;
    } else if (creditCardNumber.match(maestro_regex)) {
      if (creditCardNumber[0] === '5') {
        creditCardType = creditCardTypes.masterCard;
      } else {
        creditCardType = creditCardTypes.maestro;
      }
    } else {
      creditCardType = creditCardTypes.unknown;
    }

    this.setState({ creditCardType });
  }

  isPlaceholder(index) {
    const placeholdersPositions = this.getPlaceholdersPositions();

    const foundIndex = placeholdersPositions.filter((placeholdersPosition) =>
      placeholdersPosition === index
    );

    return foundIndex.length > 0;
  }

  applyMask(numericValue) {
    const { mask } = this.state;
    const maskArray = mask.split('');
    const numericValueArray = numericValue.toString().split('');
    let maskedValue = '';
    let nextIndex = 0;

    maskArray.forEach((character) => {
      if (character === 'x') {
        maskedValue += numericValueArray[nextIndex];
        nextIndex += 1;
      } else {
        maskedValue += character;
      }
    });

    return maskedValue;
  }

  dirtInput() {
    this.setState({ dirty: true });
  }

  resetInput() {
    const existDefaultValue = this.props.defaultValue !== undefined && this.props.defaultValue !== null && this.props.defaultValue !== '';

    let valid;
    if (existDefaultValue) {
      valid = true;
    } else {
      valid = !this.props.required;
    }

    let value = '';
    if (existDefaultValue) {
      value = this.applyMask(this.props.defaultValue);
    }

    this.setState({
      value,
      valid,
      message: this.props.required ? errorRequired[this.props.language].replace('#label',this.props.label.toLowerCase()) : '',
      dirty: false,
      mask: VISA_MASK,
      creditCardType: creditCardTypes.unknown,
      errorVisible: false,
    });
  }

  showError() {
    this.setState({ errorVisible: true });
  }


  // --------------------------
  // ------ render methods ----
  // --------------------------
  renderErrorLabel() {
    const { errorVisible, message } = this.state;

    if (errorVisible) {
      return (
        <FadeInView style={styles.popUpContainer}>
          <Label basic pointing color="red" style={styles.popUpErrorLabel}>
            { message }
          </Label>
        </FadeInView>
      );
    }

    return null;
  }

  renderCreditCardIcon() {
    const { value, creditCardType } = this.state;

    if (value) {
      if (creditCardType === creditCardTypes.amex) {
        return (
          <FadeInView style={styles.iconContainer}>
            <Image src={amexIcon} style={styles.icon} floated="right" />
          </FadeInView>
        );
      }

      if (creditCardType === creditCardTypes.masterCard) {
        return (
          <FadeInView style={styles.iconContainer}>
            <Image src={masterCardIcon} style={styles.icon} floated="right" />
          </FadeInView>
        );
      }

      if (creditCardType === creditCardTypes.visa) {
        return (
          <FadeInView style={styles.iconContainer}>
            <Image src={visaIcon} style={styles.icon} floated="right" />
          </FadeInView>
        );
      }
    }

    return null;
  }

  render() {
    const invalidInput = this.state.dirty && !this.state.valid;
    let className = '';
    let finalLabelStyle = { ...styles.label, ...this.props.labelStyle };
    let finalInputStyle = { ...styles.input, ...this.props.inputStyle };

    if (this.props.className) {
      className = `${this.props.className} ${invalidInput ? 'invalid' : 'valid'}`;
    } else {
      className = invalidInput ? 'invalid' : 'valid';
    }

    if (invalidInput) {
      finalLabelStyle = { ...this.props.labelStyle, ...styles.errorLabel };
      finalInputStyle = { ...this.props.inputStyle, ...styles.errorInput };
    }

    const labelString = this.props.required ? `${this.props.label} *` : this.props.label;

    // render input
    if (this.props.inlineLabel) {
      return (
        <Form.Field onBlur={this.onBlur.bind(this)}>
          <Input
            label={labelString}
            value={this.state.value}
            name={this.props.name}
            className={className}
            onChange={this.onChange.bind(this)}
            disabled={this.props.readOnly}
            placeholder={this.props.placeholder}
            style={finalInputStyle}
          />

          { this.renderCreditCardIcon() }
          { this.renderErrorLabel() }
        </Form.Field>
      );
    }

    return (
      <Form.Field onBlur={this.onBlur.bind(this)}>
        <label style={finalLabelStyle}>
          { labelString }
        </label>

        <input
          value={this.state.value}
          name={this.props.name}
          className={className}
          onChange={this.onChange.bind(this)}
          disabled={this.props.readOnly}
          placeholder={this.props.placeholder}
          style={finalInputStyle}
        />

        { this.renderCreditCardIcon() }
        { this.renderErrorLabel() }
      </Form.Field>
    );
  }
}


// estilos
const styles = {
  label: {

  },
  errorLabel: {
    color: '#9F3A38',
  },
  input: {

  },
  errorInput: {
    background: 'rgba(224, 180, 180, 0.48)',
    border: '1px solid #9F3A38',
    color: '#9F3A38',
  },
  popUpContainer: {
    position: 'absolute',
    top: 75,
    left: 0,
    zIndex: 100,
  },
  popUpErrorLabel: {
    fontSize: 13,
    textAlign: 'center',
    boxShadow: 'rgba(100, 100, 100, 0.25) 0px 2px 4px',
  },
  iconContainer: {
    position: 'absolute',
    bottom: 9,
    right: 0,
    width: 100,
  },
  icon: {
    width: 48,
    marginRight: 18,
  },
};


CreditCardInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  inlineLabel: PropTypes.bool,
  inputStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  className: PropTypes.string,
  required: PropTypes.bool,
  showErrors: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setFormData: PropTypes.func.isRequired,
  language: PropTypes.string,
};


// exportar componente
export default CreditCardInput;
