import axios from 'axios';
import { API_URL } from '../../../../utils/constants';

export function getAllTiers(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-tiers`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};