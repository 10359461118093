import { call, put } from 'redux-saga/effects';
import * as calls from '../calls/index.js';
import * as actionsTypes from '../../store/types';
import notifications from '../../../../utils/notifications';
import eventManager from '../../../../utils/eventManager';


export function* saveAditionalForm(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingAditionalForm', value: true } });

    const request = calls.saveAditionalForm(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingAditionalForm', value: false } });
      eventManager.emit('saved_aditional_form');
    } else {
      notifications.error('Error al guardar datos');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingAditionalForm', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* saveFinancingData(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingFinancingData', value: true } });

    const request = calls.saveFinancingData(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingFinancingData', value: false } });
      eventManager.emit('saved-financingdata', serverResponse.data);
    } else {
      notifications.error(serverResponse.message);
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingFinancingData', value: false } });

  } catch (error) {
    console.log(error.message);
  };
};

export function* getFinancingData(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingData', value: true } });
    const request = calls.getFinancingData(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingData', value: false } });
      yield put({ type: actionsTypes.SET_FINANCING_DATA, payload: serverResponse.data });
    } else {
      notifications.error(serverResponse.message);
      yield put({ type: actionsTypes.UNSET_FINANCING_DATA, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingData', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getFinancingByImei(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingByImei', value: true } });

    const request = calls.getFinancingByImei(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingByImei', value: false } });
      yield put({ type: actionsTypes.SET_FINANCING_BY_IMEI, payload: serverResponse.data });
    } else {
      notifications.error('Financiamiento no encontrado');
      yield put({ type: actionsTypes.UNSET_FINANCING_BY_IMEI, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingByImei', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getEnrrolledPhones(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'enrolledPhones', value: true } });

    const request = calls.getEnrrolledPhones(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'enrolledPhones', value: false } });
      yield put({ type: actionsTypes.SET_ENRROLLED_PHONES, payload: serverResponse.data });
    } else {
      notifications.error(serverResponse.message);
      yield put({ type: actionsTypes.SET_ENRROLLED_PHONES, payload: null });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'enrolledPhones', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* saveFinancingApplication(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingFinancingApplication', value: true } });

    const request = calls.saveFinancingApplication(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingFinancingApplication', value: false } });
      eventManager.emit('saved-financing-application', serverResponse.data);
    } else {
      notifications.error(serverResponse.message);
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingFinancingApplication', value: false } });

  } catch (error) {
    console.log(error.message);
  };
};

export function* getFinancingApplication(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingApplication', value: true } });
    const request = calls.getFinancingApplication(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingApplication', value: false } });
      yield put({ type: actionsTypes.SET_FINANCING_APPLICATION, payload: serverResponse.data });
    } else {
      notifications.error(serverResponse.message);
      yield put({ type: actionsTypes.SET_FINANCING_APPLICATION, payload: null });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingApplication', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getFinancingPayments(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingPayments', value: true } });

    const request = calls.getFinancingPayments(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingPayments', value: false } });
      yield put({ type: actionsTypes.SET_FINANCING_PAYMENTS, payload: serverResponse.data });
    } else {
      notifications.error(serverResponse.message);
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingPayments', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* savePayment(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savePayment', value: true } });

    const request = calls.savePayment(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savePayment', value: false } });
      eventManager.emit('saved-payment', serverResponse.data);
    } else {
      notifications.error('No se pudo generar el pago');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savePayment', value: false } });

  } catch (error) {
    console.log(error.message);
  };
};

export function* getFinancingDataPayment(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingData', value: true } });
    const request = calls.getFinancingDataPayment(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingData', value: false } });
      yield put({ type: actionsTypes.SET_FINANCING_DATA, payload: serverResponse.data });
    } else {
      notifications.error(serverResponse.message);
      yield put({ type: actionsTypes.UNSET_FINANCING_DATA, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingData', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getBanksList(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'banksList', value: true } });
    const request = calls.getBanksList(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'banksList', value: false } });
      yield put({ type: actionsTypes.SET_BANKS_LIST, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer los datos del banco');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'banksList', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getFinancingTable(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingsTable', value: true } });
    const request = calls.getFinancingTable(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingsTable', value: false } });
      yield put({ type: actionsTypes.SET_FINANCING_TABLE, payload: serverResponse.data });
      eventManager.emit('financings_table', serverResponse.data);
    } else {
      notifications.error(serverResponse.message);
      yield put({ type: actionsTypes.SET_FINANCING_TABLE, payload: null });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingsTable', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getFinancingDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingDetail', value: true } });
    const request = calls.getFinancingDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingDetail', value: false } });
      yield put({ type: actionsTypes.SET_FINANCING_DETAIL, payload: serverResponse.data });
    } else {
      notifications.error(serverResponse.message);
      yield put({ type: actionsTypes.SET_FINANCING_DETAIL, payload: null });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingDetail', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};
