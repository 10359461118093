import axios from 'axios';
import { API_URL } from '../../../../utils/constants';

//* Vistas que lo utilizan

// Trae los datos de un cliente a través de su CURP
//* Warranty/ConfirmClient
//* Warranty/ConfirmClient/ClientNotFound
export function getCustomerData(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/customers/by-curp`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae los datos de financiamiento de un cliente a través de su CURP
//* Financing/Identification/Application
export function getCustomerFinancingData(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/customers/aplication/by-curp`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Guarda un cliente nuevo
//* Warranty/ConfirmClient
//* Warranty/ConfirmClient/ClientNotFound
export function saveCustomerData(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/customers/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Guarda un nuevo cliente para generar solicitud
//* Financing/Identification/Application/Manual
export function saveApplicationForm(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/customers/aplication/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae los datos biométricos de un cliente mediante su CURP
//* Financing/Identification/Application
export function getBioData(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/customers/bio-data/by-curp`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { curp: body.curp }
  });
};

// Guarda los datos biométricos de un cliente nuevo
//* Financing/Identification/Incode
export function saveBioData(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/customers/bio-data/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { response: body.data, image: body.image }
  });
};

// Trae los datos de score de riesgo de un cliente
//* Financing/FinancingCalculator_v2
//* Financing/Identification/Application
export function getCustomerScore(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/customers/credit-score/by-curp`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { curp: body.curp }
  });
};

// Guarda o actualiza los datos de score de riesgo de un cliente
//* Financing/FinancingCalculator_v2/Detail
//* Financing/Identification/Application
export function saveCustomerScore(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/customers/credit-score/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};
