import { call, put } from 'redux-saga/effects';
import notifications from '../../../utils/notifications';
import eventManager from '../../../utils/eventManager';
import * as actionsTypes from '../store/types';
import * as calls from './calls';
import formatter from '../../../utils/formatter';

function fixFailures(failures){
  const failuresToShow = failures.map(element => {
    return {
      id: element.failure_id,
      text: formatter.capitalize(element.name),
      value: element.failure_id
    };
  });

  return failuresToShow;
};

function fixSymptoms(symptoms){
  const symptomsToShow = symptoms.map(element => {
    return {
      id: element.idSymptoms,
      text: formatter.capitalize(element.name),
      value: element.idSymptoms,
    };
  });
  return symptomsToShow;
};

function fixAccesories(accesories){
  const accesoriesToShow = accesories.map(element => {
    return {
      id: element.accesory_id,
      text: formatter.capitalize(element.name),
      value: element.accesory_id,
    };
  });
  return accesoriesToShow;
};

function fixBranches(branches){
  const branchesToShow = branches.map(element => {
    return {
      id: element.branch_id,
      text: `${element.postal_code} - ${element.name}`,
      value: element.branch_id,
      key: element.branch_id
    };
  });
  return branchesToShow;
};

export function* getUserDataBySession(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'userData', value: true } });

    const request = calls.getUserDataBySession(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'userData', value: false } });
      yield put({ type: actionsTypes.SET_USERDATA, payload: serverResponse.data });
      eventManager.emit('valid_token', serverResponse.data.token );
    } else {
      notifications.error('Error al traer datos de usuario');
      eventManager.emit('invalid_token');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'userData', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getBranchData(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchData', value: true } });

    const request = calls.getBranchData(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchData', value: false } });
      yield put({ type: actionsTypes.SET_BRANCH_DATA, payload: serverResponse.data });
    } else {
      notifications.error('Sucursal no encontrada');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchData', value: false } });
  } catch (error) {
    notifications.error('Sucursal no encontrada');
  }
}

export function* getInvoiceTable(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'invoiceTable', value: true } });

    const request = calls.getInvoiceTable(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'invoiceTable', value: false } });
      yield put({ type: actionsTypes.SET_INVOICE_TABLE, payload: serverResponse.data });
    } else {
      notifications.error('Tabla no encontrada');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'invoiceTable', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getInvoiceTableByStatus(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'invoiceTableByStatus', value: true } });

    const request = calls.getInvoiceTableByStatus(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'invoiceTableByStatus', value: false } });
      yield put({ type: actionsTypes.SET_INVOICE_TABLE_BY_STATUS, payload: serverResponse.data });
    } else {
      notifications.error('Tabla no encontrada');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'invoiceTableByStatus', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getAllWarrantyStatus(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'allWarrantyStatus', value: true } });

    const request = calls.getAllWarrantyStatus(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'allWarrantyStatus', value: false } });
      yield put({ type: actionsTypes.SET_ALL_WARRANTY_STATUS, payload: serverResponse.data });
    } else {
      notifications.error('Status no encontrados');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'allWarrantyStatus', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getWarrantyDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyDetail', value: true } });

    const request = calls.getWarrantyDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyDetail', value: false } });
      yield put({ type: actionsTypes.SET_WARRANTY_DETAIL, payload: serverResponse.data });
    } else {
      notifications.error('Detalle de garantia no encontrado');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyDetail', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* updateAllWarantyDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateAllWarantyDetail', value: true } });

    const request = calls.updateAllWarantyDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateAllWarantyDetail', value: false } });
      eventManager.emit('updated_all_waranty_detail')
      notifications.success('Datos de la garantia guardados')
    } else {
      notifications.error('Error al actulizar los datos de garantia');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateAllWarantyDetail', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* changeInvoiceStatus(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateWarrantiesSatus', value: true } });

    const request = calls.changeInvoiceStatus(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateWarrantiesSatus', value: false } });
      eventManager.emit('updated_invoices');
    } else {
      notifications.error('Error al cambiar folios de status');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateWarrantiesSatus', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* changeInvoiceStatusWithDoc(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateWarrantiesSatus', value: true } });

    const request = calls.changeInvoiceStatusWithDoc(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateWarrantiesSatus', value: false } });
      eventManager.emit('updated_invoices');
    } else {
      notifications.error('Error al cambiar folios de status');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateWarrantiesSatus', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getFailures(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'failures', value: true } });

    const request = calls.getFailures(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      const fixedArray = fixFailures(serverResponse.data);
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'failures', value: false } });
      yield put({ type: actionsTypes.SET_FAILURES, payload: fixedArray });
    } else {
      notifications.error('Fallas no encontradas');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'failures', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getSymptoms(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'symptoms', value: true } });

    const request = calls.getSymptoms(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      const fixedArray = fixSymptoms(serverResponse.data);
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'symptoms', value: false } });
      yield put({ type: actionsTypes.SET_SYMPTOMS, payload: fixedArray });
    } else {
      notifications.error('Sintomas no encontrados');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'symptoms', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getAccesories(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'accesories', value: true } });

    const request = calls.getAccesories(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      const fixedArray = fixAccesories(serverResponse.data);
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'accesories', value: false } });
      yield put({ type: actionsTypes.SET_ACCESORIES, payload: fixedArray });
    } else {
      notifications.error('Accesorios no encontrados');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'accesories', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getBranches(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branches', value: true } });

    const request = calls.getBranches(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      const fixedArray = fixBranches(serverResponse.data);
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branches', value: false } });
      yield put({ type: actionsTypes.SET_BRANCHES, payload: fixedArray });
    } else {
      notifications.error('Sucursales no encontradas');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branches', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getWarrantyEvidence(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidence', value: true } });

    const request = calls.getWarrantyEvidence(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidence', value: false } });
      yield put({ type: actionsTypes.SET_WARRANTY_EVIDENCE, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer las evidencias');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidence', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* uploadWarrantyEvidence(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidenceUpload', value: true } });

    const request = calls.uploadWarrantyEvidence(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidenceUpload', value: false } });
      eventManager.emit('uploaded_warranty_file' );
      notifications.success('Archivo cargado con exito');

    } else {
      notifications.error('Error al subir el documento');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidenceUpload', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getWarrantyEvidenceUrl(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidenceURL', value: true } });

    const request = calls.getWarrantyEvidenceUrl(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidenceURL', value: false } });
      eventManager.emit('warranty_evidence_url', serverResponse.data);
    } else {
      notifications.error('Error al traer el url');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidenceURL', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getWarrantyLogs(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyLogs', value: true } });

    const request = calls.getWarrantyLogs(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyLogs', value: false } });
      yield put({ type: actionsTypes.SET_WARRANTY_LOGS, payload: serverResponse.data });

    } else {
      notifications.error('Error al traer los Logs');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyLogs', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* generateQuote(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyQuote', value: true } });

    const request = calls.generateQuote(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyQuote', value: false } });
      eventManager.emit('warranty_quote_saved', serverResponse.data);
    } else {
      notifications.error('Error al generar la cotización');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyQuote', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getPhoneData(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phoneData', value: true } });

    const request = calls.getPhoneData(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phoneData', value: false } });
      if (serverResponse.data.type === 'Financing') {
        yield put({ type: actionsTypes.SET_WARRANTY_DETAIL, payload: serverResponse.data });
      } else {
        yield put({ type: actionsTypes.SET_PHONE_DATA, payload: serverResponse.data });
      }
    } else {
      notifications.error('Error al traer información');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phoneData', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* saveInvoiceSwap(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveInvoiceSwap', value: true } });

    const request = calls.saveInvoiceSwap(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveInvoiceSwap', value: false } });
      eventManager.emit('saved_invoice_swap');

    } else {
      notifications.error('Error al realizar el SWAP');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveInvoiceSwap', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getFinancingData(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingData', value: true } });

    const request = calls.getFinancingData(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingData', value: false } });
      yield put({ type: actionsTypes.SET_FINANCING_DATA, payload: serverResponse.data });
    } else {
      notifications.error('Telefono no encontrado');
      yield put({ type: actionsTypes.UNSET_FINANCING_DATA, payload: serverResponse.data });

    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingData', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getDashboardStats(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'dashboardStats', value: true } });

    const request = calls.getDashboardStats(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'dashboardStats', value: false } });
      yield put({ type: actionsTypes.SET_DASHBOARD_STATS, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'dashboardStats', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getRolesList(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'rolesList', value: true } });

    const request = calls.getRolesList(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'rolesList', value: false } });
      yield put({ type: actionsTypes.SET_ROLES_LIST, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'rolesList', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* saveUser(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveUser', value: true } });

    const request = calls.saveUser(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveUser', value: false } });
      eventManager.emit('saved-user');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveUser', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* updateUser(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateUser', value: true } });

    const request = calls.updateUser(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateUser', value: false } });
      eventManager.emit('updated-user');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateUser', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getUsersList(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'usersList', value: true } });

    const request = calls.getUsersList(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'usersList', value: false } });
      yield put({ type: actionsTypes.SET_USERS_LIST, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'usersList', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getUserDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'userDetail', value: true } });

    const request = calls.getUserDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'userDetail', value: false } });
      yield put({ type: actionsTypes.SET_USER_DETAIL, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'userDetail', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* saveRole(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveRole', value: true } });

    const request = calls.saveRole(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveRole', value: false } });
      eventManager.emit('saved-role');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveRole', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getRoleDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'roleDetail', value: true } });

    const request = calls.getRoleDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'roleDetail', value: false } });
      yield put({ type: actionsTypes.SET_ROLE_DETAIL, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'roleDetail', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getBranchesList(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchesList', value: true } });

    const request = calls.getBranchesList(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchesList', value: false } });
      yield put({ type: actionsTypes.SET_BRANCHES_LIST, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchesList', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getBranchDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchDetail', value: true } });

    const request = calls.getBranchDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchDetail', value: false } });
      yield put({ type: actionsTypes.SET_BRANCH_DETAIL, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchDetail', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* saveBranch(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveBranch', value: true } });

    const request = calls.saveBranch(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveBranch', value: false } });
      eventManager.emit('saved-branch');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveBranch', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* saveWarrantyDiagnosis(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveWarrantyDiagnosis', value: true } });

    const request = calls.saveWarrantyDiagnosis(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveWarrantyDiagnosis', value: false } });
      eventManager.emit('warranty_quote_saved');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveWarrantyDiagnosis', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getInventoryList(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'inventoryList', value: true } });

    const request = calls.getInventoryList(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'inventoryList', value: false } });
      yield put({ type: actionsTypes.SET_INVENTORY_LIST, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'inventoryList', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getAditionalFormData(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'aditionalFormData', value: true } });

    const request = calls.getAditionalFormData(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'aditionalFormData', value: false } });
      yield put({ type: actionsTypes.SET_ADITIONAL_FORM, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'aditionalFormData', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* getGestionDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'gestionDetail', value: true } });

    const request = calls.getGestionDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'gestionDetail', value: false } });
      yield put({ type: actionsTypes.SET_GESTION_DETAIL, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'gestionDetail', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* saveGestionLog(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingGestionLog', value: true } });

    const request = calls.saveGestionLog(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingGestionLog', value: false } });
      eventManager.emit('gestion_log_saved');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingGestionLog', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}


// ==================================================


export function* getDropdownWarrantyStatus(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'dropdownWarrantyStatus', value: true } });

    const request = calls.getDropdownWarrantyStatus(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {

      const data = serverResponse.data.map(item => {
        return {
          key: item.warrantie_status_id,
          text: item.name,
          value: item.warrantie_status_id,
          id:  item.warrantie_status_id
        };
      });

      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'dropdownWarrantyStatus', value: false } });
      yield put({ type: actionsTypes.SET_DROPDOWN_WARRANTY_STATUS, payload: data });
    } else {
      notifications.error('Status no encontrados');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'dropdownWarrantyStatus', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getAllRecoveriesPhone(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveriesPhonesData', value: true } });

    const request = calls.getAllRecoveriesPhone(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveriesPhonesData', value: false } });
      yield put({ type: actionsTypes.SET_ALL_RECOVERIES_PHONES, payload: serverResponse.data });
    } else {
      notifications.error('Error al obtener la información');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveriesPhonesData', value: false } });
  } catch (error) {
    console.log(error);
  }
}

export function* getPhonesBrandsCategories(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'brands', value: true } });

    const request = calls.getPhonesBrandsCategories(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'brands', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_BRANDS, payload: serverResponse.data });
    } else {
      notifications.error('Marcas no encontradas');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'brands', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getPhonesNamesByBrand(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesListByBrand', value: true } });

    const request = calls.getPhonesNamesByBrand(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesListByBrand', value: false } });
      yield put({ type: actionsTypes.SET_PHONE_NAMES_BY_BRAND, payload: serverResponse.data });
    } else {
      notifications.error('Error al obtener la información');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesListByBrand', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getRecoveryPhoneById(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryphoneById', value: true } });

    const request = calls.getRecoveryPhoneById(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryphoneById', value: false } });
      yield put({ type: actionsTypes.SET_RECOVERY_PHONE_BY_ID, payload: serverResponse.data  });
    } else {
      notifications.error(`Error al obtener la información`);
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryphoneById', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* getRecoveryEvidence(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryEvidence', value: true } });

    const request = calls.getRecoveryEvidence(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryEvidence', value: false } });
      yield put({ type: actionsTypes.SET_RECOVERY_EVIDENCE, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer las evidencias');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryEvidence', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getRecoveryEvidenceUrl(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryEvidenceURL', value: true } });

    const request = calls.getRecoveryEvidenceUrl(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryEvidenceURL', value: false } });
      eventManager.emit('recovery_evidence_url', serverResponse.data);
    } else {
      notifications.error('Error al traer el url');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryEvidenceURL', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* uploadRecoveryEvidence(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryEvidenceUpload', value: true } });

    const request = calls.uploadRecoveryEvidence(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryEvidenceUpload', value: false } });
      eventManager.emit('uploaded_recovery_file' );
      notifications.success('Archivo cargado con exito');

    } else {
      notifications.error('Error al subir el documento');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryEvidenceUpload', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getRecoveriesLogs(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryLogs', value: true } });

    const request = calls.getRecoveriesLogs(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryLogs', value: false } });
      yield put({ type: actionsTypes.SET_RECOVERY_LOGS, payload: serverResponse.data });

    } else {
      notifications.error('Error al traer los Logs');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryLogs', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* changeRecoveryStatus(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateRecoveryStatus', value: true } });

    const request = calls.changeRecoveryStatus(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateRecoveryStatus', value: false } });
      eventManager.emit('updated_recoveries');
      notifications.success('Folio de recuperación actualizado correctamente.');
    } else {
      notifications.error('Error al cambiar folios de status');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateRecoveryStatus', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* generateRecoveryQuote(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'RecoveryQuote', value: true } });

    const request = calls.generateRecoveryQuote(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'RecoveryQuote', value: false } });
      eventManager.emit('recovery_quote_saved', serverResponse.data);
      notifications.success("El folio ha sido actualizado correctamente")
    } else {
      notifications.error('Error al generar la cotización');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'RecoveryQuote', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* modRecoveryStatus(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'modRecoveryStatus', value: true } });

    const request = calls.modRecoveryStatus(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'modRecoveryStatus', value: false } });
      notifications.success('Folio de recuperación actualizado correctamente.');
      eventManager.emit('returntowarrantyview');
    } else {
      notifications.error('Error al cambiar folios de status');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'modRecoveryStatus', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* updateRecoveryStatusMultiple(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateRecoveriesStatus', value: true } });

    const request = calls.updateRecoveryStatusMultiple(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateRecoveriesStatus', value: false } });
      notifications.success('Folios de recuperación actualizados correctamente.');
      eventManager.emit('updated_recoveries');
    } else {
      notifications.error('Error al cambiar folios de status');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateRecoveriesStatus', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getColabApplications(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'colabApplications', value: true } });

    const request = calls.getColabApplications(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'colabApplications', value: false } });
      yield put({ type: actionsTypes.SET_COLAB_APPLICATIONS, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer solicitudes');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'colabApplications', value: false } });

  } catch (error) {
    console.log(error.message);
  }
}

export function* updateCaprepaApplication(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingCaprepaApplication', value: true } });

    const request = calls.updateCaprepaApplication(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingCaprepaApplication', value: false } });
      eventManager.emit('updated-caprepa-application', serverResponse.data);
    } else {
      notifications.error('Error al guardar los cambios');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingCaprepaApplication', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getSepomexInfo(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'sepomexInfo', value: true } });

    const request = calls.getSepomexInfo(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'sepomexInfo', value: false } });
      yield put({ type: actionsTypes.SET_SEPOMEX_INFO, payload: { data: serverResponse.data, id: action.payload.id } });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'sepomexInfo', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* getPhonesByBrand(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesByBrand', value: true } });

    const request = calls.getPhonesByBrand(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesByBrand', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_BY_BRAND, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer los colores');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesByBrand', value: false } });
  } catch (error) {
    notifications.error('Error al traer los colores');
  }
}

export function* getAllBranchWarranties(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchWarrantyData', value: true } });

    const request = calls.getAllBranchWarranties(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchWarrantyData', value: false } });
      yield put({ type: actionsTypes.SET_ALL_BRANCH_WARRANTIES, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchWarrantyData', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getBranchWarrantiesDetailById(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchwarrantyDetail', value: true } });

    const request = calls.getBranchWarrantiesDetailById(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchwarrantyDetail', value: false } });
      yield put({ type: actionsTypes.SET_BRANCH_WARRANTY_DETAIL_BY_ID, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchwarrantyDetail', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getBranchWarrantyLogsById(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchwarrantyLogs', value: true } });

    const request = calls.getBranchWarrantyLogsById(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchwarrantyLogs', value: false } });
      yield put({ type: actionsTypes.SET_BRANCH_WARRANTY_LOGS_BY_ID, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchwarrantyLogs', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getBranchWarrantyDocumentsEvidenceById(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchwarrantyEvidence', value: true } });

    const request = calls.getBranchWarrantyDocumentsEvidenceById(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchwarrantyEvidence', value: false } });
      yield put({ type: actionsTypes.SET_BRANCH_WARRANTY_DOCUMENTS_EVIDENCE_BY_ID, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer las evidencias');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchwarrantyEvidence', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* uploadBranchWarrantyEvidence(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchwarrantyUpload', value: true } });

    const request = calls.uploadBranchWarrantyEvidence(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchwarrantyUpload', value: false } });
      eventManager.emit('uploaded_branch_warranty_file' );
      notifications.success('Archivo cargado con exito');

    } else {
      notifications.error('Error al subir el documento');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchwarrantyUpload', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* generateBranchWarrantyQuote(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchwarrantyQuote', value: true } });

    const request = calls.generateBranchWarrantyQuote(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchwarrantyQuote', value: false } });
      eventManager.emit('return_to_warrantyview', serverResponse.data);
    } else {
      notifications.error('Error al generar la cotización');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchwarrantyQuote', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* updateBranchWarrantyStatus(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchWarrantyStatusUpdate', value: true } });

    const request = calls.updateBranchWarrantyStatus(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchWarrantyStatusUpdate', value: false } });
      notifications.success('Folio de recuperación actualizado correctamente.');
      eventManager.emit('return_to_warrantyview');
    } else {
      notifications.error('Error al cambiar folios de status');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchWarrantyStatusUpdate', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* updateBranchWarrantyStatusMultiple(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchWarrantyStatusMultipleUpdate', value: true } });

    const request = calls.updateBranchWarrantyStatusMultiple(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchWarrantyStatusMultipleUpdate', value: false } });
      notifications.success('Folio de recuperación actualizado correctamente.');
      eventManager.emit('updated_branch_warranty_status_multiple');
    } else {
      notifications.error('Error al cambiar folios de status');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchWarrantyStatusMultipleUpdate', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}