import { call, put } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import * as calls from '../calls/index.js';
import * as actionsTypes from '../../store/types';
import notifications from '../../../../utils/notifications';
import eventManager from '../../../../utils/eventManager';


export function* saveRecovery(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveRecovery', value: true } });

    const request = calls.saveRecovery(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveRecovery', value: false } });
      notifications.success("La recuperación ha sido almacenada con éxito")
    } else {
      notifications.error('Error al guardar folio');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveRecovery', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getAllRecoveries(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveriesData', value: true } });

    const request = calls.getAllRecoveries(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;


    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveriesData', value: false } });
      yield put({ type: actionsTypes.SET_ALL_RECOVERIES, payload: serverResponse.data  });
    } else {
      notifications.error('Error al obtener las devoluciones');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveRecovery', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getRecoveryById(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryphoneById', value: true } });

    const request = calls.getRecoveryById(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryphoneById', value: false } });
      yield put({ type: actionsTypes.SET_RECOVERY_PHONE_BY_ID, payload: serverResponse.data  });
    } else {
      notifications.error(`Error al obtener la información`);
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryphoneById', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  };
};

export function* getRecoveryByImei(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryphoneByImei', value: true } });

    const request = calls.getRecoveryByImei(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryphoneByImei', value: false } });
      yield put({ type: actionsTypes.SET_RECOVERY_PHONE_BY_IMEI, payload: serverResponse.data  });

      eventManager.emit('phone-with-recovery');

      if(isEmpty(serverResponse.data)){
        notifications.error(serverResponse.message);
        eventManager.emit('phone-without-recovery');
      }
    } else {
      notifications.error(serverResponse.message);
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryphoneByImei', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  };
};

export function* getRecoveryPhoneByIMEI(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryphoneByImei', value: true } });

    const request = calls.getRecoveryPhoneByIMEI(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryphoneByImei', value: false } });
      yield put({ type: actionsTypes.SET_RECOVERY_PHONE_BY_IMEI, payload: serverResponse.data  });
    } else {
      notifications.error(`${serverResponse.message}`);
      eventManager.emit('showCreatePhoneAction');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryphoneByImei', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  };
};

export function* getRecoveryEvidence(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryEvidence', value: true } });

    const request = calls.getRecoveryEvidence(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryEvidence', value: false } });
      yield put({ type: actionsTypes.SET_RECOVERY_EVIDENCE, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer las evidencias');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryEvidence', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* uploadRecoveryEvidence(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryEvidenceUpload', value: true } });

    const request = calls.uploadRecoveryEvidence(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryEvidenceUpload', value: false } });
      eventManager.emit('uploaded_recovery_file' );
      notifications.success('Archivo cargado con exito');
    } else {
      notifications.error('Error al subir el documento');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryEvidenceUpload', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getRecoveryEvidenceUrl(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryEvidenceURL', value: true } });

    const request = calls.getRecoveryEvidenceUrl(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryEvidenceURL', value: false } });
      eventManager.emit('recovery_evidence_url', serverResponse.data);
    } else {
      notifications.error('Error al traer el url');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryEvidenceURL', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* modRecoveryStatus(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'modRecoveryStatus', value: true } });

    const request = calls.modRecoveryStatus(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'modRecoveryStatus', value: false } });
      notifications.success('Folio de recuperación actualizado correctamente.');
      eventManager.emit('returntobranchview');
    } else {
      notifications.error('Error al cambiar folios de status');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'modRecoveryStatus', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getRecoveriesLogs(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryLogs', value: true } });

    const request = calls.getRecoveriesLogs(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryLogs', value: false } });
      yield put({ type: actionsTypes.SET_RECOVERY_LOGS, payload: serverResponse.data });

    } else {
      notifications.error('Error al traer los Logs');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'recoveryLogs', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* changeRecoveryStatus(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateRecoveryStatus', value: true } });

    const request = calls.changeRecoveryStatus(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateRecoveryStatus', value: false } });
      eventManager.emit('updated_recoveries');
      notifications.success('Folio de recuperación actualizado correctamente.');
    } else {
      notifications.error('Error al cambiar folios de status');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateRecoveryStatus', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};
