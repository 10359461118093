import { takeLatest, all } from 'redux-saga/effects';
import * as actionsTypes from '../store/types';
// import * as workers from './workers';
import * as workers from './workers/index';


export default function* watcherSaga() {
  yield all([
    // =================== BRANCH =========================
    takeLatest(actionsTypes.GET_USER_BY_SESSION, workers.getUserDataBySession),
    takeLatest(actionsTypes.GET_BRANCHES, workers.getBranches),
    takeLatest(actionsTypes.GET_DASHBOARD_STATS, workers.getDashboardStats),
    takeLatest(actionsTypes.GET_SEPOMEX_INFO, workers.getSepomexInfo),
    takeLatest(actionsTypes.GET_OTP, workers.getOtp),
    takeLatest(actionsTypes.VALIDATE_OTP, workers.validateOtp),

    // ================== CUSTOMERS ========================
    takeLatest(actionsTypes.GET_CUSTOMER_DATA, workers.getCustomerData),
    takeLatest(actionsTypes.GET_CUSTOMER_FINANCING_DATA, workers.getCustomerFinancingData),
    takeLatest(actionsTypes.SAVE_CUSTOMER_DATA, workers.saveCustomerData),
    takeLatest(actionsTypes.SAVE_APPLICATION_FORM, workers.saveApplicationForm),
    takeLatest(actionsTypes.GET_BIO_DATA, workers.getBioData),
    takeLatest(actionsTypes.SAVE_BIO_DATA, workers.saveBioData),
    takeLatest(actionsTypes.GET_CUSTOMER_SCORE, workers.getCustomerScore),
    takeLatest(actionsTypes.SAVE_CUSTOMER_SCORE, workers.saveCustomerScore),

    // ================== FINANCING ========================
    takeLatest(actionsTypes.SAVE_ADITIONAL_FORM, workers.saveAditionalForm),
    takeLatest(actionsTypes.SAVE_FINANCING_DATA, workers.saveFinancingData),
    takeLatest(actionsTypes.GET_FINANCING_DATA, workers.getFinancingData),
    takeLatest(actionsTypes.GET_FINANCING_BY_IMEI, workers.getFinancingByImei),
    takeLatest(actionsTypes.GET_ENRROLLED_PHONES, workers.getEnrrolledPhones),
    takeLatest(actionsTypes.SAVE_FINANCING_APPLICATION, workers.saveFinancingApplication),
    takeLatest(actionsTypes.GET_FINANCING_APPLICATION, workers.getFinancingApplication),
    takeLatest(actionsTypes.GET_FINANCING_PAYMENTS, workers.getFinancingPayments),
    takeLatest(actionsTypes.SAVE_PAYMENT, workers.savePayment),
    takeLatest(actionsTypes.GET_BANKS_LIST, workers.getBanksList),
    takeLatest(actionsTypes.GET_FINANCING_TABLE, workers.getFinancingTable),
    takeLatest(actionsTypes.GET_FINANCING_DETAIL, workers.getFinancingDetail),

    // ==================== PHONES ==========================
    takeLatest(actionsTypes.GET_PHONES, workers.getPhonesCat),
    takeLatest(actionsTypes.GET_ALL_PHONES, workers.getPhonesFullCat),
    takeLatest(actionsTypes.GET_PHONE_DATA, workers.getPhoneByImei),
    takeLatest(actionsTypes.GET_PHONE_BY_IMEI, workers.getPhoneByImei),
    takeLatest(actionsTypes.GET_PHONE_DETAIL, workers.getPhoneDetail),
    takeLatest(actionsTypes.GET_PHONE_DETAIL_ARRAY, workers.getPhonesArray),
    takeLatest(actionsTypes.GET_PHONE_BY_SCORE, workers.getPhonesByScore),
    takeLatest(actionsTypes.GET_PHONES_BY_BRAND, workers.getPhonesByBrand),
    takeLatest(actionsTypes.GET_PHONES_BRANDS, workers.getPhonesBrandsCat),
    takeLatest(actionsTypes.GET_WARRANTY_PHONES_BRANDS, workers.getPhonesBrandsWarr),
    takeLatest(actionsTypes.GET_PHONES_STORAGES, workers.getPhonesStoragesCat),
    takeLatest(actionsTypes.GET_PHONES_REAR_CAMERAS, workers.getPhonesRearCamerasCat),
    takeLatest(actionsTypes.GET_PHONES_FRONTAL_CAMERAS, workers.getPhonesFrontalCamerasCat),
    takeLatest(actionsTypes.GET_PHONES_SIMS, workers.getPhonesSIMsCat),
    takeLatest(actionsTypes.GET_PHONES_OS, workers.getPhonesOSsCat),
    takeLatest(actionsTypes.GET_PHONES_SCREENS, workers.getPhonesScreensCat),
    takeLatest(actionsTypes.GET_PHONES_SERVICES, workers.getPhonesServicesCat),
    takeLatest(actionsTypes.SAVE_PHONE, workers.savePhone),
    takeLatest(actionsTypes.SAVE_PHONE_DATA, workers.savePhoneData),
    takeLatest(actionsTypes.GET_CAT_PHONE_BY_BRAND, workers.getCategoryPhonesByBrand), //! NO SE USA

    // ================== RECOVERIES ========================
    takeLatest(actionsTypes.SAVE_RECOVERY, workers.saveRecovery),
    takeLatest(actionsTypes.GET_ALL_RECOVERIES, workers.getAllRecoveries),
    takeLatest(actionsTypes.GET_RECOVERY_PHONE_BY_ID, workers.getRecoveryById),
    takeLatest(actionsTypes.GET_PHONE_RECOVERY_BY_IMEI, workers.getRecoveryByImei),
    takeLatest(actionsTypes.GET_RECOVERY_PHONE_BY_IMEI, workers.getRecoveryPhoneByIMEI),
    takeLatest(actionsTypes.GET_RECOVERY_EVIDENCE, workers.getRecoveryEvidence),
    takeLatest(actionsTypes.SAVE_RECOVERY_EVIDENCE, workers.uploadRecoveryEvidence),
    takeLatest(actionsTypes.GET_RECOVERY_EVIDENCE_URL, workers.getRecoveryEvidenceUrl),
    takeLatest(actionsTypes.MOD_RECOVERY_STATUS, workers.modRecoveryStatus),
    takeLatest(actionsTypes.GET_RECOVERY_LOGS, workers.getRecoveriesLogs),
    takeLatest(actionsTypes.UPDATE_RECOVERY_STATUS, workers.changeRecoveryStatus), //! NO SE USA

    // ================== WARRANTIES ========================
    takeLatest(actionsTypes.SAVE_INVOICE, workers.saveInvoice),
    takeLatest(actionsTypes.GET_ALL_WARRANTY_STATUS, workers.getAllWarrantyStatus),
    takeLatest(actionsTypes.GET_INVOICE_TABLE_BY_STATUS, workers.getInvoiceTableByStatus),
    takeLatest(actionsTypes.UPDATE_WARRANTIES_STATUS, workers.changeInvoiceStatus),
    takeLatest(actionsTypes.SAVE_INVOICE_SWAP, workers.saveInvoiceSwap),
    takeLatest(actionsTypes.GET_WARRANTY_DETAIL, workers.getWarrantyDetail),
    takeLatest(actionsTypes.GET_WARRANTY_EVIDENCE, workers.getWarrantyEvidence),
    takeLatest(actionsTypes.SAVE_WARRANTY_EVIDENCE, workers.uploadWarrantyEvidence),
    takeLatest(actionsTypes.GET_WARRANTY_EVIDENCE_URL, workers.getWarrantyEvidenceUrl),
    takeLatest(actionsTypes.GET_WARRANTY_LOGS, workers.getWarrantyLogs),
    takeLatest(actionsTypes.GET_BRANCH_WARRANTIES, workers.getBranchWarranties),
    takeLatest(actionsTypes.GET_BRANCH_WARRANTY, workers.getBranchWarranty), //? NUEVO
    takeLatest(actionsTypes.GET_CUSTOMER_WARRANTY, workers.getCustomerWarranty), //? NUEVO
    takeLatest(actionsTypes.GET_BRANCH_LOGS, workers.getBranchLogs),
    takeLatest(actionsTypes.SAVE_WARRANTY_BRANCH, workers.saveWarrantyBranch),
    takeLatest(actionsTypes.GET_BRANCH_WARRANTY_BY_IMEI, workers.getBranchWarrantyByImei),
    takeLatest(actionsTypes.GET_SWAP_WARRANTY, workers.getWarrantySwap),
    takeLatest(actionsTypes.GET_ACCESORIES, workers.getAccesories),
    takeLatest(actionsTypes.GET_FAILURES, workers.getFailures),
    takeLatest(actionsTypes.GET_SYMPTOMS, workers.getSymptoms),
    takeLatest(actionsTypes.UPDATE_WARRANTY_DETAIL, workers.updateWarrantyDetail), //! NO SE USA
  ]);
}
