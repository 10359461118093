import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { BLUE, CONTAINERS_BORDER, WHITE } from '../colors';
import notifications from '../utils/notifications';
import tokens from '../utils/tokens';
import { ImagePicker } from './ImagePicker';
import getWindow from '../utils/getWindow';
import { isEqual } from 'lodash';
import eventManager from '../utils/eventManager';

const { Column } = Grid;

const EXTENSIONS = ['jpg', 'png', 'jpeg'];
const MAX_SIZE_IMG = 14000;
const MAX_SIZE_VIDEO = 25600;

class ImageGallery extends Component {
  constructor(props) {
    super(props);

    this.state = { gallery: {} };
  }

  componentDidMount(){
    if (this.props.prevData) {
      this.setData();
    };

    this.revicedImage = eventManager.on('save_cropped', (data) => {

      this.getImageBase64(data.image, (base64, dimensions) => {
        const copy = {...this.state.gallery, [data.id]: { base64, dimensions, type: 'image' }};
        this.setState({ gallery: copy });
      });
    });

    this.closedCropperCallbackID = eventManager.on('closed_croppper', () => {
      this.inputRefImage.value = null;
    });
  };

  componentDidUpdate(prevProps){
    if (!isEqual(prevProps.prevData, this.props.prevData)) {
      this.setData();
    }
  }

  //-----------------
  //  Adding Base64
  //-----------------

  onChangeImageFile(e) {
    const { gallery } = this.state;
    if (e.target.files.length) {
      const currentFile = e.target.files[0];
      let format = currentFile.name.split('.');
      format = format[format.length - 1];
      const size = currentFile.size / 1024;

      if (size > MAX_SIZE_IMG) {
        notifications.error(`The image size must be less than ${this.getMaxSizeLabel('image')}`);
      } else if (!this.validExtension(format)) {
        notifications.error(`The file extension must be one of ${EXTENSIONS.toString()}`);
      } else {
        this.getImageBase64(currentFile, (base64, dimensions) => {
          const token = tokens.getToken();

          if (this.props.cropped) {
            eventManager.emit('open-cropper', {
              id: token,
              image: base64,
              height: 500,
              width: 500
            });
          } else {
            const copy = {...gallery, [token]: { base64, dimensions, type: 'image' }};
            this.setState({ gallery: copy });
          }
        });
      };
    };
    this.inputRefImage.value = null;
  };

  getMaxSizeLabel(type) {
    const type_size = type === 'image' ? MAX_SIZE_IMG : MAX_SIZE_VIDEO;
    return `${type_size / 1024} MB`;
  };

  validExtension(format) {
    const foundExtensions = EXTENSIONS.filter((item) => item === format.toLowerCase());
    return !!foundExtensions.length;
  };

  getImageBase64(currentFile, callback) {
    const reader = new FileReader();
    let base64;

    reader.onloadend = () => {
      const windowObject = getWindow();
      const img = new windowObject.Image();
      img.src = reader.result;

      img.addEventListener('load', () => {
        const dimensions = { width: img.width, height: img.height };
        base64 = reader.result;
        callback(base64, dimensions);
      });
    };

    reader.readAsDataURL(currentFile);
  };

  //-----------------
  //   Ref Methods
  //-----------------

  getData(){
    const { gallery } = this.state;
    return gallery;
  }

  //-----------------
  //     Methods
  //-----------------

  setData(){
    const { prevData } = this.props;
    const loadedData = {};
    if (prevData) {
      prevData.forEach(element => {
        loadedData[element.phone_gallery_id] = {
          url: element.image,
          type: 'image'
        };
      });

      this.setState({ gallery: loadedData });
    };
  }

  onDeleteGalleyItem(token){
    const { gallery } = this.state;

    if (gallery[token].url) {
      this.props.onDelete({ phone_gallery_id: token });
    };

    let copy = {...gallery};
    delete copy[token];

    this.setState({ gallery: copy });
  }

  //-----------------
  //     Renders
  //-----------------
  renderGallery() {
    const { gallery } = this.state;
    const content = [];
    const galleryArray = Object.keys(gallery);

    if (galleryArray && galleryArray.length) {
      galleryArray.forEach(token => {
        const file = gallery[token].url ? `${gallery[token].url}` : gallery[token].base64;

        if (gallery[token].type === 'image') {
          content.push(
            <div key={token} style={{ height: 200, width: 200, marginRight: 10, marginBottom: 10 }}>
              <ImagePicker
                image={file}
                title={token}
                hideTitle
                hiddeSize
                height={500}
                width={500}
                onlyDelete
                onDelete={() => this.onDeleteGalleyItem(token)}
              />
            </div>
          );
        };
      });
    };

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 20, justifyContent: 'flex-start' }}>
        {content}
      </div>
    );
  };

  render() {
    const { gallery } = this.state;
    const galleryArray = Object.keys(gallery);
    let disabled = false;

    if (galleryArray && galleryArray.length && galleryArray.length === 3) {
      disabled = true;
    }

    return (
      <div style={{ marginTop: 30}}>
        <span style={{ fontSize: 17, fontWeight: 'bold', margin: 0}}>Galeria</span>

        <Grid>
          <Column width={16}>
            <div style={{ border: CONTAINERS_BORDER, borderRadius: 10, padding: '20px 15px', backgroundColor: WHITE }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <span style={{ color: '#000000', marginBottom: 0, maxWidth: '85%' }}></span>

                <div>
                  <Button disabled={disabled} style={{ color: WHITE, background: BLUE, borderRadius: 12 }}>
                    <label htmlFor={'image'}>
                      <Icon name='file image outline'/> <span>Agregar</span>
                    </label>
                  </Button>
                </div>
              </div>

              <div>
                {this.renderGallery()}
              </div>
            </div>
          </Column>
        </Grid>

        <input
          ref={((inputRefImage) => { this.inputRefImage = inputRefImage; })}
          type="file"
          hidden
          accept="image/*"
          name={'image'}
          id={'image'}
          style={{ visibility: false }}
          onChange={this.onChangeImageFile.bind(this)}
        />

      </div>
    );
  };
};

ImageGallery.propTypes = {
  prevData: PropTypes.array,
  onDelete: PropTypes.func,
  cropped: PropTypes.bool,

};

export { ImageGallery };
