export const START_VIEW_TRANSITION = 'start-view-transition';
export const SHOW_NOTIFICATION = 'show-notification';

export const API_URL = 'https://sip.paycel.uno/api';
// export const API_URL = 'http://192.168.3.188:4000';

export const CAPREPA_USER = 'caprepa-user';

export const CAPREPA_COOKIES = {
  EMPLOYEE_ID: 'idEmployee',
  FIRST_NAME: 'firstName',
  MIDDLE_NAME: 'middleName',
  LAST_NAME: 'lastName',
  SECOND_LAST_NAME: 'secondLastName',
  CURP: 'CURP',
  ADMISSION_DATE: 'admissionDate',
  EMAIL: 'email',

  BRANCH: 'branch',
  BRAND: 'businessUnit',
  BRAND_ID: 'idBusinessUnit',

  NEIGHBORHOOD_ID: 'idColony',
  LOCATION: 'street',
  NUMBER: 'outdoorNumber',
  POSTAL_CODE: 'postalCode'
};

export const MASTER_TOKEN = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJicmFuZCI6IlJpZXNnb3MiLCJpYXQiOjE3MTAyODk2Nzd9.RWDCRzad5G6LGNn2uNqRLysK1A2PlEdvDmqTaI9mP0g';

export const MOBILE_BREAKPOINT = 768;
export const COOKIES_ACCEPTED = 'cookies-accepted';

export const PAYCEL_TOKEN = 'paycel_token';
export const USER_DATA = 'userData';

export const GRADIENT_DIRECTIONS = {
  TO_BOTTOM: 'to bottom',
  TO_RIGHT: 'to right',
  TO_CORNER_RIGHT: 'to corner right',
  TO_CORNER_LEFT: 'to corner left'
};

export const WARRANITES_ROLES = {
  MANAGER: "manager",
  SUPERVISOR: "supervisor",
  QUALITY: "quality",
  TECHNICIAN: "technician",
  LOGISTICS: "logistics",
  WAREHOUSE: "warehouse"
};

export const WARRANITES_STATUS = {
  RECIEN_CREADO: "Creacion de folio",
  PENDIENTE_GUIA_CS: "Pendiente de guia",
  PENDIENTE_ENVIO_CS: "Listo para envio",
  ENVIADO_CS: "Por recibir en CS",
  RECIBIDO_CS: "Recibido en CS",
  TECNICO: "Revision",
  LABORATORIO: "Laboratorio",
  COTIZACION: "Cotizaciones",
  CALIDAD: "Calidad",
  PENDIENTE_GUIA_SUCURSAL: "Por enviar",
  ENVIADO_SUCURSAL: "Por recibir en Sucursal",
  EN_SUCURSAL: "Equipo en sucursal",
  ENTREGADO_CLIENTE: "Entregado",
  ABANDONO: "Abandono",
  ENVIO_PROVEEDORES: "Por envio a Proveedores",
  PROVEEDORES: "Proveedores",
  RECUPERACION: "Recuperaciones",
  DEMANDAS: "Demandas y cobranza",
  RESGUARDO: "Resguardo",
  FACTURACION: "Faturacion",
  NOTA_CREDITO: "Notas de credito",
  EXTRAVIOS: "Extravios",
  INVENTARIO: "Inventario",
  SCRAP: "Scrap"
};

export const MENUS_WARANTY = {
  HOME: "Inicio",
  INVOICE: "Folios",
  NOTIFICATIONS: "Notificaciones",
  PROCESS: "Proceso",
  BRANCHES: "Sucursales",
  WAREHOUSE: "Alamacen",
  LOGISTICS: "Logistica",
  SUPPLIERS: "Proveedores",
  SETTINGS: "Ajustes"
};

export const MENUS_BRANCH = {
  HOME: "Inicio",
  SELL_TERMINAL: "Terminal PV",
  FINNANCINGS: "Financiamiento",
  SERVICES: "Servicios",
  WAREHOUSE: "Alamacen",
  WARANTIES: "Garantias",
  ASSISTANCE: "Asistencia",
};

export const DOCUMENTS = [
  { id: 0, value: 0, key: 0, text: 'Orden Servicio' },
  { id: 1, value: 1, key: 1, text: 'Orden Servicio (Firmada)' },
  { id: 2, value: 2, key: 2, text: 'Evidencia del estado físico' },
  { id: 3, value: 3, key: 3, text: 'Guía de envío (De sucursal a CS)' },
  { id: 4, value: 4, key: 4, text: 'Diagnóstico' },
  { id: 5, value: 5, key: 5, text: 'Cotización' },
  { id: 6, value: 6, key: 6, text: 'Guía de envío (De SC a Proveedor)' },
  { id: 7, value: 7, key: 7, text: 'Factura de compra del equipo con el proveedor' },
  { id: 8, value: 8, key: 8, text: 'Guía de envío (De proveedor a CS)' },
  { id: 9, value: 9, key: 9, text: 'Calidad' },
  { id: 10, value: 10, key: 10, text: 'Guía de envío (SC a sucursal)' },
  { id: 12, value: 12, key: 12, text: 'Evidencia de entrega a Paqueteria' },
  { id: 11, value: 11, key: 11, text: 'Evidencia de entrega a Cliente' },
  { id: 17, value: 17, key: 17, text: 'Evidencia de entrega a Sucursal' },
  { id: 13, value: 13, key: 13, text: 'Evidencia de reparación' },
  { id: 14, value: 14, key: 14, text: 'Ticket de pago de cotización' },
  { id: 15, value: 15, key: 15, text: 'Evidencia de SWAP' },
  { id: 16, value: 16, key: 16, text: 'Otros' },

];

export const DOCUMENTSRECOVERIES = [
  { id: 1, value: 1, key: 1, text: 'evidencia de recepción sucursal' },
  { id: 2, value: 2, key: 2, text: 'evidencia de envio Sucursar - CS' },
  { id: 3, value: 3, key: 3, text: 'evidencia de recepción CS' },
  { id: 4, value: 4, key: 4, text: 'evidencia de envio CS - CD (centro de distribución)' },
  { id: 5, value: 5, key: 5, text: 'evidencia de recepción CD' },
  { id: 6, value: 6, key: 6, text: 'evidencia de desctruccion (scrap)' },
]

export const ROLES = {
  SUPER_ADMIN: 'super-admin',
  ADMIN: 'admin'
};

export const COLAB_APPLICATION_STEPS = {
  1: "Solicitud Generada",
  2: "Solicitud Firmada",
  3: "Aprobado por RH",
  4: "Aprobado por Nominas",
  5: "Aprobado por Atencion al cliente",
  6: "Solicitud Finalizada",
  7: "Solicitud Pagada",
  98: "Solicitud Rechazada",
  99: "Solicitud Eliminada"
};

export const COLAB_APPLICATION_STATUS = {
  START: 1,
  RH: 2,
  PAYROLL: 3,
  CUSTOMER_SERVICES: 4,
  SUPPLY_CHAIN: 5,
  FINISHED: 6,
  PAID: 7,
  REJECTED: 98,
  DELETED: 99,
};

export const INCODE = {
  API_URL: 'https://demo-api.incodesmile.com/',
  API_KEY: '7720b919246109ddf369aad778eaf987f1390435',
  CLIENT_ID: '38mf945',
  CONF_ID: '655fe2378fa29a49fb372be4'
};

export const PHONE_TIERS = [
  { id: 'GAMA DE ENTRADA', value: 'GAMA DE ENTRADA', key: 'GAMA DE ENTRADA', text: 'GAMA DE ENTRADA' },
  { id: 'GAMA INTERMEDIA', value: 'GAMA INTERMEDIA', key: 'GAMA INTERMEDIA', text: 'GAMA INTERMEDIA' },
  { id: 'GAMA ALTA', value: 'GAMA ALTA', key: 'GAMA ALTA', text: 'GAMA ALTA' },
  { id: 'GAMA PREMIUM', value: 'GAMA PREMIUM', key: 'GAMA PREMIUM', text: 'GAMA PREMIUM' }
]
