import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Label } from 'semantic-ui-react';
import { isEqual, eq } from 'lodash';
import formatter from '../../../utils/formatter';
import { FadeInView } from '../../FadeInView';
import i18n from '../utils/i18n.json';

const { errorRequired } = i18n.text;

class UrlInput extends Component {
  constructor(props) {
    super(props);
    let valid;

    if (props.defaultValue) {
      valid = true;
    } else {
      valid = !props.required;
    }

    this.state = {
      value: props.defaultValue ? props.defaultValue : '',
      valid,
      message: props.required ? errorRequired[props.language].replace('#label', props.label.toLowerCase()) : '',
      dirty: false,
      errorVisible: false,
    };
  }


  // -----------------------------
  // ------ life cycle events ----
  // -----------------------------
  componentDidMount() {
    this.setForm();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.dirty && nextProps.defaultValue) {
      this.setState({ value: nextProps.defaultValue, valid: true });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !isEqual(nextState, this.state) || !isEqual(clean(nextProps), clean(this.props));
  }

  componentDidUpdate(prevProps, prevState) {
    if (!eq(prevState, this.state)) {
      this.setForm();
    }
  }


  // -----------------------
  // ------ user events ----
  // -----------------------
  onChange(e) {
    this.setState({ dirty: true });

    if (this.props.required && !e.target.value) {
      this.setState({
        valid: false,
        value: '',
        message: errorRequired[this.props.language].replace('#label', this.props.label.toLowerCase()),
        errorVisible: this.props.showErrors,
      });
    } else if (this.props.max && e.target.value.length <= this.props.max) {
      this.setState({ valid: true, errorVisible: false, message: '', value: e.target.value });
    } else if (!this.props.max) {
      this.setState({ valid: true, errorVisible: false, message: '', value: e.target.value });
    }
  }

  onBlur(e) {
    const regex = /(https?: \/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    this.setState({ value: e.target.value, dirty: true });

    if (this.props.required && !e.target.value) {
      this.setState({
        valid: false,
        message: errorRequired[this.props.language].replace('#label', this.props.label.toLowerCase()),
        errorVisible: this.props.showErrors,
      });
    } else if (e.target.value && !regex.test(e.target.value)) {
      this.setState({
        valid: false,
        message: errorRequired[this.props.language].replace('#label', this.props.label.toLowerCase()),
        errorVisible: this.props.showErrors,
      });
    } else {
      this.setState({ valid: true, errorVisible: false, message: '' });

      if (this.props.onBlur) {
        this.props.onBlur(e.target.value);
      }
    }
  }

  setForm() {
    this.props.setFormData(this.props.name, this.state);
  }

  getValue() {
    return this.state.value.toLowerCase();
  }

  dirtInput() {
    this.setState({ dirty: true });
  }

  resetInput() {
    let valid;

    if (this.props.defaultValue) {
      valid = true;
    } else {
      valid = !this.props.required;
    }

    this.setState({
      value: this.props.defaultValue ? this.props.defaultValue : '',
      valid,
      message: this.props.required ? errorRequired[this.props.language].replace('#label', this.props.label.toLowerCase()) : '',
      dirty: false,
      errorVisible: false,
    });
  }

  showError() {
    this.setState({ errorVisible: true });
  }


  // --------------------------
  // ------ render methods ----
  // --------------------------
  renderErrorLabel() {
    const { errorVisible, message } = this.state;

    if (errorVisible) {
      return (
        <FadeInView style={styles.popUpContainer}>
          <Label basic pointing color="red" style={styles.popUpErrorLabel}>
            {message}
          </Label>
        </FadeInView>
      );
    }

    return null;
  }

  render() {
    const invalidInput = this.state.dirty && !this.state.valid;
    let className = '';
    let finalLabelStyle = { ...styles.label, ...this.props.labelStyle };
    let finalInputStyle = { ...styles.input, ...this.props.inputStyle };

    if (this.props.className) {
      className = `${this.props.className} ${invalidInput ? 'invalid' : 'valid'}`;
    } else {
      className = invalidInput ? 'invalid' : 'valid';
    }

    if (invalidInput) {
      finalLabelStyle = { ...this.props.labelStyle, ...styles.errorLabel };
      finalInputStyle = { ...this.props.inputStyle, ...styles.errorInput };
    }

    const labelString = this.props.required ? `${this.props.label} *` : this.props.label;

    // render input
    if (this.props.inlineLabel) {
      return (
        <Form.Field onBlur={this.onBlur.bind(this)}>
          <Input
            label={labelString}
            value={this.state.value}
            name={this.props.name}
            className={className}
            onChange={this.onChange.bind(this)}
            disabled={this.props.readOnly}
            placeholder={this.props.placeholder}
            style={finalInputStyle}
          />

          {this.renderErrorLabel()}
        </Form.Field>
      );
    }

    return (
      <Form.Field onBlur={this.onBlur.bind(this)}>
        <label style={finalLabelStyle}>
          {labelString}
        </label>

        <input
          value={this.state.value}
          name={this.props.name}
          className={className}
          onChange={this.onChange.bind(this)}
          disabled={this.props.readOnly}
          placeholder={this.props.placeholder}
          style={finalInputStyle}
        />

        {this.renderErrorLabel()}
      </Form.Field>
    );
  }
}


// estilos
const styles = {
  label: {

  },
  errorLabel: {
    color: '#9F3A38',
  },
  input: {

  },
  errorInput: {
    background: 'rgba(224, 180, 180, 0.48)',
    border: '1px solid #9F3A38',
    color: '#9F3A38',
  },
  popUpContainer: {
    position: 'absolute',
    top: 75,
    left: 0,
    zIndex: 100,
  },
  popUpErrorLabel: {
    fontSize: 13,
    textAlign: 'center',
    boxShadow: 'rgba(100, 100, 100, 0.25) 0px 2px 4px',
  },
};


UrlInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  inlineLabel: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  showErrors: PropTypes.bool,
  readOnly: PropTypes.bool,
  labelStyle: PropTypes.object,
  max: PropTypes.number,
  inputStyle: PropTypes.object,
  required: PropTypes.bool,
  setFormData: PropTypes.func.isRequired,
  language: PropTypes.string,
  onBlur: PropTypes.func
};


// exportar componente
export default UrlInput;
