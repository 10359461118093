// =================== COMMON =========================
export const SET_LOADING = 'branch/SET_LOADING';
export const SET_SELECTED_VIEW = 'branch/SET_SELECTED_VIEW';


// =================== BRANCH =========================
export const GET_USER_BY_SESSION = 'branch/GET_USER_BY_SESSION';
export const SET_USERDATA = 'branch/SET_USERDATA';
export const UNSET_USERDATA = 'branch/UNSET_USERDATA';

export const GET_BRANCHES = 'branch/GET_BRANCHES';
export const SET_BRANCHES = 'branch/SET_BRANCHES';

export const GET_DASHBOARD_STATS = 'branch/GET_DASHBOARD_STATS';
export const SET_DASHBOARD_STATS = 'branch/SET_DASHBOARD_STATS';
export const UNSET_DASHBOARD_STATS = 'branch/UNSET_DASHBOARD_STATS';

export const GET_SEPOMEX_INFO = 'branch/GET_SEPOMEX_INFO';
export const SET_SEPOMEX_INFO = 'branch/SET_SEPOMEX_INFO';
export const UNSET_ADD_SEPOMEX_INFO = 'branch/UNSET_ADD_SEPOMEX_INFO';
export const UNSET_SEPOMEX_INFO = 'branch/UNSET_SEPOMEX_INFO';

export const GET_OTP = 'branch/GET_OTP';
export const VALIDATE_OTP = 'branch/VALIDATE_OTP';


// =================== CUSTOMERS =========================
export const GET_CUSTOMER_DATA = 'branch/GET_CUSTOMER_DATA';
export const SET_CUSTOMER_DATA = 'branch/SET_CUSTOMER_DATA';
export const UNSET_CUSTOMER_DATA = 'branch/UNSET_CUSTOMER_DATA';

export const GET_CUSTOMER_FINANCING_DATA = 'branch/GET_CUSTOMER_FINANCING_DATA';
export const SET_CUSTOMER_FINANCING_DATA = 'branch/SET_CUSTOMER_FINANCING_DATA';

export const SAVE_CUSTOMER_DATA = 'branch/SAVE_CUSTOMER_DATA';

export const SAVE_APPLICATION_FORM = 'branch/SAVE_APPLICATION_FORM';

export const GET_BIO_DATA = 'branch/GET_BIO_DATA';
export const SET_BIO_DATA = 'branch/SET_BIO_DATA';
export const UNSET_BIO_DATA = 'branch/UNSET_BIO_DATA';

export const SAVE_BIO_DATA = 'branch/SAVE_BIO_DATA';

export const GET_CUSTOMER_SCORE = 'branch/GET_CUSTOMER_SCORE';
export const SET_CUSTOMER_SCORE = 'branch/SET_CUSTOMER_SCORE';


export const SAVE_CUSTOMER_SCORE = 'branch/SAVE_CUSTOMER_SCORE';
export const SET_CUSTOMER_SCORE_DATA = 'branch/SET_CUSTOMER_SCORE_DATA';


// =================== FINANCING =========================
export const SAVE_ADITIONAL_FORM = 'branch/SAVE_ADITIONAL_FORM';

export const SAVE_FINANCING_DATA = 'branch/SAVE_FINANCING_DATA';

export const GET_FINANCING_DATA = 'branch/GET_FINANCING_DATA';
export const SET_FINANCING_DATA = 'branch/SET_FINANCING_DATA';
export const UNSET_FINANCING_DATA = 'branch/UNSET_FINANCING_DATA';

export const GET_FINANCING_BY_IMEI = 'branch/GET_FINANCING_BY_IMEI';
export const SET_FINANCING_BY_IMEI = 'branch/SET_FINANCING_BY_IMEI';
export const UNSET_FINANCING_BY_IMEI = 'branch/UNSET_FINANCING_BY_IMEI';

export const SAVE_FINANCING_USER_DATA = 'branch/SAVE_FINANCING_USER_DATA';
export const SET_FINANCING_USER_DATA = 'branch/SET_FINANCING_USER_DATA';

export const GET_ENRROLLED_PHONES = 'branch/GET_ENRROLLED_PHONES';
export const SET_ENRROLLED_PHONES = 'branch/SET_ENRROLLED_PHONES';

export const SAVE_FINANCING_APPLICATION = 'branch/SAVE_FINANCING_APPLICATION';
export const GET_FINANCING_APPLICATION = 'branch/GET_FINANCING_APPLICATION';
export const SET_FINANCING_APPLICATION = 'branch/SET_FINANCING_APPLICATION';

export const GET_FINANCING_PAYMENTS = 'branch/GET_FINANCING_PAYMENTS';
export const SET_FINANCING_PAYMENTS = 'branch/SET_FINANCING_PAYMENTS';
export const SAVE_PAYMENT = 'branch/SAVE_PAYMENT';

export const GET_BANKS_LIST = 'branch/GET_BANKS_LIST';
export const SET_BANKS_LIST = 'branch/SET_BANKS_LIST';

export const GET_FINANCING_TABLE = 'branch/GET_FINANCING_TABLE';
export const SET_FINANCING_TABLE = 'branch/SET_FINANCING_TABLE';

export const GET_FINANCING_DETAIL = 'branch/GET_FINANCING_DETAIL';
export const SET_FINANCING_DETAIL = 'branch/SET_FINANCING_DETAIL';

// =================== PHONES =========================
export const GET_PHONES = 'branch/GET_PHONES';
export const SET_PHONES = 'branch/SET_PHONES';
export const UNSET_PHONES = 'branch/UNSET_PHONES';

export const GET_ALL_PHONES = 'branch/GET_ALL_PHONES';
export const SET_ALL_PHONES = 'branch/SET_ALL_PHONES';
export const UNSET_ALL_PHONES = 'branch/UNSET_ALL_PHONES';

export const GET_PHONE_DATA = 'branch/GET_PHONE_DATA';
export const SET_PHONE_DATA = 'branch/SET_PHONE_DATA';
export const UNSET_PHONE_DATA = 'branch/UNSET_PHONE_DATA';

export const GET_PHONE_BY_IMEI = 'branch/GET_PHONE_BY_IMEI';
export const SET_PHONE_BY_IMEI = 'branch/SET_PHONE_BY_IMEI';
export const UNSET_PHONE_BY_IMEI = 'branch/UNSET_PHONE_BY_IMEI';

export const GET_PHONE_DETAIL = 'branch/GET_PHONE_DETAIL';
export const SET_PHONE_DETAIL = 'branch/SET_PHONE_DETAIL';
export const GET_PHONE_DETAIL_ARRAY = 'branch/GET_PHONE_DETAIL_ARRAY';
export const SET_PHONE_DETAIL_ARRAY = 'branch/SET_PHONE_DETAIL_ARRAY';

export const GET_PHONE_BY_SCORE = 'branch/GET_PHONE_BY_SCORE';
export const SET_PHONE_BY_SCORE = 'branch/SET_PHONE_BY_SCORE';

export const GET_PHONES_BY_BRAND = 'branch/GET_PHONES_BY_BRAND';
export const SET_PHONES_BY_BRAND = 'branch/SET_PHONES_BY_BRAND';

export const GET_PHONES_BRANDS = 'branch/GET_PHONES_BRANDS';
export const SET_PHONES_BRANDS = 'branch/SET_PHONES_BRANDS';

export const GET_WARRANTY_PHONES_BRANDS = 'branch/GET_WARRANTY_PHONES_BRANDS';
export const SET_WARRANTY_PHONES_BRANDS = 'branch/SET_WARRANTY_PHONES_BRANDS';

export const GET_PHONES_STORAGES = 'branch/GET_PHONES_STORAGES';
export const SET_PHONES_STORAGES = 'branch/SET_PHONES_STORAGES';

export const GET_PHONES_REAR_CAMERAS = 'branch/GET_PHONES_REAR_CAMERAS';
export const SET_PHONES_REAR_CAMERAS = 'branch/SET_PHONES_REAR_CAMERAS';

export const GET_PHONES_FRONTAL_CAMERAS = 'branch/GET_PHONES_FRONTAL_CAMERAS';
export const SET_PHONES_FRONTAL_CAMERAS = 'branch/SET_PHONES_FRONTAL_CAMERAS';

export const GET_PHONES_SIMS = 'branch/GET_PHONES_SIMS';
export const SET_PHONES_SIMS = 'branch/SET_PHONES_SIMS';

export const GET_PHONES_OS = 'branch/GET_PHONES_OS';
export const SET_PHONES_OS = 'branch/SET_PHONES_OS';

export const GET_PHONES_SCREENS = 'branch/GET_PHONES_SCREENS';
export const SET_PHONES_SCREENS = 'branch/SET_PHONES_SCREENS';

export const GET_PHONES_SERVICES = 'branch/GET_PHONES_SERVICES';
export const SET_PHONES_SERVICES = 'branch/SET_PHONES_SERVICES';

export const SAVE_PHONE = 'branch/SAVE_PHONE';

export const SAVE_PHONE_DATA = 'branch/SAVE_PHONE_DATA';

export const GET_CAT_PHONE_BY_BRAND = 'branch/GET_CAT_PHONE_BY_BRAND';
export const SET_CAT_PHONE_BY_BRAND = 'branch/SET_CAT_PHONE_BY_BRAND';


// =================== RECOVERIES =========================
export const SAVE_RECOVERY = 'branch/SAVE_RECOVERY';

export const GET_ALL_RECOVERIES = 'branch/GET_ALL_RECOVERIES';
export const SET_ALL_RECOVERIES = 'branch/SET_ALL_RECOVERIES';

export const GET_RECOVERY_PHONE_BY_ID = 'branch/GET_RECOVERY_PHONE_BY_ID';
export const SET_RECOVERY_PHONE_BY_ID = 'branch/SET_RECOVERY_PHONE_BY_ID';

export const GET_PHONE_RECOVERY_BY_IMEI = 'branch/GET_PHONE_RECOVERY_BY_IMEI';
export const SET_PHONE_RECOVERY_BY_IMEI = 'branch/SET_PHONE_RECOVERY_BY_IMEI';
export const UNSET_PHONE_RECOVERY_BY_IMEI = 'branch/UNSET_PHONE_RECOVERY_BY_IMEI';

export const GET_RECOVERY_PHONE_BY_IMEI = 'branch/GET_RECOVERY_PHONE_BY_IMEI';
export const SET_RECOVERY_PHONE_BY_IMEI = 'branch/SET_RECOVERY_PHONE_BY_IMEI';
export const UNSET_RECOVERY_PHONE_BY_IMEI = 'branch/UNSET_RECOVERY_PHONE_BY_IMEI';

export const GET_RECOVERY_EVIDENCE = 'branch/GET_RECOVERY_EVIDENCE';
export const SET_RECOVERY_EVIDENCE = 'branch/SET_RECOVERY_EVIDENCE';

export const SAVE_RECOVERY_EVIDENCE = 'branch/SAVE_RECOVERY_EVIDENCE';

export const GET_RECOVERY_EVIDENCE_URL = 'branch/GET_RECOVERY_EVIDENCE_URL';

export const MOD_RECOVERY_STATUS = 'branch/MOD_RECOVERY_STATUS';

export const GET_RECOVERY_LOGS = 'branch/GET_RECOVERY_LOGS';
export const SET_RECOVERY_LOGS = 'branch/SET_RECOVERY_LOGS';

export const UPDATE_RECOVERY_STATUS = 'branch/UPDATE_RECOVERY_STATUS';


// =================== WARRANTIES =========================
export const SAVE_INVOICE = 'branch/SAVE_INVOICE';

export const GET_ALL_WARRANTY_STATUS = 'branch/GET_ALL_WARRANTY_STATUS';
export const SET_ALL_WARRANTY_STATUS = 'branch/SET_ALL_WARRANTY_STATUS';

export const GET_INVOICE_TABLE_BY_STATUS = 'branch/GET_INVOICE_TABLE_BY_STATUS';
export const SET_INVOICE_TABLE_BY_STATUS = 'branch/SET_INVOICE_TABLE_BY_STATUS';
export const UNSET_INVOICE_TABLE_BY_STATUS = 'branch/UNSET_INVOICE_TABLE_BY_STATUS';

export const UPDATE_WARRANTIES_STATUS = 'branch/UPDATE_WARRANTIES_STATUS';

export const SAVE_INVOICE_SWAP = 'branch/SAVE_INVOICE_SWAP';

export const GET_WARRANTY_DETAIL = 'branch/GET_WARRANTY_DETAIL';
export const SET_WARRANTY_DETAIL = 'branch/SET_WARRANTY_DETAIL';
export const UNSET_WARRANTY_DETAIL = 'branch/UNSET_WARRANTY_DETAIL';

export const GET_WARRANTY_EVIDENCE = 'branch/GET_WARRANTY_EVIDENCE';
export const SET_WARRANTY_EVIDENCE = 'branch/SET_WARRANTY_EVIDENCE';
export const UNSET_WARRANTY_EVIDENCE = 'branch/UNSET_WARRANTY_EVIDENCE';

export const SAVE_WARRANTY_EVIDENCE = 'branch/SAVE_WARRANTY_EVIDENCE';

export const GET_WARRANTY_EVIDENCE_URL = 'branch/GET_WARRANTY_EVIDENCE_URL';

export const GET_WARRANTY_LOGS = 'branch/GET_WARRANTY_LOGS';
export const SET_WARRANTY_LOGS = 'branch/SET_WARRANTY_LOGS';

export const GET_BRANCH_WARRANTIES = 'branch/GET_BRANCH_WARRANTIES';
export const SET_BRANCH_WARRANTIES = 'branch/SET_BRANCH_WARRANTIES';
export const UNSET_BRANCH_WARRANTIES = 'branch/UNSET_BRANCH_WARRANTIES';

export const GET_BRANCH_WARRANTY = 'branch/GET_BRANCH_WARRANTY'; //? NUEVO
export const SET_BRANCH_WARRANTY = 'branch/SET_BRANCH_WARRANTY'; //? NUEVO
export const UNSET_BRANCH_WARRANTY = 'branch/UNSET_BRANCH_WARRANTY'; //? NUEVO

export const GET_CUSTOMER_WARRANTY = 'branch/GET_CUSTOMER_WARRANTY'; //? NUEVO
export const SET_CUSTOMER_WARRANTY = 'branch/SET_CUSTOMER_WARRANTY'; //? NUEVO
export const UNSET_CUSTOMER_WARRANTY = 'branch/UNSET_CUSTOMER_WARRANTY'; //? NUEVO

export const GET_BRANCH_LOGS = 'branch/GET_BRANCH_LOGS';
export const SET_BRANCH_LOGS = 'branch/SET_BRANCH_LOGS';
export const UNSET_BRANCH_LOGS = 'branch/UNSET_BRANCH_LOGS';

export const SAVE_WARRANTY_BRANCH = 'branch/SAVE_WARRANTY_BRANCH';

export const GET_BRANCH_WARRANTY_BY_IMEI = 'branch/GET_BRANCH_WARRANTY_BY_IMEI';
export const SET_BRANCH_WARRANTY_BY_IMEI = 'branch/SET_BRANCH_WARRANTY_BY_IMEI';
export const UNSET_BRANCH_WARRANTY_BY_IMEI = 'branch/UNSET_BRANCH_WARRANTY_BY_IMEI';

export const GET_SWAP_WARRANTY = 'branch/GET_SWAP_WARRANTY';
export const SET_SWAP_WARRANTY = 'branch/SET_SWAP_WARRANTY';
export const UNSET_SWAP_WARRANTY = 'branch/UNSET_SWAP_WARRANTY';

export const GET_ACCESORIES = 'branch/GET_ACCESORIES';
export const SET_ACCESORIES = 'branch/SET_ACCESORIES';

export const GET_FAILURES = 'branch/GET_FAILURES';
export const SET_FAILURES = 'branch/SET_FAILURES';

export const GET_SYMPTOMS = 'branch/GET_SYMPTOMS';
export const SET_SYMPTOMS = 'branch/SET_SYMPTOMS';

export const UPDATE_WARRANTY_DETAIL = 'branch/UPDATE_WARRANTY_DETAIL';
