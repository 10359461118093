import { call, put } from 'redux-saga/effects';
import notifications from '../../../utils/notifications';
import eventManager from '../../../utils/eventManager';
import * as actionsTypes from '../store/types';
import * as calls from './calls';


export function* getWarrantyInfo(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyInfo', value: true } });

    const request = calls.getWarrantyInfo(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyInfo', value: false } });
      if (!Array.isArray(serverResponse.data)) {
        yield put({ type: actionsTypes.SET_WARRANTY_INFO, payload: serverResponse.data });
      } else {
        yield put({ type: actionsTypes.UNSET_WARRANTY_INFO });
      };
    } else {
      notifications.error('Datos no encontrados');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyInfo', value: false } });
  } catch (error) {
    notifications.error('Datos no encontrados');
  }
}

export function* getWarrantyDocs(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyDocs', value: true } });

    const request = calls.getWarrantyDocs(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyDocs', value: false } });
      yield put({ type: actionsTypes.SET_WARRANTY_DOCS, payload: serverResponse.data });
    } else {
      notifications.error('Datos no encontrados');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyDocs', value: false } });
  } catch (error) {
    console.log('Datos no encontrados');
  }
}

export function* getWarrantyEvidenceUrl(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidenceURL', value: true } });

    const request = calls.getWarrantyEvidenceUrl(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidenceURL', value: false } });
      eventManager.emit('warranty_evidence_url', serverResponse.data);
    } else {
      notifications.error('Error al traer el url');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidenceURL', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* validateRequest(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'validateRequest', value: true } });

    const request = calls.validateRequest(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'validateRequest', value: false } });
      eventManager.emit('valid_request');
    } else {
      notifications.error(serverResponse.message);
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'validateRequest', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getApplicationData(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'applicationData', value: true } });

    const request = calls.getApplicationData(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'applicationData', value: false } });
      yield put({ type: actionsTypes.SET_APPLICATION, payload: serverResponse.data });
    } else {
      notifications.error('Aplicación no encontrada');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'applicationData', value: false } });
  } catch (error) {
    console.log('Aplicación no encontrada');
  }
}
