import { call, put } from 'redux-saga/effects';
import notifications from '../../../utils/notifications';
import eventManager from '../../../utils/eventManager';
import * as actionsTypes from '../store/types';
import * as calls from './calls/index.js';
import formatter from '../../../utils/formatter';


function fixTypes(types){
  const typesToShow = types.map(element => {
    return {
      id: element.supplier_type_id,
      text: formatter.capitalize(element.name),
      value: element.supplier_type_id,
      key: element.supplier_type_id
    };
  });

  return typesToShow;
};

function fixCategories(categories){
  const categoriesToShow = categories.map(element => {
    return {
      id: element.supplier_category_id,
      text: element.name,
      value: element.supplier_category_id,
      key: element.supplier_category_id
    };
  });

  return categoriesToShow;
};

function fixSubcategories(subcategories){
  const subcategoriesToShow = subcategories.map(element => {
    return {
      id: element.supplier_subcategory_id,
      text: element.name,
      value: element.supplier_subcategory_id,
      key: element.supplier_subcategory_id
    };
  });

  return subcategoriesToShow;
};

export function* getUserDataBySession(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'userData', value: true } });

    const request = calls.getUserDataBySession(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'userData', value: false } });
      yield put({ type: actionsTypes.SET_USERDATA, payload: serverResponse.data });
      eventManager.emit('valid_token', serverResponse.data.token );
    } else {
      notifications.error('Error al traer datos de usuario');
      eventManager.emit('invalid_token');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'userData', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* saveSupplier(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savedSupplier', value: true } });

    const request = calls.saveSupplier(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savedSupplier', value: false } });
      eventManager.emit('saved_supplier', serverResponse.data);
    } else {
      notifications.error('Error al guardar proveedor');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savedSupplier', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getSuppliersTable(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'suppliersTable', value: true } });

    const request = calls.getSuppliersTable(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'suppliersTable', value: false } });
      yield put({ type: actionsTypes.SET_SUPPLIERS_TABLE, payload: serverResponse.data });
    } else {
      notifications.error('Tabla no encontrada');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'suppliersTable', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getSupplierDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'supplierDetail', value: true } });

    const request = calls.getSupplierDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'supplierDetail', value: false } });
      yield put({ type: actionsTypes.SET_SUPPLIER_DETAIL, payload: serverResponse.data });
    } else {
      notifications.error('Proveedor no encontrado');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'supplierDetail', value: false } });
  } catch (error) {
    notifications.error('Proveedor no encontrado');
  }
}

export function* getSuppliersTypes(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'types', value: true } });

    const request = calls.getSuppliersTypes(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      const fixedArray = fixTypes(serverResponse.data);
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'types', value: false } });
      yield put({ type: actionsTypes.SET_SUPPLIERS_TYPES, payload: fixedArray });
    } else {
      notifications.error('Tipos de proveedor no encontrado');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'types', value: false } });
  } catch (error) {
    notifications.error('Tipos de proveedor no encontrado');
  }
}

export function* getSuppliersCategories(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'categories', value: true } });

    const request = calls.getSuppliersCategories(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      const fixedArray = fixCategories(serverResponse.data);
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'categories', value: false } });
      yield put({ type: actionsTypes.SET_SUPPLIERS_CATEGORIES, payload: fixedArray });
    } else {
      notifications.error('Categorías de proveedor no encontrado');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'categories', value: false } });
  } catch (error) {
    notifications.error('Categorías de proveedor no encontrado');
  }
}

export function* getSuppliersSubcategories(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'subcategories', value: true } });

    const request = calls.getSuppliersSubcategories(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      const fixedArray = fixSubcategories(serverResponse.data);
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'subcategories', value: false } });
      yield put({ type: actionsTypes.SET_SUPPLIERS_SUBCATEGORIES, payload: fixedArray });
    } else {
      notifications.error('Error al traer Subcategorías');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'subcategories', value: false } });
  } catch (error) {
    notifications.error('Error al traer Subcategorías');
  }
}

export function* getSupplierAddresses(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'supplierAddresses', value: true } });

    const request = calls.getSupplierAddresses(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'supplierAddresses', value: false } });
      yield put({ type: actionsTypes.SET_SUPPLIER_ADDRESSES, payload: serverResponse.data });
    } else {
      notifications.error('Direcciones de proveedor no encontrado');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'supplierAddresses', value: false } });
  } catch (error) {
    notifications.error('Direcciones de proveedor no encontrado');
  }
}

export function* saveSupplierAddress(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'newAddress', value: true } });

    const request = calls.saveSupplierAddress(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'newAddress', value: false } });
      yield put({ type: actionsTypes.ADD_SUPPLIER_ADDRESSES, payload: serverResponse.data });
      eventManager.emit('added_address');
      notifications.success('Dirección creada con exito.');
    } else {
      notifications.error('Error al crear direccion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'newAddress', value: false } });
  } catch (error) {
    notifications.error('Error al crear direccion');
  }
}

export function* deleteSupplierAddress(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'removeAddress', value: true } });

    const request = calls.deleteSupplierAddress(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'removeAddress', value: false } });
      yield put({ type: actionsTypes.REMOVE_SUPPLIER_ADDRESSES, payload: action.payload });
      eventManager.emit('removed_address');
      notifications.success('Dirección eliminada con exito.');
    } else {
      notifications.error('Error al borrar direccion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'removeAddress', value: false } });
  } catch (error) {
    notifications.error('Error al borrar direccion');
  }
}

export function* getSepomexInfo(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'sepomexInfo', value: true } });

    const request = calls.getSepomexInfo(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'sepomexInfo', value: false } });
      yield put({ type: actionsTypes.SET_SEPOMEX_INFO, payload: { data: serverResponse.data, id: action.payload.id } });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'sepomexInfo', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* updateSupplierAddresses(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingAddresses', value: true } });

    const request = calls.updateSupplierAddresses(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingAddresses', value: false } });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingAddresses', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* getSupplierContacts(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'supplierContacts', value: true } });

    const request = calls.getSupplierContacts(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'supplierContacts', value: false } });
      yield put({ type: actionsTypes.SET_SUPPLIER_CONTACTS, payload: serverResponse.data });
    } else {
      notifications.error('Contactos de proveedor no encontrados');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'supplierContacts', value: false } });
  } catch (error) {
    notifications.error('Contactos de proveedor no encontrados');
  }
}

export function* saveSupplierContact(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'newContact', value: true } });

    const request = calls.saveSupplierContact(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'newContact', value: false } });
      yield put({ type: actionsTypes.ADD_SUPPLIER_CONTACTS, payload: serverResponse.data });
      eventManager.emit('added_contact');
      notifications.success('Contacto creado con exito.');
    } else {
      notifications.error('Error al crear contacto');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'newContact', value: false } });
  } catch (error) {
    notifications.error('Error al crear contacto');
  }
}

export function* deleteSupplierContact(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'removeContact', value: true } });

    const request = calls.deleteSupplierContact(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'removeContact', value: false } });
      yield put({ type: actionsTypes.REMOVE_SUPPLIER_CONTACTS, payload: action.payload });
      eventManager.emit('removed_contact');
      notifications.success('Contacto eliminado con exito.');
    } else {
      notifications.error('Error al borrar contacto');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'removeContact', value: false } });
  } catch (error) {
    notifications.error('Error al borrar contacto');
  }
}

export function* updateSupplierContacts(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingContacts', value: true } });

    const request = calls.updateSupplierContacts(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingContacts', value: false } });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingContacts', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* getContactTypes(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'contactTypes', value: true } });

    const request = calls.getContactTypes(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'contactTypes', value: false } });
      yield put({ type: actionsTypes.SET_CONTACT_TYPES, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'contactTypes', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* saveContactType(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingContactType', value: true } });

    const request = calls.saveContactType(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingContactType', value: false } });
      eventManager.emit('saved_contact_type');
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingContactType', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* saveSupplierType(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingSupplierType', value: true } });

    const request = calls.saveSupplierType(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingSupplierType', value: false } });
      eventManager.emit('saved_supplier_type');
    } else {
      notifications.error('Error al crear Tipo');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingSupplierType', value: false } });
  } catch (error) {
    notifications.error('Error al crear Tipo');
  }
}

export function* getAllSuppliersCategories(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'allCategories', value: true } });

    const request = calls.getAllSuppliersCategories(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'allCategories', value: false } });
      yield put({ type: actionsTypes.SET_ALL_SUPPLIERS_CATEGORIES, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'allCategories', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* saveSupplierCategory(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingSupplierCategory', value: true } });

    const request = calls.saveSupplierCategory(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingSupplierCategory', value: false } });
      yield put({ type: actionsTypes.ADD_SUPPLIERS_CATEGORIES, payload: serverResponse.data });
      eventManager.emit('saved_supplier_category', serverResponse.data);
    } else {
      notifications.error('Error al guardar Categoria');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingSupplierCategory', value: false } });
  } catch (error) {
    notifications.error('Error al guardar Categoria');
  }
}

export function* getSupplierCategoryDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'categoryDetail', value: true } });

    const request = calls.getSupplierCategoryDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'categoryDetail', value: false } });
      yield put({ type: actionsTypes.SET_SUPPLIERS_CATEGORY_DETAIL, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'categoryDetail', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* getAllSuppliersSubCategories(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'allSubCategories', value: true } });

    const request = calls.getAllSuppliersSubCategories(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'allSubCategories', value: false } });
      yield put({ type: actionsTypes.SET_ALL_SUPPLIERS_SUBCATEGORIES, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'allSubCategories', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* saveSupplierSubCategory(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingSupplierSubCategory', value: true } });

    const request = calls.saveSupplierSubCategory(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingSupplierSubCategory', value: false } });
      yield put({ type: actionsTypes.ADD_SUPPLIERS_SUBCATEGORIES, payload: serverResponse.data });
      eventManager.emit('saved_supplier_subcategory', serverResponse.data);
    } else {
      notifications.error('Error al guardar Sub Categoria');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingSupplierSubCategory', value: false } });
  } catch (error) {
    notifications.error('Error al guardar Sub Categoria');
  }
}

export function* getSuppliersTypeDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'typeDetail', value: true } });

    const request = calls.getSuppliersTypeDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'typeDetail', value: false } });
      yield put({ type: actionsTypes.SET_SUPPLIERS_TYPES_DETAIL, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'typeDetail', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* deleteSupplierType(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingSupplierType', value: true } });

    const request = calls.deleteSupplierType(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingSupplierType', value: false } });
      eventManager.emit('deleted_supplier_type');
    } else {
      notifications.error('Error al borrar Tipo');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingSupplierType', value: false } });
  } catch (error) {
    notifications.error('Error al borrar Tipo');
  }
}

export function* deleteSupplierCategory(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingSupplierCategory', value: true } });

    const request = calls.deleteSupplierCategory(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingSupplierCategory', value: false } });
      eventManager.emit('deleted_supplier_category');
    } else {
      notifications.error('Error al borrar Categoria');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingSupplierCategory', value: false } });
  } catch (error) {
    notifications.error('Error al borrar Categoria');
  }
}

export function* deleteSupplierSubCategory(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingSupplierSubCategory', value: true } });

    const request = calls.deleteSupplierSubCategory(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingSupplierSubCategory', value: false } });
      eventManager.emit('deleted_supplier_subcategory');
    } else {
      notifications.error('Error al borrar Categoria');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingSupplierSubCategory', value: false } });
  } catch (error) {
    notifications.error('Error al borrar Categoria');
  }
}

export function* getAditionalFormData(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'aditionalFormData', value: true } });

    const request = calls.getAditionalFormData(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'aditionalFormData', value: false } });
      yield put({ type: actionsTypes.SET_ADITIONAL_FORM, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'aditionalFormData', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* getInvetory(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'inventory', value: true } });

    const request = calls.getInvetory(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'inventory', value: false } });
      yield put({ type: actionsTypes.SET_INVENTORY, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'inventory', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* getBrands(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'brands', value: true } });

    const request = calls.getBrands(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'brands', value: false } });
      yield put({ type: actionsTypes.SET_BRANDS, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'brands', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* getModels(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'models', value: true } });

    const request = calls.getModels(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'models', value: false } });
      yield put({ type: actionsTypes.SET_MODELS, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'models', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}


export function* getPhoneDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phoneDetail', value: true } });

    const request = calls.getPhoneDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phoneDetail', value: false } });
      yield put({ type: actionsTypes.SET_PHONE_DETAIL, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phoneDetail', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* savePhone(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savedPhone', value: true } });

    const request = calls.savePhone(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savedPhone', value: false } });
      eventManager.emit('saved_phone', serverResponse.data);
    } else {
      notifications.error('Error al guardar teléfono');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savedPhone', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* deletePhoneImage(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingPhoneImage', value: true } });

    const request = calls.deletePhoneImage(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingPhoneImage', value: false } });
      eventManager.emit('deleted_phone_image');
    } else {
      notifications.error('Error al borrar imagen');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingPhoneImage', value: false } });
  } catch (error) {
    notifications.error('Error al borrar imagen');
  }
}

export function* saveBrand(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savedBrand', value: true } });

    const request = calls.saveBrand(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savedBrand', value: false } });
      eventManager.emit('saved_brand', serverResponse.data);
    } else {
      notifications.error('Error al guardar marca');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savedBrand', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getPhonesStorage(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'storages', value: true } });

    const request = calls.getPhonesStorage(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'storages', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_STORAGES, payload: serverResponse.data });
    } else {
      notifications.error('Modelos no encontrados');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'storages', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getPhonesFrontalCamera(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'frontal_cameras', value: true } });

    const request = calls.getPhonesFrontalCamera(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'frontal_cameras', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_FRONTAL_CAMERAS, payload: serverResponse.data });
    } else {
      notifications.error('cameras no encontrados');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'frontal_cameras', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getPhonesRearCamera(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'rear_cameras', value: true } });

    const request = calls.getPhonesRearCamera(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'rear_cameras', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_REAR_CAMERAS, payload: serverResponse.data });
    } else {
      notifications.error('cameras no encontrados');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'rear_cameras', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getPhonesSIM(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'SIMs', value: true } });

    const request = calls.getPhonesSIM(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'SIMs', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_SIMS, payload: serverResponse.data });
    } else {
      notifications.error('SIMs no encontrados');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'SIMs', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getPhonesOS(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'OSs', value: true } });

    const request = calls.getPhonesOS(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'OSs', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_OS, payload: serverResponse.data });
    } else {
      notifications.error('Modelos no encontrados');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'OSs', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getPhonesScreen(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'screens', value: true } });

    const request = calls.getPhonesScreen(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'screens', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_SCREENS, payload: serverResponse.data });
    } else {
      notifications.error('screens no encontrados');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'screens', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getPhonesService(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'services', value: true } });

    const request = calls.getPhonesService(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'services', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_SERVICES, payload: serverResponse.data });
    } else {
      notifications.error('services no encontrados');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'services', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

// =====================================================

export function* getPayrollApplications(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'payrollApplications', value: true } });

    const request = calls.getPayrollApplications(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'payrollApplications', value: false } });
      yield put({ type: actionsTypes.SET_PAYROLL_APPLICATIONS, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'payrollApplications', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* updatePayrollApplication(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingPayrollApplication', value: true } });

    const request = calls.updatePayrollApplication(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingPayrollApplication', value: false } });
      eventManager.emit('updated-caprepa-application', serverResponse.data);
    } else {
      notifications.error('Error al guardar marca');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingPayrollApplication', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getPayrollPayments(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'payrollPayments', value: true } });

    const request = calls.getPayrollPayments(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'payrollPayments', value: false } });
      yield put({ type: actionsTypes.SET_PAYROLL_PAYMENTS, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'payrollPayments', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* updatePayrollPayments(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingCapitalApplications', value: true } });

    const request = calls.updatePayrollPayments(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingCapitalApplications', value: false } });
      eventManager.emit('updated-payroll-payments', serverResponse.data);
    } else {
      notifications.error('Error al guardar Pagos');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingCapitalApplications', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* savePayrollPaymentsByFile(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingCapitalApplications', value: true } });

    const request = calls.savePayrollPaymentsByFile(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingCapitalApplications', value: false } });
      eventManager.emit('updated-payroll-payments-file', serverResponse.data);
    } else {
      notifications.error('Error al guardar Pagos');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingCapitalApplications', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

// =====================================================

export function* getCapitalApplications(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'capitalApplications', value: true } });

    const request = calls.getCapitalApplications(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'capitalApplications', value: false } });
      yield put({ type: actionsTypes.SET_CAPITAL_APPLICATIONS, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'capitalApplications', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* updateCapitalApplication(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingPayrollApplication', value: true } });

    const request = calls.updateCapitalApplication(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingPayrollApplication', value: false } });
      eventManager.emit('updated-caprepa-application', serverResponse.data);
    } else {
      notifications.error('Error al guardar marca');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingPayrollApplication', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

// =====================================================

export function* getCaprepaApplications(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'caprepaApplications', value: true } });

    const request = calls.getCaprepaApplications(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'caprepaApplications', value: false } });
      yield put({ type: actionsTypes.SET_CAPREPA_APPLICATIONS, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'caprepaApplications', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* updateCaprepaApplication(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingCaprepaApplication', value: true } });

    const request = calls.updateCaprepaApplication(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingCaprepaApplication', value: false } });
      eventManager.emit('updated-caprepa-application', serverResponse.data);
    } else {
      notifications.error(serverResponse.message);
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingCaprepaApplication', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getUsersDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'usersDetail', value: true } });

    const request = calls.getUsersDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'usersDetail', value: false } });
      yield put({ type: actionsTypes.SET_USER_DETAIL, payload: serverResponse.data });
    } else {
      notifications.error('usuario no encontrado');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'usersDetail', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getEmailsUsersDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'emailUsersDetail', value: true } });

    const request = calls.getEmailsUsersDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'emailUsersDetail', value: false } });
      yield put({ type: actionsTypes.SET_EMAIL_USER_DETAIL, payload: serverResponse.data });
    } else {
      notifications.error('usuario no encontrado');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'emailUsersDetail', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* saveTiers(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingTier', value: true } });

    const request = calls.saveTiers(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingTier', value: false } });
      eventManager.emit('saved-tier');
    } else {
      notifications.error('No se pudo procesar la gama correctamente');
      eventManager.emit('error-tier');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingTier', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getBrandsList(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'brandsList', value: true } });

    const request = calls.getBrandsList(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'brandsList', value: false } });
      yield put({ type: actionsTypes.SET_BRANDS_LIST, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'brandsList', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* saveUser(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveUser', value: true } });

    const request = calls.saveUser(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveUser', value: true } });
      eventManager.emit('saved-user');
    };
    if(serverResponse.message.includes("UC_username"))
    {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveUser', value: false } });
      eventManager.emit('exist-user');
    }
  } catch (error) {
    eventManager.emit('error-user');
  }
}

export function* saveEmailUser(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveEmailUser', value: true } });

    const request = calls.saveEmailUser(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveEmailUser', value: true } });
      eventManager.emit('saved-email-user');
    };
    if(serverResponse.message.includes("UC_username"))
    {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveEmailUser', value: false } });
      eventManager.emit('exist-email-user');
    }
  } catch (error) {
    eventManager.emit('error-user');
  }
}

export function* getBranchesList(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchesList', value: true } });

    const request = calls.getBranchesList(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchesList', value: false } });
      yield put({ type: actionsTypes.SET_BRANCHES_LIST, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchesList', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}
export function* getBranchDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchDetail', value: false } });

    const request = calls.getBranchDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchDetail', value: true } });
      yield put({ type: actionsTypes.SET_BRANCH_DETAIL, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchDetail', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* saveBranch(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveBranch', value: true } });

    const request = calls.saveBranch(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveBranch', value: false } });
      eventManager.emit('saved-branch');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveBranch', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}



export function* getRolesList(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'rolesList', value: true } });

    const request = calls.getRolesList(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'rolesList', value: false } });
      yield put({ type: actionsTypes.SET_ROLES_LIST, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'rolesList', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getRoleDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'roleDetail', value: true } });

    const request = calls.getRoleDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'roleDetail', value: false } });
      yield put({ type: actionsTypes.SET_ROLE_DETAIL, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'roleDetail', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}


export function* getAllWarrantyStatus(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'allWarrantyStatus', value: true } });

    const request = calls.getAllWarrantyStatus(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'allWarrantyStatus', value: false } });
      yield put({ type: actionsTypes.SET_ALL_WARRANTY_STATUS, payload: serverResponse.data });
    } else {
      notifications.error('Status no encontrados');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'allWarrantyStatus', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}


export function* saveRole(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveRole', value: true } });

    const request = calls.saveRole(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveRole', value: false } });
      eventManager.emit('saved-role');
    };
    if(serverResponse.message.includes("Rol ya existente")){
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveRole', value: false } });
      eventManager.emit('exist-role');
    }
  } catch (error) {
    eventManager.emit('error-role');
  }
}



export function* getRegionsList(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'regionList', value: true } });

    const request = calls.getRegionsList(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'regionList', value: false } });
      yield put({ type: actionsTypes.SET_REGION_LIST, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'regionList', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getFinancingsData(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingsData', value: true } });

    const request = calls.getFinancingsData(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingsData', value: false } });
      yield put({ type: actionsTypes.SET_FINANCINGS_DATA, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingsData', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* saveInventoryByFile(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingInventoryByFile', value: true } });

    const request = calls.saveInventoryByFile(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingInventoryByFile', value: false } });
      eventManager.emit('updated-inventory', serverResponse.data);
    } else {
      notifications.error('Error al subir el archivo');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingInventoryByFile', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getFinishedApplications(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'finishedApplications', value: true } });

    const request = calls.getFinishedApplications(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'finishedApplications', value: false } });
      yield put({ type: actionsTypes.SET_FINISHED_APPLICATIONS, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer informacion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'finishedApplications', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

export function* updateContractStatus(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateContractStatus', value: true } });

    const request = calls.updateContractStatus(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateContractStatus', value: false } });
      eventManager.emit('updated-contract', serverResponse.data);
    } else {
      notifications.error('Error al actualizar el status del contrato');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateContractStatus', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getColabApplications(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'colabApplications', value: true } });

    const request = calls.getColabApplications(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'colabApplications', value: false } });
      yield put({ type: actionsTypes.SET_COLAB_APPLICATIONS, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer solicitudes');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'colabApplications', value: false } });

  } catch (error) {
    console.log(error.message);
  }
}

export function* getTiers(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'tiers', value: true } });

    const request = calls.getTiers(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'tiers', value: false } });
      yield put({ type: actionsTypes.SET_TIERS, payload: serverResponse.data });
    } else {
      notifications.error('Error al traer solicitudes');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'tiers', value: false } });

  } catch (error) {
    console.log(error.message);
  }
}
