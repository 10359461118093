import { call, put } from 'redux-saga/effects';
import * as calls from '../calls/index.js';
import * as actionsTypes from '../../store/types';
import notifications from '../../../../utils/notifications';
import eventManager from '../../../../utils/eventManager';


export function* getCustomerData(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'customerData', value: true } });

    const request = calls.getCustomerData(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'customerData', value: false } });
      yield put({ type: actionsTypes.SET_CUSTOMER_DATA, payload: serverResponse.data });
    } else {
      notifications.error(serverResponse.message);
    };
    eventManager.emit('get-Customer');
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'customerData', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getCustomerFinancingData(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'customerFinancingData', value: true } });

    const request = calls.getCustomerFinancingData(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'customerFinancingData', value: false } });
      yield put({ type: actionsTypes.SET_CUSTOMER_FINANCING_DATA, payload: serverResponse.data });
    } else {
      notifications.error(serverResponse.message);
    };
    eventManager.emit('get-Customer');
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'customerFinancingData', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* saveCustomerData(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingCustomerData', value: true } });

    const request = calls.saveCustomerData(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingCustomerData', value: false } });
      yield put({ type: actionsTypes.SET_CUSTOMER_DATA, payload: serverResponse.data });
      eventManager.emit('saved-customerdata', serverResponse.data);
    } else {
      notifications.error(serverResponse.message);
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingCustomerData', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* saveApplicationForm(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingApplication', value: true } });

    const request = calls.saveApplicationForm(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingApplication', value: false } });
      eventManager.emit('saved_application', serverResponse.data);
      notifications.success('Aplicación creada exitosamente')
    } else {
      notifications.error('Error al guardar aplicación');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingApplication', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getBioData(action) {
  try {
    //yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveBioData', value: true } });

    const request = calls.getBioData(action.payload);
    const response = yield call(request);

    const serverResponse = response.data;

    if (serverResponse.isValid) {
      //yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveBioData', value: true } });
      yield put({ type: actionsTypes.SET_BIO_DATA, payload: serverResponse.data });
      eventManager.emit('getBioData', serverResponse.data);
    } else {
      eventManager.emit('getBioDataError', serverResponse.data);
      notifications.error('error en procesado de informacion');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveBioData', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* saveBioData(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveBioData', value: true } });

    const request = calls.saveBioData(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveBioData', value: true } });
      yield put({ type: actionsTypes.SET_BIO_DATA, payload: serverResponse.data });
      eventManager.emit('saveBioData', serverResponse.data);
    } else {
      eventManager.emit('saveBioDataError', serverResponse.data);
      notifications.error('error en procesado de informacion');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveBioData', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getCustomerScore(action){
  try {

    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'customerScore', value: true } });

    const request = calls.getCustomerScore(action.payload);
    const response = yield call(request);

    const serverResponse = response.data;
    if (serverResponse.isValid) {

      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'customerScore', value: true } });
      yield put({ type: actionsTypes.SET_CUSTOMER_SCORE, payload: serverResponse.data });
      eventManager.emit('get-customerScore', serverResponse.data);
    };
  } catch (error) {
    console.log(error.message);
  };
};

export function* saveCustomerScore(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveCustomerScore', value: true } });

    const request = calls.saveCustomerScore(action.payload);
    const response = yield call(request);

    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveCustomerScore', value: true } });
      yield put({ type: actionsTypes.SET_CUSTOMER_SCORE_DATA, payload: serverResponse.data });
      eventManager.emit('saveCustomerScore', serverResponse.data);
    } else {
      eventManager.emit('saveCustomerScoreError', serverResponse.data);
      notifications.error('error en procesado de informacion');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveCustomerScore', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};
