import axios from 'axios';
import { API_URL } from '../../../../utils/constants';


export function getSupplierAddresses(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/addresses/by-supplier`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function saveSupplierAddress(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/addresses/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function deleteSupplierAddress(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/addresses/delete`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function updateSupplierAddresses(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/addresses/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getSepomexInfo(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/addresses/by-postalCode`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

