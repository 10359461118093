import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { RED, WHITE } from '../colors';
import formatter from '../utils/formatter';
import { Modal, Divider } from 'semantic-ui-react';

const styles = {
  modal: {
    zIndex: -100,
    width: '100%',
    padding: 0,
    position: 'fixed',
    background: WHITE,
    transition: 'all 250ms ease-in',
    opacity: 0,
    transform: 'translateY(-20%)'
  },
  headerContainer: {
    border: '2px solid #b5b5b536',
    left: 0,
    top: 0,
    position: 'sticky',
    width: '100%',
    zIndex: 100,
    background: WHITE
  },
  header: {
    fontFamily: 'bebas-neue',
    fontSize: 28,
    textAlign: 'center',
    marginTop: 16,
    marginBottom: 13,
    width: '100%'
  },
  closeButton: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    textAlign: 'center',
    fontSize: 25,
    marginTop: 16,
    marginRight: 15,
    background: '#b5b5b536',
    position: 'absolute',
    top: 0,
    right: 10
  },
  rightItems: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end"
  }
};


class AnimatedModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      contentVisible: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !isEqual(nextState, this.state) || !isEqual(clean(nextProps), clean(this.props));
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.toogleModal();
    }

    if (!this.props.open && prevProps.open) {
      this.toogleModal();
    }
  }

  toogleModal() {
    const { modalVisible } = this.state;

    if (!modalVisible) {
      this.setState({ modalVisible: true }, () => {
        setTimeout(() => {
          this.setState({ contentVisible: true });
        }, 100);
      });
    } else {
      this.setState({ contentVisible: false }, () => {
        setTimeout(() => {
          this.setState({ modalVisible: false });
        }, 300);
      });
    }
  }

  renderTitle() {
    const { title } = this.props;

    if (title.length) {
      const firstString = title[0];
      const strings = title.slice(1, title.length);

      return (
        <div>
          <span style={{color: RED}}>{firstString}</span>{strings}
        </div>
      );
    }
  }

  renderClose() {
    return (
      <div
        style={{
          zIndex: 1000,
          position: "absolute",
          top: 10,
          right: 10,
          background: "rgb(230,230,230)",
          borderRadius: 20,
          cursor: "pointer",
          padding: 15
        }}
        onClick={this.props.onClose}
      >
        <span
          style={{ position: "absolute", top: 2, left: 9, fontSize: 20, fontWeight: 900 }}
        >
          x
        </span>
      </div>
    );
  }

  renderHeader() {
    const { title, mobile, onClose } = this.props;

    if (!title) {
      return this.renderClose();
    }

    return (
      <React.Fragment>
        <Modal.Header style={{ padding: 0, borderBottom: 'none', maxHeight: mobile ? 35 : 25 }}>
          <div style={{ ...styles.header, marginTop: mobile ? 5 : 16 }}>
            { this.renderTitle() }
          </div>

          <div width={title ? 2 : 16} style={{ paddingRight: mobile ? 0 : null }}>
            <div style={{ ...styles.headerContainer, ...styles.rightItems, border: 0, paddingLeft: 15, paddingBottom: mobile ? 0 : 15, position: 'unset' }}>
              <div
                style={{ zIndex: 20, position: 'absolute', display: 'inline-block', background: 'rgb(230,230,230)', borderRadius: 20, cursor: 'pointer', padding: 15, top: mobile ? 5 : 7, right: mobile ? 6 : 8 }}
                onClick={onClose}
              >
                <span style={{ fontSize: 18, position: 'absolute', top: 0, left: 10 }}>
                  x
                </span>
              </div>
            </div>
          </div>
        </Modal.Header>

        <Divider style={{ marginTop: 15 }} />
      </React.Fragment>
    );
  }

  render() {
    const { modalVisible, contentVisible } = this.state;
    const { children, onClose, style, mobile, className, size, title } = this.props;
    const modalStyle = { ...styles.modal };
    let marginTop;

    if (mobile) {
      modalStyle.overflowY = 'auto';
      modalStyle.overflowX = 'hidden';
    }

    if (contentVisible) {
      modalStyle.transform = 'translateY(0%)';
      modalStyle.opacity = 1;
      modalStyle.zIndex = 1000000;
    }

    if (!title) {
      marginTop = mobile ? 5 : 15;
    }

    return (
      <Modal
        style={{...modalStyle, ...style}}
        onClose={onClose}
        open={modalVisible}
        className={className}
        size={size}
      >
        { this.renderHeader() }

        <Modal.Content style={{ paddingTop: 0, marginTop }}>
          { modalVisible ? children : null }
        </Modal.Content>
      </Modal>
    );
  }
}


AnimatedModal.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.any,
  onClose: PropTypes.any,
  title: PropTypes.any,
  style: PropTypes.any,
  mobile: PropTypes.bool,
  className: PropTypes.any,
  size: PropTypes.any
};


export { AnimatedModal };
