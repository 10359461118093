import * as actionsTypes from './types';


const INITIAL_STATE = {
  selectedView: '',
  userData: null,
  branchData: null,
  invoiceTable: [],
  invoiceTableByStatus: [],
  allWarrantyStatus: null,
  warrantyDetail: null,
  warrantyEvidence: null,
  warrantyLogs: [],
  recoveryLogs: [],
  branchwarrantyLogs: [],
  branchwarrantyEvidence: null,
  branchwarrantyDetail: null,
  rolesList: [],
  usersList: [],
  branchesList: [],
  inventoryList: [],
  userDetail: null,
  roleDetail: null,
  branchDetail: null,
  dropdownWarrantyStatus: null,
  failures: null,
  symptoms: null,
  accesories: null,
  branches: null,
  phoneData: null,
  financingData: null,
  dashboardStats: null,
  aditionalFormData: [],
  gestionDetail: null,
  recoveriesPhonesData: null,
  branchWarrantyData: null,
  brands: null,
  phonesListByBrand: null,
  recoveryphoneByImei: null,
  recoveryEvidence: null,
  colabApplications: [],
  sepomexInfo: {},
  phonesByBrand: [],
  selectedFieldsMaster: [],
  selectedPageMaster: 1,
  loadings: {
    userData: false,
    branchData: false,
    invoiceTable: false,
    invoiceTableByStatus: false,
    allWarrantyStatus: false,
    warrantyDetail: false,
    dropdownWarrantyStatus: false,
    updateWarrantiesSatus: false,
    updateRecoveryStatus: false,
    modRecoveryStatus: false,
    failures: false,
    symptoms: false,
    accesories: false,
    branches: false,
    warrantyEvidence: false,
    recoveryEvidence: false,
    warrantyEvidenceUpload: false,
    warrantyEvidenceURL: false,
    recoveryEvidenceURL: false,
    recoveryEvidenceUpload: false,
    recoveryLogs: false,
    warrantyLogs: false,
    branchwarrantyLogs: false,
    branchwarrantyEvidence:false,
    branchwarrantyDetail:false,
    branchwarrantyUpload:false,
    branchwarrantyQuote:false,
    branchWarrantyStatusUpdate:false,
    branchWarrantyStatusMultipleUpdate:false,
    rolesList: false,
    usersList: false,
    branchesList: false,
    warrantyQuote: false,
    RecoveryQuote: false,
    updateAllWarantyDetail: false,
    phoneData: false,
    saveInvoiceSwap: false,
    financingData: false,
    dashboardStats: false,
    saveUser: false,
    updateUser: false,
    userDetail: false,
    saveRole: false,
    roleDetail: false,
    branchDetail: false,
    saveWarrantyDiagnosis: false,
    inventoryList: false,
    aditionalFormData: false,
    gestionDetail: false,
    savingGestionLog: false,
    colabApplications: false,
    updatingCaprepaApplication: false,
    sepomexInfo: false,
    phonesByBrand: false,
    updateRecoveriesStatus: false,
  }
};


const setLoading = (state, action) => {
  const { prop, value } = action.payload;
  const loadings = { ...state.loadings };

  loadings[prop] = value;
  return { ...state, loadings };
};

const setSepomexInfo = (state, action) => {
  const { id, data } = action.payload;
  const copy = { ...state.sepomexInfo };
  copy[id] = data;
  return { ...state, sepomexInfo: copy };
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionsTypes.SET_LOADING:
    return setLoading(state, action);
  case actionsTypes.SET_SELECTED_VIEW:
    return { ...state, selectedView: action.payload };
  case actionsTypes.SET_USERDATA:
    return { ...state, userData: action.payload };
  case actionsTypes.UNSET_USERDATA:
    return { ...state, userData: null };
  case actionsTypes.SET_BRANCH_DATA:
    return { ...state, branchData: action.payload };
  case actionsTypes.SET_INVOICE_TABLE:
    return { ...state, invoiceTable: action.payload };
  case actionsTypes.UNSET_INVOICE_TABLE:
    return { ...state, invoiceTable: [] };
  case actionsTypes.SET_INVOICE_TABLE_BY_STATUS:
    return { ...state, invoiceTableByStatus: action.payload };
  case actionsTypes.UNSET_INVOICE_TABLE_BY_STATUS:
    return { ...state, invoiceTableByStatus: [] };
  case actionsTypes.SET_ALL_WARRANTY_STATUS:
    return { ...state, allWarrantyStatus: action.payload };
  case actionsTypes.SET_WARRANTY_DETAIL:
    return { ...state, warrantyDetail: action.payload };
  case actionsTypes.UNSET_WARRANTY_DETAIL:
    return { ...state, warrantyDetail: null };
  case actionsTypes.SET_DROPDOWN_WARRANTY_STATUS:
    return { ...state, dropdownWarrantyStatus: action.payload };
  case actionsTypes.SET_FAILURES:
    return { ...state, failures: action.payload };
  case actionsTypes.SET_SYMPTOMS:
    return { ...state, symptoms: action.payload };
  case actionsTypes.SET_ACCESORIES:
    return { ...state, accesories: action.payload };
  case actionsTypes.SET_BRANCHES:
    return { ...state, branches: action.payload };
  case actionsTypes.SET_WARRANTY_EVIDENCE:
    return { ...state, warrantyEvidence: action.payload };
  case actionsTypes.SET_WARRANTY_LOGS:
    return { ...state, warrantyLogs: action.payload };
  case actionsTypes.SET_PHONE_DATA:
    return { ...state, phoneData: action.payload };
  case actionsTypes.UNSET_PHONE_DATA:
    return { ...state, phoneData: null };
  case actionsTypes.SET_ROLES_LIST:
    return { ...state, rolesList: action.payload };
  case actionsTypes.UNSET_ROLES_LIST:
    return { ...state, rolesList: [] };
  case actionsTypes.SET_USERS_LIST:
    return { ...state, usersList: action.payload };
  case actionsTypes.UNSET_USERS_LIST:
    return { ...state, usersList: [] };
  case actionsTypes.SET_USER_DETAIL:
    return { ...state, userDetail: action.payload };
  case actionsTypes.UNSET_USER_DETAIL:
    return { ...state, userDetail: null };
  case actionsTypes.SET_ROLE_DETAIL:
    return { ...state, roleDetail: action.payload };
  case actionsTypes.UNSET_ROLE_DETAIL:
    return { ...state, roleDetail: null };
  case actionsTypes.SET_FINANCING_DATA:
    return { ...state, financingData: action.payload };
  case actionsTypes.UNSET_FINANCING_DATA:
    return { ...state, financingData: null };
  case actionsTypes.SET_DASHBOARD_STATS:
    return { ...state, dashboardStats: action.payload };

  case actionsTypes.SET_BRANCHES_LIST:
    return { ...state, branchesList: action.payload };
  case actionsTypes.UNSET_BRANCHES_LIST:
    return { ...state, branchesList: [] };

  case actionsTypes.SET_BRANCH_DETAIL:
    return { ...state, branchDetail: action.payload };
  case actionsTypes.UNSET_BRANCH_DETAIL:
    return { ...state, branchDetail: null };

  case actionsTypes.SET_INVENTORY_LIST:
    return { ...state, inventoryList: action.payload };
  case actionsTypes.UNSET_INVENTORY_LIST:
    return { ...state, inventoryList: [] };

  case actionsTypes.SET_ADITIONAL_FORM:
    return { ...state, aditionalFormData: action.payload };
  case actionsTypes.UNSET_ADITIONAL_FORM:
    return { ...state, aditionalFormData: null };

  case actionsTypes.SET_GESTION_DETAIL:
    return { ...state, gestionDetail: action.payload };
  case actionsTypes.UNSET_GESTION_DETAIL:
    return { ...state, gestionDetail: null };
  case actionsTypes.SET_ALL_RECOVERIES_PHONES:
    return { ...state, recoveriesPhonesData: action.payload };
  case actionsTypes.SET_PHONES_BRANDS:
    return { ...state, brands: action.payload };
  case actionsTypes.SET_PHONE_NAMES_BY_BRAND:
    return { ...state, phonesListByBrand: action.payload }
  case actionsTypes.SET_RECOVERY_PHONE_BY_ID:
    return { ...state, recoveryphoneById: action.payload };
  case actionsTypes.SET_RECOVERY_EVIDENCE:
    return { ...state, recoveryEvidence: action.payload };
  case actionsTypes.SET_RECOVERY_LOGS:
    return { ...state, recoveryLogs: action.payload };
  case actionsTypes.SET_ALL_BRANCH_WARRANTIES:
    return { ...state, branchWarrantyData: action.payload };

  case actionsTypes.SET_COLAB_APPLICATIONS:
    return { ...state, colabApplications: action.payload };
  case actionsTypes.SET_PHONES_BY_BRAND:
    return { ...state, phonesByBrand: action.payload };

  case actionsTypes.SET_SEPOMEX_INFO:
    return setSepomexInfo(state, action);
  case actionsTypes.SET_BRANCH_WARRANTY_DETAIL_BY_ID:
    return { ...state, branchwarrantyDetail: action.payload };
  case actionsTypes.SET_BRANCH_WARRANTY_LOGS_BY_ID:
    return { ...state, branchwarrantyLogs: action.payload };
  case actionsTypes.SET_BRANCH_WARRANTY_DOCUMENTS_EVIDENCE_BY_ID:
    return { ...state, branchwarrantyEvidence: action.payload };
  case actionsTypes.SET_SELECTED_FIELDS_MASTER:
    return { ...state, selectedFieldsMaster: action.payload };
  case actionsTypes.SET_SELECTED_PAGE_MASTER:
    return { ...state, selectedPageMaster: action.payload };
  default:
    return state;
  }
};
