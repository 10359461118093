import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { isEqual } from 'lodash';

const svgContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
};

class ColoredSVG extends Component {
  constructor(props) {
    super(props);

    this.state = {
      svgContent: ''
    };
  }

  componentDidMount() {
    const { svg } = this.props;
    this.getImage(svg);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.svg, this.props.svg)) {
      this.getImage(this.props.svg);
    }
  }

  getImage(newSvg) {
    axios.get(newSvg)
      .then(response => {
        this.setState({ svgContent: response.data });
      })
      .catch(error => {
        console.error('Error fetching SVG:', error);
      });
  }

  getDirection() {
    const { gradient } = this.props;

    let value = '';

    switch (gradient.direction) {
    case 'to bottom':
      value = `
      <linearGradient
        id="gradient-custom"
        x1="0%"
        y1="100%"
        x2="0%"
        y2="0%"
      >`;
      break;
    case 'to right':
      value = `
        <linearGradient
          id="gradient-custom"
          x1="100%"
          y1="0%"
          x2="0%"
          y2="0%"
        >`;
      break;
    case 'to corner right':
      value = `
      <linearGradient
        id="gradient-custom"
        x1="0%"
        y1="0%"
        x2="100%"
        y2="100%"
      >`;
      break;
    case 'to corner left':
      value = `
        <linearGradient
          id="gradient-custom"
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
        >`;
      break;
    default:
      value = `
      <linearGradient
        id="gradient-custom"
        x1="0%"
        y1="100%"
        x2="0%"
        y2="0%"
      >`;
      break;
    }

    return value;
  };

  render() {
    const { color, width, height, gradient } = this.props;
    const { svgContent } = this.state;

    let svg = svgContent;

    if (width && height) {
      svg = svg.replace(/width="[^"]*"/g, `width="${width}"`);
      svg = svg.replace(/height="[^"]*"/g, `height="${height}"`);
    }

    let modifiedSvg = svg;

    if (color) {
      modifiedSvg = svg.replace(/fill="[^"]*"/g, `fill="${color}"`);
    } else if (gradient) {

      const direction = this.getDirection()

      const gradientBody = `
        <defs>
            ${direction}
            <stop offset="0%" stop-color="${gradient.colorEnd}" />
            <stop offset="100%" stop-color="${gradient.colorStart}" />
          </linearGradient>
        </defs>
      `;


      const svg_base = `{*SVG_HEAD*} {*GRADIENT*} {*PATH*} </svg>`;

      const svg_head = svg.match(/<svg[^>]*>[\s\S]*?>/);
      const path = svg.match(/<path.*?\/>/);


      modifiedSvg = svg_base.replace('{*SVG_HEAD*}', `${svg_head}`);
      modifiedSvg = modifiedSvg.replace('{*GRADIENT*}', `${gradientBody}`);
      modifiedSvg = modifiedSvg.replace('{*PATH*}', `${path}`);

      modifiedSvg = modifiedSvg.replace(/fill="[^"]*"/g, `fill="url(#gradient-custom)"`);
    }

    return (
      <div style={svgContainerStyle} dangerouslySetInnerHTML={{ __html: modifiedSvg }} />
    );
  }
}

ColoredSVG.propTypes = {
  svg: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  gradient: PropTypes.object
};

export { ColoredSVG };
