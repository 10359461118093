import * as actionsTypes from './types';


const INITIAL_STATE = {
  selectedView: '',
  userData: null,
  suppliersTable: [],
  supplierDetail: null,
  types: [],
  categories: [],
  allCategories: [],
  typeDetail: null,
  categoryDetail: null,
  subcategories: [],
  allSubCategories: [],
  supplierAddresses: [],
  supplierContacts: [],
  sepomexInfo: {},
  contactTypes: [],
  aditionalFormData: [],
  inventory: [],
  brands: [],
  models: [],
  storages: [],
  frontal_cameras: [],
  rear_cameras: [],
  SIMs: [],
  OSs: [],
  screens: [],
  services: [],
  phoneDetail: null,
  usersDetail: [],
  branchesList: [],
  rolesList: [],
  allWarrantyStatus: null,
  roleDetail: null,
  regionList: null,
  tiers: [],

  financingsData: [],


  // ======================
  caprepaApplications: [],
  // ======================
  capitalApplications: [],
  // ======================
  payrollApplications: [],
  payrollPayments: [],
  finishedApplications: [],
  colabApplications: [],
  // ======================
  loadings: {
    userData: false,
    saveSupplier: false,
    suppliersTable: false,
    supplierDetail: false,
    types: false,
    categories: false,
    subcategories: false,
    supplierAddresses: false,
    savingAddresses: false,
    deletingAddress: false,
    supplierContacts: false,
    savingContacts: false,
    newContact: false,
    removeContact: false,
    newAddress: false,
    removeAddress: false,
    deletingContact: false,
    sepomexInfo: false,
    contactTypes: false,
    savingContactType: false,
    typeDetail: false,
    savingSupplierType: false,
    allCategories: false,
    categoryDetail: false,
    allSubCategories: false,
    savingSupplierCategory: false,
    savingSupplierSubCategory: false,
    aditionalFormData: false,
    inventory: false,
    brands: false,
    models: false,
    storages: false,
    frontal_cameras: false,
    rear_cameras: false,
    SIMs: false,
    OSs: false,
    screens: false,
    services: false,
    phoneDetail: false,
    savePhone: false,
    deletePhoneImage: false,
    savingBrand: false,
    // ======================
    payrollApplications: false,
    updatingPayrollApplication: false,
    payrollPayments: false,
    updatingPayrollPayment: false,
    // ======================
    capitalApplications: false,
    updatingCapitalApplications: false,
    // ======================
    caprepaApplications: false,
    updatingCaprepaApplication: false,
    usersDetail: false,
    saveUser: false,
    updateStatusTiers: false,
    branchesList: false,
    rolesList: false,
    allWarrantyStatus: false,
    saveRole: false,
    roleDetail: false,
    regionList: false,

    financingsData: false,
    savingInventoryByFile: false,
    finishedApplications: false,
    updateContractStatus: false,
    colabApplications: false,

    tiers: false,
    savingTier: false,

  }
};


const setLoading = (state, action) => {
  const { prop, value } = action.payload;
  const loadings = { ...state.loadings };

  loadings[prop] = value;
  return { ...state, loadings };
};

const setSepomexInfo = (state, action) => {
  const { id, data } = action.payload;
  const copy = { ...state.sepomexInfo };
  copy[id] = data;
  return { ...state, sepomexInfo: copy };
}

const unsetAddSepomexInfo = (state) => {
  const copy = { ...state.sepomexInfo };
  copy.new = [];
  return { ...state, sepomexInfo: copy };
}

const addAddress = (state, action) => {
  const copy = [...state.supplierAddresses];
  copy.push(action.payload);
  return { ...state, supplierAddresses: copy };
}

const removeAddress = (state, action) => {
  const copy = [...state.supplierAddresses];
  const fixCopy = copy.filter(item => item.address_id !== action.payload.address_id)
  return { ...state, supplierAddresses: fixCopy };
}

const addContact = (state, action) => {
  const copy = [...state.supplierContacts];
  copy.push(action.payload);
  return { ...state, supplierContacts: copy };
}

const removeContact = (state, action) => {
  const copy = [...state.supplierContacts];
  const fixCopy = copy.filter(item => item.contact_id !== action.payload.contact_id)
  return { ...state, supplierContacts: fixCopy };
}

const addCategory = (state, action) => {
  const copy = [...state.allCategories];
  copy.push(action.payload);
  return { ...state, allCategories: copy };
}

const addSubCategory = (state, action) => {
  const copy = [...state.allSubCategories];
  copy.push(action.payload);
  return { ...state, allSubCategories: copy };
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionsTypes.SET_LOADING:
    return setLoading(state, action);
  case actionsTypes.SET_SELECTED_VIEW:
    return { ...state, selectedView: action.payload };
  case actionsTypes.SET_USERDATA:
    return { ...state, userData: action.payload };
  case actionsTypes.UNSET_USERDATA:
    return { ...state, userData: null };
  case actionsTypes.SET_BRANCH_DATA:
    return { ...state, branchData: action.payload };
  case actionsTypes.SET_INVOICE_TABLE:
    return { ...state, invoiceTable: action.payload };
  case actionsTypes.SET_SUPPLIERS_TABLE:
    return { ...state, suppliersTable: action.payload };
  case actionsTypes.UNSET_SUPPLIERS_TABLE:
    return { ...state, suppliersTable: [] };
  case actionsTypes.SET_SUPPLIER_DETAIL:
    return { ...state, supplierDetail: action.payload };
  case actionsTypes.UNSET_SUPPLIER_DETAIL:
    return { ...state, supplierDetail: [] };
  case actionsTypes.SET_SUPPLIERS_TYPES:
    return { ...state, types: action.payload };
  case actionsTypes.UNSET_SUPPLIERS_TYPES:
    return { ...state, types: [] };
  case actionsTypes.SET_SUPPLIERS_CATEGORIES:
    return { ...state, categories: action.payload };
  case actionsTypes.UNSET_SUPPLIERS_CATEGORIES:
    return { ...state, categories: [] };
  case actionsTypes.SET_SUPPLIERS_SUBCATEGORIES:
    return { ...state, subcategories: action.payload };
  case actionsTypes.UNSET_SUPPLIERS_SUBCATEGORIES:
    return { ...state, subcategories: [] };
  case actionsTypes.SET_SUPPLIER_ADDRESSES:
    return { ...state, supplierAddresses: action.payload };
  case actionsTypes.ADD_SUPPLIER_ADDRESSES:
    return addAddress(state, action);
  case actionsTypes.REMOVE_SUPPLIER_ADDRESSES:
    return removeAddress(state, action);
  case actionsTypes.UNSET_SUPPLIER_ADDRESSES:
    return { ...state, supplierAddresses: [] };
  case actionsTypes.SET_SUPPLIER_CONTACTS:
    return { ...state, supplierContacts: action.payload };
  case actionsTypes.ADD_SUPPLIER_CONTACTS:
    return addContact(state, action);
  case actionsTypes.REMOVE_SUPPLIER_CONTACTS:
    return removeContact(state, action);
  case actionsTypes.UNSET_SUPPLIER_CONTACTS:
    return { ...state, supplierContacts: [] };
  case actionsTypes.SET_CONTACT_TYPES:
    return { ...state, contactTypes: action.payload };
  case actionsTypes.UNSET_CONTACT_TYPES:
    return { ...state, contactTypes: [] };
  case actionsTypes.SET_SEPOMEX_INFO:
    return setSepomexInfo(state, action);
  case actionsTypes.UNSET_ADD_SEPOMEX_INFO:
    return unsetAddSepomexInfo(state, action);
  case actionsTypes.UNSET_SEPOMEX_INFO:
    return { ...state, sepomexInfo: {} };
  case actionsTypes.SET_SUPPLIERS_TYPES_DETAIL:
    return { ...state, typeDetail: action.payload };
  case actionsTypes.UNSET_SUPPLIERS_TYPES_DETAIL:
    return { ...state, typeDetail: null };
  case actionsTypes.SET_ALL_SUPPLIERS_CATEGORIES:
    return { ...state, allCategories: action.payload };
  case actionsTypes.UNSET_ALL_SUPPLIERS_CATEGORIES:
    return { ...state, allCategories: [] };
  case actionsTypes.SET_SUPPLIERS_CATEGORY_DETAIL:
    return { ...state, categoryDetail: action.payload };
  case actionsTypes.UNSET_SUPPLIERS_CATEGORY_DETAIL:
    return { ...state, categoryDetail: null };
  case actionsTypes.SET_ALL_SUPPLIERS_SUBCATEGORIES:
    return { ...state, allSubCategories: action.payload };
  case actionsTypes.UNSET_ALL_SUPPLIERS_SUBCATEGORIES:
    return { ...state, allSubCategories: null };
  case actionsTypes.ADD_SUPPLIERS_CATEGORIES:
    return addCategory(state, action);
  case actionsTypes.ADD_SUPPLIERS_SUBCATEGORIES:
    return addSubCategory(state, action);
  case actionsTypes.SET_ADITIONAL_FORM:
    return { ...state, aditionalFormData: action.payload };
  case actionsTypes.UNSET_ADITIONAL_FORM:
    return { ...state, aditionalFormData: null };

  case actionsTypes.SET_INVENTORY:
    return { ...state, inventory: action.payload };
  case actionsTypes.SET_BRANDS:
    return { ...state, brands: action.payload };
  case actionsTypes.SET_MODELS:
    return { ...state, models: action.payload };
  case actionsTypes.SET_PHONES_STORAGES:
    return { ...state, storages: action.payload };
  case actionsTypes.SET_PHONES_FRONTAL_CAMERAS:
    return { ...state, frontal_cameras: action.payload };
  case actionsTypes.SET_PHONES_REAR_CAMERAS:
    return { ...state, rear_cameras: action.payload };
  case actionsTypes.SET_PHONES_SIMS:
    return { ...state, SIMs: action.payload };
  case actionsTypes.SET_PHONES_OS:
    return { ...state, OSs: action.payload };
  case actionsTypes.SET_PHONES_SCREENS:
    return { ...state, screens: action.payload };
  case actionsTypes.SET_PHONES_SERVICES:
    return { ...state, services: action.payload };
  case actionsTypes.SET_PHONE_DETAIL:
    return { ...state, phoneDetail: action.payload };
    // =====================================================
  case actionsTypes.SET_CAPREPA_APPLICATIONS:
    return { ...state, caprepaApplications: action.payload };
    // =====================================================
  case actionsTypes.SET_PAYROLL_APPLICATIONS:
    return { ...state, payrollApplications: action.payload };
  case actionsTypes.SET_PAYROLL_PAYMENTS:
    return { ...state, payrollPayments: action.payload };

    // =====================================================
  case actionsTypes.SET_CAPITAL_APPLICATIONS:
    return { ...state, capitalApplications: action.payload };
    // =====================================================



  case actionsTypes.SET_USER_DETAIL:
    return { ...state, usersDetail: action.payload };
  case actionsTypes.UNSET_USER_DETAIL:
    return { ...state, usersDetail: [] };

  case actionsTypes.SET_EMAIL_USER_DETAIL:
    return { ...state, usersDetail: action.payload };
  case actionsTypes.UNSET_EMAIL_USER_DETAIL:
    return { ...state, usersDetail: [] };


  case actionsTypes.SET_TIERS:
    return { ...state, tiers: action.payload };

  case actionsTypes.SAVE_TIERS:
    return { ...state, saveTiers: action.payload };


  case actionsTypes.SET_BRANCHES_LIST:
    return { ...state, branchesList: action.payload };
  case actionsTypes.UNSET_BRANCHES_LIST:
    return { ...state, branchesList: [] };

  case actionsTypes.SET_BRANDS_LIST:
    return { ...state, brandsList: action.payload };
  case actionsTypes.UNSET_BRANDS_LIST:
    return { ...state, brandList: [] };


  case actionsTypes.SET_ROLES_LIST:
    return { ...state, rolesList: action.payload };
  case actionsTypes.UNSET_ROLES_LIST:
    return { ...state, rolesList: [] };

  case actionsTypes.SET_BRANCH_DETAIL:
    return { ...state, branchDetail: action.payload };
  case actionsTypes.UNSET_BRANCH_DETAIL:
    return { ...state, branchDetail: null };

  case actionsTypes.SET_ALL_WARRANTY_STATUS:
    return { ...state, allWarrantyStatus: action.payload };

  case actionsTypes.SET_ROLE_DETAIL:
    return { ...state, roleDetail: action.payload };
  case actionsTypes.UNSET_ROLE_DETAIL:
    return { ...state, roleDetail: null };

  case actionsTypes.SAVE_USER:
    return { ...state, saveUser: action.payload };

  case actionsTypes.SAVE_EMAIL_USER:
    return { ...state, saveEmailUser: action.payload };

  case actionsTypes.SET_REGION_LIST:
    return { ...state, regionList: action.payload };
  case actionsTypes.UNSET_REGION_LIST:
    return { ...state, regionList: null };

  case actionsTypes.SET_FINANCINGS_DATA:
    return { ...state, financingsData: action.payload };
  case actionsTypes.UNSET_FINANCINGS_DATA:
    return { ...state, financingsData: null };
  case actionsTypes.SET_FINISHED_APPLICATIONS:
    return { ...state, finishedApplications: action.payload };
  case actionsTypes.UNSET_FINISHED_APPLICATIONS:
    return { ...state, finishedApplications: null };
  case actionsTypes.SET_COLAB_APPLICATIONS:
    return { ...state, colabApplications: action.payload };

  default:
    return state;
  }
};
