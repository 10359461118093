import axios from 'axios';
import { API_URL } from '../../../../utils/constants';

export function getRoleDetail(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/roles/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};


export function getRolesList() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/roles/get-all`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export function getAllWarrantyStatus() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/warrantiesStatus/get-all`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};


export function saveRole(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/roles/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};
