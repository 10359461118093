import React, { Component } from 'react'
import PropTypes from "prop-types";
import { CONTAINERS_BOX_SHADOW_LIGHT } from '../colors'
import { Image } from 'semantic-ui-react';
import fileDownload from '../assets/icons/file-download-icon.svg';
import { ColoredSVG } from './ColoredSVG';

class DocumentPreview extends Component {

  render() {
    const { icon, name, fontSize, rute, color } = this.props;

    return (
      <div style={{ display: "flex", flexDirection: "column", borderRadius: "20px", boxShadow: CONTAINERS_BOX_SHADOW_LIGHT, padding: 10, height: '100%', justifyContent: 'space-between' }}>
        <Image src={icon} style={{ height: '65%' }} />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color, textAlign: "center",  fontSize: fontSize || 12 }}>
          {name}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', position: 'flex-end' }} onClick={() => this.props.onClickCard(rute)}>
          <ColoredSVG
            svg={fileDownload}
            height={15}
            width={15}
            color={this.props.color}
          />
        </div>
      </div>
    );
  }
}

DocumentPreview.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
  fontSize: PropTypes.number,
  rute: PropTypes.string,
  color: PropTypes.string,
  onClickCard: PropTypes.func
};



export default (DocumentPreview);
