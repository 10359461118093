import axios from 'axios';
import { API_URL } from '../../../../utils/constants';


export function getRegionsList(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/region/get-all`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};
