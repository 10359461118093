import axios from 'axios';
import { API_URL } from '../../../../utils/constants';



export function getCaprepaComboBranch() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/branches/get-caprepa-branches`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

export function getCaprepaBranchById(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/branches/getCaprepaBranchById`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getCaprepaUnit(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/branches/get-caprepa-unit-by-branch`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getCaprepaBrands(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/caprepa-brand/get-all`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getCaprepaColabs(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/employee/boss/by-name`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};
