import React, { Component } from "react";
import { Dimmer, Grid, Loader, Segment } from "semantic-ui-react";

const { Column } = Grid;

class Loading extends Component {

  render() {
    return (
      <Grid style={{ padding: 20 }} centered>
        <Column width={16}>
          <Segment style={{ minHeight: 100, marginTop: '20%' }}>
            <Dimmer active inverted>
              <Loader inverted content='Loading' />
            </Dimmer>
          </Segment>
        </Column>
      </Grid>
    );
  };
};


export { Loading };
