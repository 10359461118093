import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon, Checkbox, Popup } from 'semantic-ui-react';
import { BLACK, WHITE } from '../colors';
import { isEqual } from 'lodash';
import formatter from '../utils/formatter';
const { Column } = Grid;
class DropdownMultiple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupVisible: false,
      direction: 'down',
      selectedValues: this.formatDefaulValue(props.value)
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !isEqual(nextState, this.state) || !isEqual(clean(nextProps), clean(this.props));
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.selectedValues, this.state.selectedValues)) {
      if (this.props.onChange) {
        this.props.onChange(Object.keys(this.state.selectedValues));
      }
    }
    if (!isEqual(prevProps.value, this.props.value)) {
      this.setState({ selectedValues: this.formatDefaulValue(this.props.value) });
    }
  }

  onCheckBoxClick(item) {
    const { excludeItem } = this.props;
    const { selectedValues } = this.state;
    const values = { ...selectedValues };
    if (excludeItem) {
      if (item.id === excludeItem.id) {
        if (values[item.id]) {
          delete values[item.id];
        } else {
          this.setState({ selectedValues: { [item.id]: item.text } });
          return;
        }
      } else if (values[excludeItem.id]) {
        delete values[excludeItem.id];
      }
    }

    if (values[item.id]) {
      delete values[item.id];
    } else {
      values[item.id] = item.text;
    }
    this.setState({ selectedValues: values });
  }

  formatDefaulValue(value) {
    if (value && value.length) {
      let fixedDefault = {};
      value.forEach(item => {
        fixedDefault[item] = true;
      });
      return fixedDefault;
    }
    return {};
  }

  renderOptions() {
    const { options, columns, disabled } = this.props;
    if (options) {
      return options.map((item, i) => ((
        <Column key={i} width={columns === 2 ? 8 : 16} style={{ paddingBottom: 3}} >
          <Checkbox
            disabled={disabled}
            label={item.text}
            checked={!!this.state.selectedValues[item.id]}
            onChange={() => { this.onCheckBoxClick(item); }}
          />
        </Column>
      )));
    }
  }

  renderNum() {
    const { selectedValues } = this.state;
    const { inverted, accentColor } = this.props;
    if (Object.keys(selectedValues).length !== 0) {
      return (
        <div
          style={{
            marginLeft: 5,
            height: 25,
            width: 25,
            textAlign: 'center',
            padding: 5,
            backgroundColor: inverted ? accentColor : WHITE,
            color: BLACK,
            borderRadius: '50%',
            marginRight: 5,
            paddingTop: 3
          }}
        >
          <span>{Object.keys(selectedValues).length}</span>
        </div>
      );
    }
  }

  render() {
    const { popupVisible, selectedValues } = this.state;
    const { title, disabled, containerStyle, style, inverted, outsideLabel, accentColor, columns } = this.props;
    const labelStyle = { fontWeight: 'bold', fontSize: 14 };
    const triggerStyle = {
      backgroundColor: WHITE,
      borderRadius: 50,
      border: `1px solid rgba(34,36,38,.15)`,
      height: 38,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'space-between',
      userSelect: 'none',
      paddingRight: 15,
      paddingLeft: 15,
      marginTop: outsideLabel ? 4 : 0
    };

    if (Object.keys(selectedValues).length) {
      triggerStyle.border = `1px solid rgba(34,36,38,.15)`;
      triggerStyle.backgroundColor = inverted ? WHITE : accentColor;
      triggerStyle.color = inverted ? BLACK : WHITE;
    }

    return (
      <Popup
        on='click'
        open={popupVisible}
        onOpen={() => { this.setState({ popupVisible: true }); }}
        onClose={() => { this.setState({ popupVisible: false }); }}
        position='bottom right'
        content={
          <Grid columns={columns} style={{ borderRadius: 5, paddingBottom: 10, ...containerStyle }}>
            {this.renderOptions()}
          </Grid>
        }
        trigger={
          <div>
            <label style={{...labelStyle}}>{outsideLabel}</label>
            <div style={{ ...triggerStyle, ...style, opacity: disabled ? 0.7 : 1 }}>
              <span style={{ marginBottom: 0, marginLeft: 5 }}>{outsideLabel ? 'Selected items' : title}</span>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {this.renderNum()}
                <div style={{ paddingTop: 5 }}>
                  <Icon name={`chevron ${popupVisible ? 'up' : 'down'}`} />
                </div>
              </div>
            </div>
          </div>
        } />
    );
  }
}

DropdownMultiple.propTypes = {
  options: PropTypes.array,
  columns: PropTypes.number,
  title: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  containerStyle: PropTypes.object,
  style: PropTypes.object,
  value: PropTypes.any,
  inverted: PropTypes.bool,
  outsideLabel: PropTypes.string,
  accentColor: PropTypes.string,
  excludeItem: PropTypes.object,
};

export {DropdownMultiple};