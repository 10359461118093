import * as actionsTypes from './types';


const INITIAL_STATE = {
  selectedView: '',
  userData: null,
  sepomexInfo: {},
  // ==========================
  phonesData: [],
  phoneDetail: null,
  brands: [],
  relatedPhones: [],
  // ==========================
  storages: [],
  frontal_cameras: [],
  rear_cameras: [],
  SIMs: [],
  OSs: [],
  screens: [],
  services: [],
  // ==========================
  applicationData: false,
  signedApplicationPDF: false,
  // ==========================
  financingData: null,
  financingPayments: [],

  // ==========================
  topPhones: [],
  activeBrands: [],

  // ==========================
  caprepaUnit: [],
  caprepaBranches: [],
  caprepaBrands: [],
  caprepaColabs: [],

  loadings: {
    userData: false,
    sepomexInfo: false,
    // ==========================
    phonesData: false,
    phoneDetail: false,
    brands: false,
    relatedPhones: false,
    // ==========================
    storages: false,
    frontal_cameras: false,
    rear_cameras:false,
    SIMs: false,
    OSs: false,
    screens: false,
    services: false,
    // ==========================
    savingApplication: false,
    updatingApplication: false,
    savingApplicationPDF: false,
    savingColabIne: false,
    applicationPDF: false,
    applicationData: false,
    signedApplicationPDF: false,

    // ==========================
    financingData: false,
    financingPayments: false,

    // ==========================
    topPhones: false,
    activeBrands: false,

    // ==========================
    caprepaUnit: false,
    caprepaBranches: false,
    caprepaBrands: false,
    caprepaColabs: false,
  }
};


const setLoading = (state, action) => {
  const { prop, value } = action.payload;
  const loadings = { ...state.loadings };

  loadings[prop] = value;
  return { ...state, loadings };
};

const setSepomexInfo = (state, action) => {
  const { id, data } = action.payload;
  const copy = { ...state.sepomexInfo };
  copy[id] = data;
  return { ...state, sepomexInfo: copy };
}

const unsetAddSepomexInfo = (state) => {
  const copy = { ...state.sepomexInfo };
  copy.new = [];
  return { ...state, sepomexInfo: copy };
}


export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionsTypes.SET_LOADING:
    return setLoading(state, action);
  case actionsTypes.SET_SELECTED_VIEW:
    return { ...state, selectedView: action.payload };
  case actionsTypes.SET_USERDATA:
    return { ...state, userData: action.payload };
  case actionsTypes.UNSET_USERDATA:
    return { ...state, userData: null };
    // ==========================
  case actionsTypes.SET_PHONES:
    return { ...state, phonesData: action.payload };
  case actionsTypes.SET_PHONE_DETAIL:
    return { ...state, phoneDetail: action.payload };
  case actionsTypes.SET_PHONES_BRANDS:
    return { ...state, brands: action.payload };
  case actionsTypes.SET_PHONES_STORAGES:
    return { ...state, storages: action.payload };
  case actionsTypes.SET_PHONES_FRONTAL_CAMERAS:
    return { ...state, frontal_cameras: action.payload };
  case actionsTypes.SET_PHONES_REAR_CAMERAS:
    return { ...state, rear_cameras: action.payload };
  case actionsTypes.SET_PHONES_SIMS:
    return { ...state, SIMs: action.payload };
  case actionsTypes.SET_PHONES_OS:
    return { ...state, OSs: action.payload };
  case actionsTypes.SET_PHONES_SCREENS:
    return { ...state, screens: action.payload };
  case actionsTypes.SET_PHONES_SERVICES:
    return { ...state, services: action.payload };
    // ==========================
  case actionsTypes.SET_SEPOMEX_INFO:
    return setSepomexInfo(state, action);
  case actionsTypes.UNSET_ADD_SEPOMEX_INFO:
    return unsetAddSepomexInfo(state, action);
  case actionsTypes.UNSET_SEPOMEX_INFO:
    return { ...state, sepomexInfo: {} };
    // ==========================
  case actionsTypes.SET_APPLICATION_DATA:
    return { ...state, applicationData: action.payload };
  case actionsTypes.SET_SIGNED_APPLICATION_PDF:
    return { ...state, signedApplicationPDF: action.payload };

    // ==========================
  case actionsTypes.SET_FINANCING_DATA:
    return { ...state, financingData: action.payload };

  case actionsTypes.SET_FINANCING_PAYMENTS:
    return { ...state, financingPayments: action.payload };

    // ==========================
  case actionsTypes.SET_TOP_PHONES:
    return { ...state, topPhones: action.payload };
  case actionsTypes.SET_ACTIVE_BRANDS:
    return { ...state, activeBrands: action.payload };

    // ==========================

  case actionsTypes.SET_CAPREPA_BRANCHES:
    return { ...state, caprepaBranches: action.payload };
  case actionsTypes.SET_CAPREPA_UNIT:
    return { ...state, caprepaUnit: action.payload };
  case actionsTypes.SET_CAPREPA_BRANDS:
    return { ...state, caprepaBrands: action.payload };
  case actionsTypes.SET_CAPREPA_COLABS:
    return { ...state, caprepaColabs: action.payload };

  case actionsTypes.SET_TIERS:
    return { ...state, tiersDetails: action.payload };
  case actionsTypes.UNSET_TIERS:
    return { ...state, tiersDetails: [] };

  case actionsTypes.SET_RELATED_PHONES:
    return { ...state, relatedPhones: action.payload };

  default:
    return state;
  }
};
