import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FadeInView } from './FadeInView';
import { Button, Icon, Image } from 'semantic-ui-react';
import { BLUE, GRAY, RED, WHITE } from '../colors';
import formatter from '../utils/formatter';
import notifications from '../utils/notifications';
import getWindow from '../utils/getWindow';
import eventManager from '../utils/eventManager';
import tokens from '../utils/tokens';

const styles = {
  dimmer: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.2)',
  },
  buttonLabel: {
    height: 42,
    padding: 10,
    borderRadius: 10,
    marginRight: 5,
    fontWeight: 'bold',
    border: '1px solid white',
    cursor: 'pointer',
    backgroundColor: BLUE,
    color: WHITE,
  },
  deleteButton: {
    height: 42,
    padding: 10,
    borderRadius: 10,
    marginRight: 5,
    fontWeight: 'bold',
    border: '1px solid white',
    cursor: 'pointer',
    backgroundColor: RED,
    color: WHITE,
  }
};

const EXTENSIONS = ['jpg', 'png', 'jpeg'];
const MAX_SIZE = 14000;


class ImagePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovering: false,
      id: tokens.generate()
    };
  }

  componentDidMount() {
    this.revicedImage = eventManager.on('save_cropped', (data) => {
      if (data.id === this.state.id) {
        this.getBase64(data.image, (base64, dimensions) => {
          if (this.props.onChange) {
            this.props.onChange(base64, dimensions);
            this.inputRef.value = null;
          };
        });
      };
    });

    this.closedCropperCallbackID = eventManager.on('closed_croppper', () => {
      this.inputRef.value = null;
    });
  }

  componentWillUnmount() {
    eventManager.unsubscribe('save_cropped', this.revicedImage);
    eventManager.unsubscribe('closed_croppper', this.closedCropperCallbackID);
  }

  // ----------
  // METHODS
  // ----------
  onChangeFile(e) {
    if (e.target.files.length) {
      const currentFile = e.target.files[0];
      let format = currentFile.name.split('.');
      format = format[format.length - 1];
      const size = currentFile.size / 1024;

      if (size > MAX_SIZE) {
        notifications.error(`The image size must be less than ${this.getMaxSizeLabel()}`);
      } else if (!this.validExtension(format)) {
        notifications.error(`The file extension must be one of ${EXTENSIONS.toString()}`);
      } else {
        this.getBase64(currentFile, (base64, dimensions) => {
          if (this.props.cropped) {
            eventManager.emit('open-cropper', {
              id: this.state.id,
              image: base64,
              height: this.props.height,
              width: this.props.width
            });
          } else if (this.props.onChange) {
            this.props.onChange(base64, dimensions);
          }
        });
      }
    }
  }

  onDelete() {
    this.inputRef.value = null;

    if (this.props.onChange) {
      this.props.onChange(null);
    }

    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  getMaxSizeLabel() {
    if (MAX_SIZE < 1024) {
      return `${MAX_SIZE} KB`;
    }

    return `${MAX_SIZE / 1024} MB`;
  }

  validExtension(format) {
    const foundExtensions = EXTENSIONS.filter((item) => item === format.toLowerCase());
    return !!foundExtensions.length;
  }

  getBase64(currentFile, callback) {
    const reader = new FileReader();
    let base64;

    reader.onloadend = () => {
      const windowObject = getWindow();
      const img = new windowObject.Image();
      img.src = reader.result;

      img.addEventListener('load', () => {
        const dimensions = { width: img.width, height: img.height };
        base64 = reader.result;
        callback(base64, dimensions);
      });
    };

    reader.readAsDataURL(currentFile);
  }

  // ----------
  // RENDERS
  // ----------
  renderContent() {
    const { image, height, width } = this.props;

    const imgStyle = {
      maxWidth: '100%',
      aspectRatio: `${width} / ${height}`
    };

    if (!image) {
      return (
        <div
          onMouseEnter={() => this.setState({ hovering: true })}
          style={{ ...imgStyle, backgroundColor: '#C7D1E2', color: WHITE, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Icon name='image' size='big' />
        </div>
      );
    }

    if (this.props.original) {
      return (
        <div onMouseEnter={() => this.setState({ hovering: true })}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              ref={(ref) => {
                if (this.props.initRef) {
                  this.props.initRef(ref);
                }
              }}
              style={{ display: 'inline' }}
            >
              <Image src={image} alt='' />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div style={imgStyle} onMouseEnter={() => this.setState({ hovering: true })}>
        <img alt='' style={{ height: '100%', width: '100%' }} src={image} />
      </div>
    );
  }

  renderDimmer() {
    const { hovering } = this.state;
    const { title, image, isOwn, onlyDelete } = this.props;
    const id = formatter.slugify(title);

    if (!hovering) {
      return null;
    }

    let delete_Button = false;

    if (image) {
      delete_Button = true;
    }

    if (!isOwn) {
      delete_Button = false;
    }

    if (onlyDelete) {
      return (
        <div
          style={styles.dimmer}
          onMouseLeave={() => this.setState({ hovering: false })}
        >
          <FadeInView style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Button style={styles.deleteButton} onClick={this.onDelete.bind(this)}>Delete</Button>
          </FadeInView>
        </div>
      );
    }

    return (
      <div
        style={styles.dimmer}
        onMouseLeave={() => this.setState({ hovering: false })}
      >
        <FadeInView style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <label htmlFor={id} style={styles.buttonLabel}>
            Change
          </label>

          {delete_Button ?
            (<Button style={styles.deleteButton} onClick={this.onDelete.bind(this)}>Delete</Button>)
            :
            null
          }
        </FadeInView>
      </div>
    );
  }

  renderTitle() {
    const { hideTitle, title } = this.props;

    if (hideTitle) {
      return null;
    }

    return (
      <p style={{ fontWeight: 'bold', marginBottom: 1, textAlign: 'left' }}>
        { title }
      </p>
    );
  }

  render() {
    const { title, height, width, readOnly, hiddeSize } = this.props;
    const id = formatter.slugify(title);

    return (
      <div>
        { this.renderTitle() }

        <div style={{ width: '100%', border: `1px solid #EBF0F6`, borderRadius: 5, display: 'inline-block' }}>
          <div style={{ border: '7px solid #FAFAFB', borderRadius: 5 }}>
            <div style={{ position: 'relative', borderRadius: 5, overflow: 'hidden' }}>
              { this.renderContent() }
              { readOnly ? null : this.renderDimmer() }
            </div>
          </div>
        </div>

        <input
          ref={((inputRef) => { this.inputRef = inputRef; })}
          type="file"
          hidden
          name={id}
          id={id}
          style={{ visibility: false }}
          onChange={this.onChangeFile.bind(this)}
        />

        {hiddeSize ? null : (<p style={{ color: GRAY, marginTop: 5 }}>Tamaño recomendado: {width}x{height}</p>)}
      </div>
    );
  }
}


ImagePicker.propTypes = {
  title: PropTypes.string,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  image: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  isOwn: PropTypes.bool,
  onDelete: PropTypes.func,
  hideTitle: PropTypes.bool,
  initRef: PropTypes.func,
  original: PropTypes.bool,
  hiddeSize: PropTypes.bool,
  onlyDelete: PropTypes.bool,
  cropped: PropTypes.bool,
};


export { ImagePicker };
