import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Grid, Divider } from 'semantic-ui-react';

const { Column, Row } = Grid;

const styles = {
  buttonClose: {
    borderRadius: 100,
    width: 20,
    backgroundColor: 'transparent'
  },
  headerContainer: {
    paddingLeft: 15,
    paddingBottom: 15,
  },
  rightItems: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end"
  }
};


// "blurring"
function ModalComponent(props) {
  return (
    <Modal
      onClose={() => props.onClose(false)}
      // onOpen={props.onOpen}
      size={props.size}
      style={props.style}
      open={props.open}
    >
      <Modal.Header style={{ padding: 0, borderBottom: 'none' }}>
        <Grid style={props.mobile ? { width: '115%' } : {}}>
          <Row>
            {
              props.title ? (
                <Column style={{ display: 'flex', justifyContent: 'center' }} width={14}>
                  <p style={{ marginLeft: 50 }}>{props.title}</p>
                </Column>
              ) : ''
            }

            <Column width={props.title ? 2 : 16} style={{ paddingRight: props.mobile ? 0 : '' }}>
              <div style={{ ...styles.headerContainer, ...styles.rightItems }}>
                <div
                  style={{ position: 'relative', display: 'inline-block', background: 'rgb(230,230,230)', borderRadius: 20, cursor: 'pointer', padding: 15, marginTop: props.mobile ? -5 : -8, marginRight: props ? 5 : 8 }}
                  onClick={() => props.onClose(false)}
                >
                  <span style={{ fontSize: 18, position: 'absolute', top: 1, left: 10 }}>
                    x
                  </span>
                </div>
              </div>
            </Column>

          </Row>
        </Grid>
      </Modal.Header>
      {
        props.title ? <Divider style={{ marginTop: 30 }} /> : ''
      }
      <Modal.Content style={{ paddingTop: 0 }}>
        {props.children}
      </Modal.Content>
    </Modal>
  )
}


ModalComponent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  size: PropTypes.string,
  style: PropTypes.object,
  mobile: PropTypes.bool
};


export { ModalComponent };
