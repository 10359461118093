import axios from 'axios';
import { API_URL } from '../../../../utils/constants';

export function getTiers(body) {
  return () => axios({
    method: 'GET',
    url: `${API_URL}/cat-phones/get-all-tiers`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function saveTiers(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/save-tier`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};
