import axios from 'axios';
import { API_URL } from '../../../../utils/constants';


// Trae los datos de los pagos asociados a un financiamiento mediante la especificación de un IMEI
//* Sales/Payments
export function getFinancingPayments(params) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/financings/customer/get-amortization-table?financing_id=${params.financing_id}&amount=${params.amount}`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

// Guarda un nuevo pago para un financiamiento
//Sales/Payments
export function savePayment(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/financings/customer/payments`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};
