import * as actionsTypes from './types';


const INITIAL_STATE = {
  selectedView: '',
  warrantyInfo: null,
  warrantyDocs: null,
  applicationData: null,
  loadings: {
    warrantyInfo: false,
    warrantyDocs: false,
    warrantyEvidenceURL: false,
    validateRequest: false,
    applicationData: false
  }
};


const setLoading = (state, action) => {
  const { prop, value } = action.payload;
  const loadings = { ...state.loadings };

  loadings[prop] = value;
  return { ...state, loadings };
};


export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionsTypes.SET_LOADING:
    return setLoading(state, action);
  case actionsTypes.SET_SELECTED_VIEW:
    return { ...state, selectedView: action.payload };
  case actionsTypes.SET_WARRANTY_INFO:
    return { ...state, warrantyInfo: action.payload };
  case actionsTypes.UNSET_WARRANTY_INFO:
    return { ...state, warrantyInfo: null };
  case actionsTypes.SET_WARRANTY_DOCS:
    return { ...state, warrantyDocs: action.payload };
  case actionsTypes.SET_APPLICATION:
    return { ...state, applicationData: action.payload };

  default:
    return state;
  }
};
