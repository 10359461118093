import { call, put } from 'redux-saga/effects';
import notifications from '../../../utils/notifications';
import eventManager from '../../../utils/eventManager';
import * as actionsTypes from '../store/types';
import * as calls from './calls';


export function* getSepomexInfo(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'sepomexInfo', value: true } });

    const request = calls.getSepomexInfo(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'sepomexInfo', value: false } });
      yield put({ type: actionsTypes.SET_SEPOMEX_INFO, payload: { data: serverResponse.data, id: action.payload.id } });
    } else {
      notifications.error('Error: Obtener Informacion Sepomex');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'sepomexInfo', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  }
}

// ================================================

export function* getPhonesCat(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesData', value: true } });

    const request = calls.getPhonesCat(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesData', value: false } });
      yield put({ type: actionsTypes.SET_PHONES, payload: serverResponse.data });
    } else {
      notifications.error(serverResponse.data.message);
      yield put({ type: actionsTypes.UNSET_PHONES, payload: [] });

    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phonesData', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getPhoneDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phoneDetail', value: true } });

    const request = calls.getPhoneDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phoneDetail', value: false } });
      yield put({ type: actionsTypes.SET_PHONE_DETAIL, payload: serverResponse.data });
    } else {
      notifications.error('Error: Detalle del telefono');
      yield put({ type: actionsTypes.SET_PHONE_DETAIL, payload: null });

    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'phoneDetail', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getPhonesBrandsCat(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'brands', value: true } });

    const request = calls.getPhonesBrandsCat(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'brands', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_BRANDS, payload: serverResponse.data });
    } else {
      notifications.error('Error: Marcas');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'brands', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getPhonesStoragesCat(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'storages', value: true } });

    const request = calls.getPhonesStoragesCat(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'storages', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_STORAGES, payload: serverResponse.data });
    } else {
      notifications.error('Error: Modelos');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'storages', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getPhonesFrontalCamerasCat(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'frontal_cameras', value: true } });

    const request = calls.getPhonesFrontalCamerasCat(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'frontal_cameras', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_FRONTAL_CAMERAS, payload: serverResponse.data });
    } else {
      notifications.error('Error: Camaras');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'frontal_cameras', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getPhonesRearCamerasCat(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'rear_cameras', value: true } });

    const request = calls.getPhonesRearCamerasCat(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'rear_cameras', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_REAR_CAMERAS, payload: serverResponse.data });
    } else {
      notifications.error('Error: Camaras Traseras');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'rear_cameras', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getPhonesSIMsCat(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'SIMs', value: true } });

    const request = calls.getPhonesSIMsCat(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'SIMs', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_SIMS, payload: serverResponse.data });
    } else {
      notifications.error('Error: SIMs');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'SIMs', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getPhonesOSsCat(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'OSs', value: true } });

    const request = calls.getPhonesOSsCat(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'OSs', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_OS, payload: serverResponse.data });
    } else {
      notifications.error('Error: Sistemas Operativos');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'OSs', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getPhonesScreensCat(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'screens', value: true } });

    const request = calls.getPhonesScreensCat(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'screens', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_SCREENS, payload: serverResponse.data });
    } else {
      notifications.error('Error: Pantallas');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'screens', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getPhonesServicesCat(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'services', value: true } });

    const request = calls.getPhonesServicesCat(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'services', value: false } });
      yield put({ type: actionsTypes.SET_PHONES_SERVICES, payload: serverResponse.data });
    } else {
      notifications.error('Error: Servicios Telefonicos');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'services', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getRelatedPhones(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'relatedPhones', value: true } });

    const request = calls.getRelatedPhones(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'relatedPhones', value: false } });
      yield put({ type: actionsTypes.SET_RELATED_PHONES, payload: serverResponse.data });
    } else {
      notifications.error('Error: Servicios Telefonicos');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'relatedPhones', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

// ================================================

export function* saveApplication(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingApplication', value: true } });

    const request = calls.saveApplication(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingApplication', value: false } });
      yield put({ type: actionsTypes.SET_APPLICATION_DATA, payload: serverResponse.data });
      eventManager.emit('saved-application');
    } else {
      notifications.error('Error: Generar Solicitud');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingApplication', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* updateApplication(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingApplication', value: true } });

    const request = calls.updateApplication(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingApplication', value: false } });
      yield put({ type: actionsTypes.SET_APPLICATION_DATA, payload: null });
      eventManager.emit('updated-application');
    } else {
      notifications.error('Error: Borrar Solicitud');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updatingApplication', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}


export function* getApplicationData(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'applicationData', value: true } });

    const request = calls.getApplicationData(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'applicationData', value: false } });
      yield put({ type: actionsTypes.SET_APPLICATION_DATA, payload: serverResponse.data });
    } else {
      notifications.error('Error: Obtener Solicitud');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'applicationData', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getApplicationPDF(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'applicationPDF', value: true } });

    const request = calls.getApplicationPDF(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (response.status === 200) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'applicationPDF', value: false } });
      eventManager.emit('application-pdf', serverResponse.data);

    } else {
      notifications.error('Error: Obtener 1er PDF');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'applicationPDF', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getContractPDF(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'applicationPDF', value: true } });

    const request = calls.getContractPDF(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (response.status === 200) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'applicationPDF', value: false } });
      eventManager.emit('application-pdf', serverResponse.data);

    } else {
      notifications.error('Error: Obtener 1er PDF');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'applicationPDF', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* saveApplicationPDF(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingApplicationPDF', value: true } });

    const request = calls.saveApplicationPDF(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingApplicationPDF', value: false } });
      eventManager.emit('saved-application-pdf');

    } else {
      notifications.error('Error: Guardar 1er PDF');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingApplicationPDF', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getSignedApplicationPDF(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'signedApplicationPDF', value: true } });

    const request = calls.getSignedApplicationPDF(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'signedApplicationPDF', value: false } });
      yield put({ type: actionsTypes.SET_SIGNED_APPLICATION_PDF, payload: serverResponse.data });
    } else {
      notifications.error('Error: Obtener 1er PDF firmado');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'signedApplicationPDF', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}
// ================================================

export function* getFinancingData(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingData', value: true } });

    const request = calls.getFinancingData(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingData', value: false } });
      yield put({ type: actionsTypes.SET_FINANCING_DATA, payload: serverResponse.data });
    } else {
      notifications.error('Error: Obtener Financiamiento');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingData', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getFinancingPayments(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingPayments', value: true } });

    const request = calls.getFinancingPayments(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingPayments', value: false } });
      yield put({ type: actionsTypes.SET_FINANCING_PAYMENTS, payload: serverResponse.data });
    } else {
      notifications.error('Error: Obtener Pagos');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingPayments', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}


export function* saveColabIne(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingColabIne', value: true } });

    const request = calls.saveColabIne(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingColabIne', value: false } });
      eventManager.emit('saved-colab-ine');

    } else {
      notifications.error('Error: Guardar INE');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savingColabIne', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

// ================================================

export function* getTopPhones(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'topPhones', value: true } });

    const request = calls.getTopPhones(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'topPhones', value: false } });
      yield put({ type: actionsTypes.SET_TOP_PHONES, payload: serverResponse.data });
    } else {
      notifications.error('Error: Traer los teléfonos');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'topPhones', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getActiveBrands(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'activeBrands', value: true } });

    const request = calls.getActiveBrands(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'activeBrands', value: false } });
      yield put({ type: actionsTypes.SET_ACTIVE_BRANDS, payload: serverResponse.data });
    } else {
      notifications.error('Error: Catalogo de Telefonos');
      yield put({ type: actionsTypes.SET_ACTIVE_BRANDS, payload: [] });

    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'activeBrands', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}


export function* getCaprepaBranches(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'caprepaBranches', value: true } });

    const request = calls.getCaprepaComboBranch(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'caprepaBranches', value: false } });
      yield put({ type: actionsTypes.SET_CAPREPA_BRANCHES, payload: serverResponse.data });
    } else {
      notifications.error('Error: Catalogo de sucursales caprepa');
      yield put({ type: actionsTypes.SET_CAPREPA_BRANCHES, payload: [] });

    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'caprepaBranches', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getCaprepaUnit(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'caprepaUnit', value: true } });

    const request = calls.getCaprepaUnit(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;


    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'caprepaUnit', value: false } });
      yield put({ type: actionsTypes.SET_CAPREPA_UNIT, payload: serverResponse.data });
      eventManager.emit('caprepa-unit');
    } else {
      notifications.error('Error: Catalogo de unidades caprepa');
      yield put({ type: actionsTypes.SET_CAPREPA_BRANCHES, payload: [] });

    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'caprepaUnit', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getCaprepaBrands(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'caprepaBrands', value: true } });

    const request = calls.getCaprepaBrands(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'caprepaBrands', value: false } });
      yield put({ type: actionsTypes.SET_CAPREPA_BRANDS, payload: serverResponse.data });
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'caprepaBrands', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getCaprepaColabs(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'caprepaColabs', value: true } });

    const request = calls.getCaprepaColabs(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'caprepaColabs', value: false } });
      yield put({ type: actionsTypes.SET_CAPREPA_COLABS, payload: serverResponse.data });
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'caprepaColabs', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* getAllTiers(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'tiersDetails', value: true } });

    const request = calls.getAllTiers(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'tiersDetails', value: false } });
      yield put({ type: actionsTypes.SET_TIERS, payload: serverResponse.data });
    } else {
      notifications.error('gama no encontrado');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'tiersDetails', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}
