export const SET_LOADING = 'web/SET_LOADING';
export const SET_SELECTED_VIEW = 'web/SET_SELECTED_VIEW';

export const GET_WARRANTY_INFO = 'web/GET_WARRANTY_INFO';
export const SET_WARRANTY_INFO = 'web/SET_WARRANTY_INFO';
export const UNSET_WARRANTY_INFO = 'web/UNSET_WARRANTY_INFO';

export const GET_WARRANTY_DOCS = 'web/GET_WARRANTY_DOCS';
export const SET_WARRANTY_DOCS = 'web/SET_WARRANTY_DOCS';

export const GET_WARRANTY_EVIDENCE_URL = 'web/GET_WARRANTY_EVIDENCE_URL';

export const VALIDATE_REQUEST = 'web/VALIDATE_REQUEST';

export const GET_APPLICATION = 'web/GET_APPLICATION';
export const SET_APPLICATION = 'web/SET_APPLICATION';
