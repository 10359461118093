import { combineReducers } from 'redux';
import appContainer from './app';
import warrantyContainer from './warranty';
import loginContainer from './login';
import branchContainer from './branch';
import adminContainer from './admin';
import colabContainer from './colab';
import webContainer from './web';


const containers = {
  reducers: combineReducers({
    app: appContainer.reducer,
    warranty: warrantyContainer.reducer,
    login: loginContainer.reducer,
    branch: branchContainer.reducer,
    admin: adminContainer.reducer,
    colab: colabContainer.reducer,
    web: webContainer.reducer,
  }),
  sagas: {
    warranty: warrantyContainer.saga,
    login: loginContainer.saga,
    branch: branchContainer.saga,
    admin: adminContainer.saga,
    colab: colabContainer.saga,
    web: webContainer.saga,
  },
};


export default containers;
