import axios from 'axios';
import { API_URL } from '../../../../utils/constants';


export function getUsersDetail() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/users/get-all`,
    headers: {
      'Content-Type': 'application/json'
    },

  });
};




export function saveUser(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/users/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

