import { Grid, Image, Icon, Transition } from "semantic-ui-react";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import eventManager from "../utils/eventManager";
import { LIGHTEST_GRAY, WHITE } from "../colors";

const { Column } = Grid;

class VerticalPhoneGallery extends Component {
  constructor(props) {
    super(props);
    let currentImage = null;
    if (props.data && props.data.image) {
      currentImage = props.data.image;
    };

    this.state = {
      currentImage,
      mainVisible: true
    };
  };

  componentDidMount() {
    this.CallbackAnimation = eventManager.on('start-animation', () => {
      this.setState({ mainVisible: false });
      setTimeout(() => {
        this.setState({ mainVisible: true });
      }, 10);
    });
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({ currentImage: this.props.data.image});
    };
  };

  // -------------------
  // ----- methods -----
  // -------------------
  onImageClick(image) {
    this.setState({ currentImage: image, mainVisible: false });

    setTimeout(() => {
      this.setState({ mainVisible: true });
    }, 10);
  }
  // ------------------------
  // ---- render methods ----
  // ------------------------
  renderMainImage() {
    const { currentImage } = this.state;

    if (!currentImage) {
      return (
        <div style={{ backgroundColor: LIGHTEST_GRAY, borderRadius: 25, padding: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
          <Icon name="image" style={{ color: WHITE, width: '100%' }} />
        </div>
      )
    };

    return (
      <Transition
        animation={'pulse'}
        duration={500}
        visible={this.state.mainVisible}
      >
        <Image fluid src={currentImage} style={{ maxHeight: 550, objectFit: 'contain' }} />
      </Transition>
    );
  }

  renderImagesRow() {
    const { data } = this.props;
    let arr = []

    if (data && data.image) {
      arr.push({ image: data.image, phone_gallery_id: 'main' });
    };

    if (data && data.phone_gallery) {
      arr.push(...data.phone_gallery);
    };

    const content = arr.map(item => {
      return (
        <div
          key={item.phone_gallery_id}
          style={{ padding: 5, borderRadius: 25, border: `1px solid ${LIGHTEST_GRAY}`, width: 115, height: 115, margin: 5 }}
          onClick={() => this.onImageClick(item.image)}
        >
          <Image src={item.image} rounded centered style={{ objectFit: 'cover', height: 100 }} />
        </div>
      );
    })
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-start', }}>
        {content}
      </div>
    );
  }

  render() {
    return (
      <Grid stretched>
        <Column width={16}>
          {this.renderMainImage()}
        </Column>
        <Column width={16}>
          {this.renderImagesRow()}
        </Column>
      </Grid>
    );
  };
}

VerticalPhoneGallery.propTypes = {
  data: PropTypes.object,
};


export { VerticalPhoneGallery };
