export const SET_LOADING = 'colab/SET_LOADING';
export const SET_SELECTED_VIEW = 'colab/SET_SELECTED_VIEW';

export const GET_USER_BY_SESSION = 'colab/GET_USER_BY_SESSION';
export const SET_USERDATA = 'colab/SET_USERDATA';
export const UNSET_USERDATA = 'colab/UNSET_USERDATA';

// ==================================================================
export const GET_SEPOMEX_INFO = 'colab/GET_SEPOMEX_INFO';
export const SET_SEPOMEX_INFO = 'colab/SET_SEPOMEX_INFO';
export const UNSET_ADD_SEPOMEX_INFO = 'colab/UNSET_ADD_SEPOMEX_INFO';
export const UNSET_SEPOMEX_INFO = 'colab/UNSET_SEPOMEX_INFO';
// ==================================================================
export const GET_FINANCING_DATA = 'colab/GET_FINANCING_DATA';
export const SET_FINANCING_DATA = 'colab/SET_FINANCING_DATA';
export const UNSET_FINANCING_DATA = 'colab/UNSET_FINANCING_DATA';

export const GET_PHONES = 'colab/GET_PHONES';
export const SET_PHONES = 'colab/SET_PHONES';
export const UNSET_PHONES = 'colab/UNSET_PHONES';

export const GET_PHONE_DETAIL = 'colab/GET_PHONE_DETAIL';
export const SET_PHONE_DETAIL = 'colab/SET_PHONE_DETAIL';

export const GET_PHONES_BRANDS = 'colab/GET_PHONES_BRANDS';
export const SET_PHONES_BRANDS = 'colab/SET_PHONES_BRANDS';

export const GET_PHONES_STORAGES = 'colab/GET_PHONES_STORAGES';
export const SET_PHONES_STORAGES = 'colab/SET_PHONES_STORAGES';

export const GET_PHONES_FRONTAL_CAMERAS = 'colab/GET_PHONES_FRONTAL_CAMERAS';
export const SET_PHONES_FRONTAL_CAMERAS = 'colab/SET_PHONES_FRONTAL_CAMERAS';

export const GET_PHONES_REAR_CAMERAS = 'colab/GET_PHONES_REAR_CAMERAS';
export const SET_PHONES_REAR_CAMERAS = 'colab/SET_PHONES_REAR_CAMERAS';

export const GET_PHONES_SIMS = 'colab/GET_PHONES_SIMS';
export const SET_PHONES_SIMS = 'colab/SET_PHONES_SIMS';

export const GET_PHONES_OS = 'colab/GET_PHONES_OS';
export const SET_PHONES_OS = 'colab/SET_PHONES_OS';

export const GET_PHONES_SCREENS = 'colab/GET_PHONES_SCREENS';
export const SET_PHONES_SCREENS = 'colab/SET_PHONES_SCREENS';

export const GET_PHONES_SERVICES = 'colab/GET_PHONES_SERVICES';
export const SET_PHONES_SERVICES = 'colab/SET_PHONES_SERVICES';

export const GET_RELATED_PHONES = 'colab/GET_RELATED_PHONES';
export const SET_RELATED_PHONES = 'colab/SET_RELATED_PHONES';
// ==================================================================
export const SAVE_APPLICATION = 'colab/SAVE_APPLICATION';
export const UPDATE_APPLICATION = 'colab/UPDATE_APPLICATION';

export const GET_APPLICATION_DATA = 'colab/GET_APPLICATION_DATA';
export const SET_APPLICATION_DATA = 'colab/SET_APPLICATION_DATA';

export const SAVE_APPLICATION_PDF = 'colab/SAVE_APPLICATION_PDF';
export const GET_APPLICATION_PDF = 'colab/GET_APPLICATION_PDF';
export const GET_CONTRACT_PDF = 'colab/GET_CONTRACT_PDF';


export const GET_SIGNED_APPLICATION_PDF = 'colab/GET_SIGNED_APPLICATION_PDF';
export const SET_SIGNED_APPLICATION_PDF = 'colab/SET_SIGNED_APPLICATION_PDF';

export const SAVE_COLAB_INE = 'colab/SAVE_COLAB_INE';


// ==================================================================

export const GET_FINANCING_PAYMENTS = 'colab/GET_FINANCING_PAYMENTS';
export const SET_FINANCING_PAYMENTS = 'colab/SET_FINANCING_PAYMENTS';

// ==================================================================

export const GET_TOP_PHONES = 'colab/GET_TOP_PHONES';
export const SET_TOP_PHONES = 'colab/SET_TOP_PHONES';

export const GET_ACTIVE_BRANDS = 'colab/GET_ACTIVE_BRANDS';
export const SET_ACTIVE_BRANDS = 'colab/SET_ACTIVE_BRANDS';

// ==================================================================

export const GET_CAPREPA_BRANCHES = 'colab/GET_CAPREPA_BRANCHES';
export const SET_CAPREPA_BRANCHES = 'colab/SET_CAPREPA_BRANCHES';

export const GET_CAPREPA_UNIT = 'colab/GET_CAPREPA_UNIT';
export const SET_CAPREPA_UNIT = 'colab/SET_CAPREPA_UNIT';

export const GET_CAPREPA_BRANDS = 'colab/GET_CAPREPA_BRANDS';
export const SET_CAPREPA_BRANDS = 'colab/SET_CAPREPA_BRANDS';

export const GET_CAPREPA_COLABS = 'colab/GET_CAPREPA_COLABS';
export const SET_CAPREPA_COLABS = 'colab/SET_CAPREPA_COLABS';

//===================================================================

export const GET_TIERS = 'colab/GET_TIERS';
export const SET_TIERS = 'colab/SET_TIERS';
export const UNSET_TIERS = 'colab/UNSET_TIERS';

