export const WHITE = '#FFFFFF';
export const BLACK = '#000000';

export const GRAY = '#707070';
export const LIGHT_GRAY = '#E0E0E5';
export const LIGHTEST_GRAY = '#DFDFDF';

export const LIGHT_BLUE = '#B5DBED';
export const BLUE = '#017DC7';
export const PALE_BLUE = '#577DBC'
export const DARK_BLUE = '#1A1C41';

export const LIGHT_PURPLE = '#AD7DB2';
export const PURPLE = '#8359A0';

export const GREEN = '#39DD84';
export const YELLOW = '#E9CA2D';
export const ORANGE = '#E57200';
export const RED = '#FF0000';

export const GRADIENT_TO_BOTTOM_GRAY = 'linear-gradient(to bottom, #E0E0E5, #707070)';

export const GRADIENT_TO_BOTTOM_BLUE_WHITE = 'linear-gradient(to bottom, #B5DBED, #FFFFFF)';

export const CONTAINERS_BOX_SHADOW = '0px 0px 45px rgb(0 0 0 / 10%)';
export const CONTAINERS_BOX_SHADOW_LIGHT = '0 0 0 1px rgb(0 0 0 / 5%), 0 2px 3px 0 rgb(0 0 0 / 10%)';
export const DROP_SHADOW = 'drop-shadow(0px 4px 6px #00000029)';

export const GRADIENT_TO_RIGHT_BLUE = 'linear-gradient(to right, #577DBC, #B5DBED)';
export const GRADIENT_TO_LEFT_BLUE = 'linear-gradient(to left, #577DBC, #B5DBED)';
export const GRADIENT_TO_BOTTOM_BLUE = 'linear-gradient(to bottom, #577DBC, #B5DBED)';
export const GRADIENT_TO_BOTTOM_BLUE_BG = 'linear-gradient(to bottom, rgba(87, 125, 188, .3), rgba(181, 219, 237, .3))';
export const GRAD_ON_DIAG_TO_BLUE = 'linear-gradient(121deg, #577DBC, #B5DBED)';

export const GRADIENT_TO_BOTTOM_DARK_PURPLE = 'linear-gradient(to bottom, #1A1C41, #AD7DB2)';
export const GRADIENT_TO_RIGHT_DARK_PURPLE = 'linear-gradient(to right, #1A1C41, #AD7DB2)';

export const GRADIENT_TO_BOTTOM_DARK_RED = 'linear-gradient(to bottom, #52170b, #ff00A0)';

export const GRADIENT_TO_BOTTOM_DARK_BLUE = 'linear-gradient(to bottom, #1A1C41, #577DBC)';
export const GRADIENT_TO_TOP_DARK_BLUE = 'linear-gradient(to bottom, #577DBC, #1A1C41)';
export const GRADIENT_TO_RIGHT_DARK_BLUE = 'linear-gradient(to right, #1A1C41, #577DBC)';
export const GRAD_ON_DIAG_TO_DARK_BLUE = 'linear-gradient(121deg, #1A1C41, #577DBC)';


export const GRADIENT_TO_BOTTOM_LIGHT_PURPLE = 'linear-gradient(to bottom, #AD7DB2, #8359A0)';
export const GRADIENT_TO_RIGHT_LIGHT_PURPLE = 'linear-gradient(to right, #AD7DB2, #8359A0)';
export const GRADIENT_TO_LEFT_LIGHT_PURPLE = 'linear-gradient(to left, #AD7DB2, #8359A0)';

export const GRAD_TO_BOTTOM_FROM_PURPLE_TO_BLUE = 'linear-gradient(to bottom, #AD7DB2, #577DBC)';
export const GRAD_TO_RIGHT_FROM_PURPLE_TO_BLUE = 'linear-gradient(to right, #AD7DB2, #577DBC)';
export const GRAD_TO_LEFT_FROM_PURPLE_TO_BLUE = 'linear-gradient(to left, #AD7DB2, #577DBC)';

export const GRAD_TO_BOTTOM_FROM_DARK_BLUE_TO_PURPLE = 'linear-gradient(to bottom, #1A1C41 30%, #AD7DB2)';



export const OPACITY_25 = 'opacity: .25';
export const OPACITY_50 = 'opacity: .5';
export const OPACITY_75 = 'opacity: .75';

export const NEW_BLACK = 'black';

export const GRADIENTE_SVG = '<stop offset="0%" stop-color="red" /> <stop offset="100%" stop-color="yellow" />'

export const CONTAINERS_BORDER = '2px solid rgb(245,245,245)';
