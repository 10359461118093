import { call, put } from 'redux-saga/effects';
import * as calls from '../calls/index.js';
import * as actionsTypes from '../../store/types';
import notifications from '../../../../utils/notifications';
// import eventManager from '../../../../utils/eventManager';


export function* getFinancingPayments(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingPayments', value: true } });

    const request = calls.getFinancingPayments(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingPayments', value: false } });
      yield put({ type: actionsTypes.SET_FINANCING_PAYMENTS, payload: serverResponse.data });
    } else {
      notifications.error('Financiamiento no encontrado');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'financingPayments', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

// export function* savePayment(action) {
//   try {
//     yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savePayment', value: true } });

//     const request = calls.savePayment(action.payload);
//     const response = yield call(request);
//     const serverResponse = response.data;

//     if (serverResponse.isValid) {
//       yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savePayment', value: false } });
//       eventManager.emit('saved-payment', serverResponse.data);
//     } else {
//       notifications.error('No se pudo generar el pago');
//     };
//     yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'savePayment', value: false } });

//   } catch (error) {
//     console.log(error.message);
//   };
// };
