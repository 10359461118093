import { takeLatest, all } from 'redux-saga/effects';
import * as actionsTypes from '../store/types';
import * as workers from './workers';

export default function* watcherSaga() {
  yield all([
    takeLatest(actionsTypes.GET_SEPOMEX_INFO, workers.getSepomexInfo),

    // =========================================================================

    takeLatest(actionsTypes.GET_PHONES, workers.getPhonesCat),
    takeLatest(actionsTypes.GET_PHONE_DETAIL, workers.getPhoneDetail),
    takeLatest(actionsTypes.GET_PHONES_BRANDS, workers.getPhonesBrandsCat),
    takeLatest(actionsTypes.GET_PHONES_STORAGES, workers.getPhonesStoragesCat),
    takeLatest(actionsTypes.GET_PHONES_FRONTAL_CAMERAS, workers.getPhonesFrontalCamerasCat),
    takeLatest(actionsTypes.GET_PHONES_REAR_CAMERAS, workers.getPhonesRearCamerasCat),
    takeLatest(actionsTypes.GET_PHONES_SIMS, workers.getPhonesSIMsCat),
    takeLatest(actionsTypes.GET_PHONES_OS, workers.getPhonesOSsCat),
    takeLatest(actionsTypes.GET_PHONES_SCREENS, workers.getPhonesScreensCat),
    takeLatest(actionsTypes.GET_PHONES_SERVICES, workers.getPhonesServicesCat),
    takeLatest(actionsTypes.GET_RELATED_PHONES, workers.getRelatedPhones),

    // =========================================================================

    takeLatest(actionsTypes.SAVE_APPLICATION, workers.saveApplication),
    takeLatest(actionsTypes.UPDATE_APPLICATION, workers.updateApplication),
    takeLatest(actionsTypes.GET_APPLICATION_DATA, workers.getApplicationData),
    takeLatest(actionsTypes.GET_APPLICATION_PDF, workers.getApplicationPDF),
    takeLatest(actionsTypes.GET_CONTRACT_PDF, workers.getContractPDF),
    takeLatest(actionsTypes.SAVE_APPLICATION_PDF, workers.saveApplicationPDF),
    takeLatest(actionsTypes.GET_SIGNED_APPLICATION_PDF, workers.getSignedApplicationPDF),
    takeLatest(actionsTypes.SAVE_COLAB_INE, workers.saveColabIne),
    // =========================================================================

    takeLatest(actionsTypes.GET_FINANCING_DATA, workers.getFinancingData),
    takeLatest(actionsTypes.GET_FINANCING_PAYMENTS, workers.getFinancingPayments),

    // =========================================================================

    takeLatest(actionsTypes.GET_TOP_PHONES, workers.getTopPhones),
    takeLatest(actionsTypes.GET_ACTIVE_BRANDS, workers.getActiveBrands),

    // =========================================================================
    takeLatest(actionsTypes.GET_CAPREPA_BRANCHES, workers.getCaprepaBranches),
    takeLatest(actionsTypes.GET_CAPREPA_UNIT, workers.getCaprepaUnit),
    takeLatest(actionsTypes.GET_CAPREPA_BRANDS, workers.getCaprepaBrands),
    takeLatest(actionsTypes.GET_CAPREPA_COLABS, workers.getCaprepaColabs),

    //==========================================================================

    takeLatest(actionsTypes.GET_TIERS, workers.getAllTiers),
  ]);
}
