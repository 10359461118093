import axios from 'axios';
import { API_URL, MASTER_TOKEN } from '../../../../utils/constants';

export function getUserDataBySession(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/sessions/get-user-by-session`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function saveSupplier(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getSuppliersTable() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/suppliers/get-all`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

export function getSupplierDetail(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getSuppliersTypeDetail(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/type/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getAditionalFormData() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/aditional-form/get-all`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': MASTER_TOKEN
    },
  });
};
