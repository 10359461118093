import { takeLatest, all } from 'redux-saga/effects';
import * as actionsTypes from '../store/types';
import * as workers from './workers';

export default function* watcherSaga() {
  yield all([
    takeLatest(actionsTypes.GET_USER_BY_SESSION, workers.getUserDataBySession),
    takeLatest(actionsTypes.GET_BRANCH_DATA, workers.getBranchData),
    takeLatest(actionsTypes.GET_INVOICE_TABLE, workers.getInvoiceTable),
    takeLatest(actionsTypes.GET_INVOICE_TABLE_BY_STATUS, workers.getInvoiceTableByStatus),
    takeLatest(actionsTypes.GET_ALL_WARRANTY_STATUS, workers.getAllWarrantyStatus),
    takeLatest(actionsTypes.GET_DROPDOWN_WARRANTY_STATUS, workers.getDropdownWarrantyStatus),
    takeLatest(actionsTypes.GET_WARRANTY_DETAIL, workers.getWarrantyDetail),
    takeLatest(actionsTypes.UPDATE_WARRANTIES_STATUS, workers.changeInvoiceStatus),
    takeLatest(actionsTypes.UPDATE_WARRANTIES_STATUS_DOC, workers.changeInvoiceStatusWithDoc),
    takeLatest(actionsTypes.GET_FAILURES, workers.getFailures),
    takeLatest(actionsTypes.GET_SYMPTOMS, workers.getSymptoms),
    takeLatest(actionsTypes.GET_ACCESORIES, workers.getAccesories),
    takeLatest(actionsTypes.GET_BRANCHES, workers.getBranches),
    takeLatest(actionsTypes.GET_WARRANTY_EVIDENCE, workers.getWarrantyEvidence),
    takeLatest(actionsTypes.SAVE_WARRANTY_EVIDENCE, workers.uploadWarrantyEvidence),
    takeLatest(actionsTypes.GET_WARRANTY_EVIDENCE_URL, workers.getWarrantyEvidenceUrl),
    takeLatest(actionsTypes.GET_WARRANTY_LOGS, workers.getWarrantyLogs),
    takeLatest(actionsTypes.SAVE_WARRANTY_QUOTE, workers.generateQuote),
    takeLatest(actionsTypes.UPDATE_ALL_WARRANTY_DETAIL, workers.updateAllWarantyDetail),
    takeLatest(actionsTypes.GET_PHONE_DATA, workers.getPhoneData),
    takeLatest(actionsTypes.SAVE_INVOICE_SWAP, workers.saveInvoiceSwap),
    takeLatest(actionsTypes.GET_FINANCING_DATA, workers.getFinancingData),
    takeLatest(actionsTypes.GET_DASHBOARD_STATS, workers.getDashboardStats),
    takeLatest(actionsTypes.GET_ROLES_LIST, workers.getRolesList),
    takeLatest(actionsTypes.SAVE_USER, workers.saveUser),
    takeLatest(actionsTypes.SAVE_ROLE, workers.saveRole),
    takeLatest(actionsTypes.GET_USERS_LIST, workers.getUsersList),
    takeLatest(actionsTypes.GET_USER_DETAIL, workers.getUserDetail),
    takeLatest(actionsTypes.GET_ROLE_DETAIL, workers.getRoleDetail),
    takeLatest(actionsTypes.GET_BRANCHES_LIST, workers.getBranchesList),
    takeLatest(actionsTypes.GET_BRANCH_DETAIL, workers.getBranchDetail),
    takeLatest(actionsTypes.SAVE_BRANCH, workers.saveBranch),
    takeLatest(actionsTypes.SAVE_WARRANTY_DIAGNOSIS, workers.saveWarrantyDiagnosis),
    takeLatest(actionsTypes.GET_INVENTORY_LIST, workers.getInventoryList),
    takeLatest(actionsTypes.GET_ADITIONAL_FORM, workers.getAditionalFormData),
    takeLatest(actionsTypes.GET_GESTION_DETAIL, workers.getGestionDetail),
    takeLatest(actionsTypes.SAVE_GESTION_LOG, workers.saveGestionLog),
    takeLatest(actionsTypes.GET_ALL_RECOVERIES_PHONES, workers.getAllRecoveriesPhone),
    takeLatest(actionsTypes.GET_PHONES_BRANDS, workers.getPhonesBrandsCategories),
    takeLatest(actionsTypes.GET_PHONE_NAMES_BY_BRAND, workers.getPhonesNamesByBrand),
    takeLatest(actionsTypes.GET_RECOVERY_PHONE_BY_ID, workers.getRecoveryPhoneById),
    takeLatest(actionsTypes.GET_RECOVERY_EVIDENCE, workers.getRecoveryEvidence),
    takeLatest(actionsTypes.GET_RECOVERY_EVIDENCE_URL, workers.getRecoveryEvidenceUrl),
    takeLatest(actionsTypes.SAVE_RECOVERY_EVIDENCE, workers.uploadRecoveryEvidence),
    takeLatest(actionsTypes.GET_RECOVERY_LOGS, workers.getRecoveriesLogs),
    takeLatest(actionsTypes.UPDATE_RECOVERY_STATUS, workers.changeRecoveryStatus),
    takeLatest(actionsTypes.SAVE_RECOVERY_QUOTE, workers.generateRecoveryQuote),
    takeLatest(actionsTypes.MOD_RECOVERY_STATUS, workers.modRecoveryStatus),
    takeLatest(actionsTypes.GET_ALL_BRANCH_WARRANTIES, workers.getAllBranchWarranties),
    takeLatest(actionsTypes.GET_COLAB_APPLICATIONS, workers.getColabApplications),
    takeLatest(actionsTypes.UPDATE_CAPREPA_APPLICATION, workers.updateCaprepaApplication),
    takeLatest(actionsTypes.GET_SEPOMEX_INFO, workers.getSepomexInfo),
    takeLatest(actionsTypes.GET_PHONES_BY_BRAND, workers.getPhonesByBrand),
    takeLatest(actionsTypes.UPDATE_RECOVERY_STATUS_MULTIPLE, workers.updateRecoveryStatusMultiple),
    takeLatest(actionsTypes.GET_BRANCH_WARRANTY_DETAIL_BY_ID, workers.getBranchWarrantiesDetailById),
    takeLatest(actionsTypes.GET_BRANCH_WARRANTY_LOGS_BY_ID, workers.getBranchWarrantyLogsById),
    takeLatest(actionsTypes.GET_BRANCH_WARRANTY_DOCUMENTS_EVIDENCE_BY_ID, workers.getBranchWarrantyDocumentsEvidenceById),
    takeLatest(actionsTypes.SAVE_BRANCH_WARRANTY_EVIDENCE, workers.uploadBranchWarrantyEvidence),
    takeLatest(actionsTypes.SAVE_BRANCH_WARRANTY_QUOTE, workers.generateBranchWarrantyQuote),
    takeLatest(actionsTypes.UPDATE_BRANCH_WARRANTY_STATUS, workers.updateBranchWarrantyStatus),
    takeLatest(actionsTypes.UPDATE_BRANCH_WARRANTY_STATUS_MULTIPLE, workers.updateBranchWarrantyStatusMultiple)
  ]);
}
