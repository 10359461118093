import axios from 'axios';
import { API_URL } from '../../../../utils/constants';

export function saveApplication(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/applications/employee/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function updateApplication(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/applications/employee/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getApplicationData(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/applications/employee/by-employee`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getApplicationPDF(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/applications/employee/pdf-sol`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getContractPDF(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/applications/employee/pdf-contr`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function saveApplicationPDF(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidence/employee/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getSignedApplicationPDF(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidence/employee/by-application`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getFinancingData(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/financings/employee/by-employee`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getFinancingPayments(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/financings/employee/payments/by-employee`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function saveColabIne(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidence/employee/save-ine`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};
