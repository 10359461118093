import axios from 'axios';
import { API_URL } from '../../../../utils/constants';

//* Vistas que lo utilizan

// Trae los datos del sesión del usuario
//* layout
export function getUserDataBySession(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/sessions/get-user-by-session`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

//Trae un listado con todas las sucursales
//* Warranty/AddRequest
//* Warranty/Branch/AddRequest
//* Warranty/ConfirmClient
//* Warranty/Detail
//* Warranty/Recoveries/AddRecoveries
//* Warranty/Recoveries/Detail
export function getBranches() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/branches/get-all`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

// Trae los datos estadísticos de la sucursal proporcionada mediante ID
//* Home
export function getDashboardStats(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/get-count-war`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae todos los datos de la dirección asociada al código postal proporcionado
//*Financing/AditionalForm
//*Financing/Identification/Application/Manual
//*Financing/Identification/Application/Manual/AddressData
export function getSepomexInfo(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/addresses/by-postalCode`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Envía un token de validación al email proporcionado
//*Financing/Identification/Application
export function getOtp(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/customers/aplication/send-message`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Hace una compraración entre el token que se envía con el que se encuentra asociado al solicitante en la base de datos
//*Financing/Identification/Application
export function validateOtp(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/customers/aplication/confirm-pin`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};
