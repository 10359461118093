import axios from 'axios';
import { API_URL } from '../../../../utils/constants';


export function getInvetory() {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/get-all-phones`,
    headers: {
      'Content-Type': 'application/json',
    }
  });
};

export function getBrands() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-brand`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export function getModels(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/model-by-brand`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: body
  });
};

export function getPhoneDetail(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/by-id`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: body
  });
};

export function savePhone(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function deletePhoneImage(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/delete-gallery`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function saveBrand(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/save-brand`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// ==============================================

export function getPhonesStorage(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-rom`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getPhonesFrontalCamera(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-frontal-camera`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getPhonesRearCamera(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-rear-camera`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getPhonesSIM(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-sim`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getPhonesOS(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-os`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getPhonesScreen(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-screen`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getPhonesService(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-service`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function saveInventoryByFile(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/load-cat-phone`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};
