import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import Axios from "axios";

const path_regex = /<path[^>]+d="([^"]+)"/;

class Svg extends Component {
  constructor(props) {
    super(props);

    this.state = {
      svgContent: ''
    };
  };

  componentDidMount() {
    const { file } = this.props;
    this.getImage(file);
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.file, this.props.file)) {
      this.getImage(this.props.file);
    }
  };

  // ------------------------
  // ----    methods     ----
  // ------------------------

  getImage(newSvg) {
    Axios.get(newSvg)
      .then(response => {
        this.setState({ svgContent: response.data });
      })
      .catch(error => {
        console.error('Error fetching SVG:', error);
      });
  };

  // ------------------------
  // ---- render methods ----
  // ------------------------

  render() {
    const { width, height } = this.props;
    const { svgContent } = this.state;

    const d = svgContent.match(path_regex);

    // if (path) {
    // const svg_path = path.match(/d="([^"]*)"/);

    // }

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
      >
        <path
          id="Unión_5"
          data-name="Unión 5"
          d={d}
          transform="translate(0 0)"
          fill="#fff"
        />
      </svg>
    );
  };
}

Svg.propTypes = {
  file: PropTypes.string,
  // color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export { Svg };

