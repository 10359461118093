import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, Checkbox } from 'semantic-ui-react';
import { isEqual, eq } from 'lodash';
import formatter from '../../../utils/formatter';

const { Column } = Grid;


class CheckboxInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultValue ? props.defaultValue : false,
      valid: true,
      dirty: false,
    };
  }


  // -----------------------------
  // ------ life cycle events ----
  // -----------------------------
  componentDidMount() {
    this.setForm();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.dirty && nextProps.defaultValue) {
      this.setState({ value: nextProps.defaultValue, valid: true });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !isEqual(nextState, this.state) || !isEqual(clean(nextProps), clean(this.props));
  }

  componentDidUpdate(prevProps, prevState) {
    if (!eq(prevState, this.state)) {
      this.setForm();
    }
  }


  // -----------------------
  // ------ user events ----
  // -----------------------
  onChange(e, data) {
    this.setState({ value: data.checked, dirty: true });
  }

  setForm() {
    this.props.setFormData(this.props.name, this.state);
  }

  getValue() {
    return this.state.value;
  }

  dirtInput() {
    this.setState({ dirty: true });
  }

  resetInput() {
    this.setState({
      value: this.props.defaultValue ? this.props.defaultValue : false,
      valid: true,
      dirty: false,
    });
  }


  // --------------------------
  // ------ render methods ----
  // --------------------------
  renderCheck() {

    if (this.props.checkAlign === 'up') {
      return (
        <Grid style={{ ...styles.input, ...this.props.inputStyle }}>
          <Column style={styles.containerColumn}>
            <span style={{ ...this.props.labelStyle, marginBottom: 10 }}>
              { this.props.label }
            </span>

            <Checkbox
              toggle={this.props.slider}
              name={this.props.name}
              checked={this.state.value}
              onChange={this.onChange.bind(this)}
              disabled={this.props.readOnly}
              className={this.props.className}
            />
          </Column>
        </Grid>
      );
    }

    if (this.props.checkAlign === 'right') {
      return (
        <Grid style={{ ...styles.input, ...this.props.inputStyle }}>
          <Column style={styles.containerRightColumn}>
            <span style={this.props.labelStyle}>
              { this.props.label }
            </span>

            <Checkbox
              toggle={this.props.slider}
              name={this.props.name}
              checked={this.state.value}
              onChange={this.onChange.bind(this)}
              disabled={this.props.readOnly}
              className={this.props.className}
            />
          </Column>
        </Grid>
      );
    }

    return (
      <Grid style={{ ...styles.input, ...this.props.inputStyle }}>
        <Column style={styles.containerLeftColumn}>
          <Checkbox
            toggle={this.props.slider}
            name={this.props.name}
            checked={this.state.value}
            onChange={this.onChange.bind(this)}
            disabled={this.props.readOnly}
            className={this.props.className}
          />

          <span style={{ marginLeft: 10, ...this.props.labelStyle }}>
            { this.props.label }
          </span>
        </Column>
      </Grid>
    );
  }

  render() {
    return (
      <Form.Field>
        { this.renderCheck() }
      </Form.Field>
    );
  }
}


const styles = {
  input: {
    width: '100%',
  },
  containerColumn: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  containerRightColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerLeftColumn: {
    display: 'flex',
    alignItems: 'center',
  },
};


CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  inputStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  defaultValue: PropTypes.any,
  setFormData: PropTypes.func.isRequired,
  slider: PropTypes.bool,
  checkAlign: PropTypes.oneOf(['up', 'right', 'left']),
};


// exportar componente
export default CheckboxInput;
