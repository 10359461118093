import axios from 'axios';
import { API_URL } from '../../../../utils/constants';

export function getSepomexInfo(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/addresses/by-postalCode`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};
