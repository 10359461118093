import { takeLatest, all } from 'redux-saga/effects';
import * as actionsTypes from '../store/types';
import * as workers from './workers';


export default function* watcherSaga() {
  yield all([
    takeLatest(actionsTypes.USER_LOGIN, workers.userLogin),
    takeLatest(actionsTypes.CAPREPA_LOGIN, workers.caprepaLogin),
  ]);
}
