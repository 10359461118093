import React, { Component } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { Button } from 'semantic-ui-react';
import { isEqual } from 'lodash';
import formatter from '../../utils/formatter';
import { BLUE, GRAY, WHITE } from '../../colors';
import { AnimatedModal } from '../AnimatedModal';
import eventManager from '../../utils/eventManager';
import i18n from "./i18n.json";

class ImageCropper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imgSrc: '',
      crop: null,
      completedCrop: null,
      scale: 1,
      rotate: 0,
      height: 0,
      width: 0
    };
  }

  componentDidMount() {
    this.openCropperCallbackID = eventManager.on('open-cropper', (data) => {
      let isLandscape = data.width > data.height;

      let crop = {
        unit: '%',
        aspect: data.width / data.height,
      };

      if (isLandscape) {
        crop.x = 0;
        crop.y = 50;
        crop.width = 100;
        crop.height = (data.height / data.width) * 100;
      } else {
        crop.x = 50;
        crop.y = 0;
        crop.width = (data.width / data.height) * 100;
        crop.height = 100;
      }

      this.setState({
        visible: true,
        cropperID: data.id,
        imgSrc: data.image,
        height: data.height,
        width: data.width,
        crop,
      });
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !isEqual(nextState, this.state) || !isEqual(clean(nextProps), clean(this.props));
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.completedCrop, this.state.completedCrop)) {
      if (
        this.state.completedCrop &&
        this.state.completedCrop.width &&
        this.state.completedCrop.height &&
        this.imgRef &&
        this.previewCanvasRef
      ) {
        this.canvasPreview();
      }
    }
  }

  componentWillUnmount() {
    eventManager.unsubscribe('open-croppper', this.openCropperCallbackID);
  }

  onImageLoad(e) {
    const { width, height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        this.state.width/this.state.height,
        width,
        height,
      ),
      width,
      height,
    );

    this.setState({ crop });
  }

  async canvasPreview() {
    const { completedCrop } = this.state;
    const canvas = this.previewCanvasRef;
    const ctx = canvas.getContext('2d');
    const image = this.imgRef;

    if (!ctx) {
      throw new Error('No 2d context');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.floor(completedCrop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(completedCrop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    const cropX = completedCrop.x * scaleX;
    const cropY = completedCrop.y * scaleY;

    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    ctx.translate(-cropX, -cropY);
    ctx.translate(centerX, centerY);
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
    );

    ctx.restore();
  }

  getDisabledButton() {
    const { completedCrop } = this.state;

    const _imgRef = this.imgRef;
    const _previewCanvasRef = this.previewCanvasRef;

    if (!_imgRef || !_previewCanvasRef || !completedCrop) {
      return true;
    };

    if ( !completedCrop.width || !completedCrop.height) {
      return true;
    };

    return false;
  }

  async onSaveClick() {
    const { completedCrop } = this.state;

    const _imgRef = this.imgRef;
    const _previewCanvasRef = this.previewCanvasRef;

    if (!_imgRef || !_previewCanvasRef || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }

    const scaleX = _imgRef.naturalWidth / _imgRef.width;
    const scaleY = _imgRef.naturalHeight / _imgRef.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    );

    const ctx = offscreen.getContext('2d');

    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      _previewCanvasRef,
      0,
      0,
      _previewCanvasRef.width,
      _previewCanvasRef.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    );

    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    });

    eventManager.emit('save_cropped', { id: this.state.cropperID, image: blob });
    this.setState({ visible: false });
  }

  onCloseHandler() {
    eventManager.emit('closed_croppper');
    this.setState({ visible: false, visibleConfirm: false });
  }

  render() {
    const { visible, imgSrc, crop, width, height, completedCrop, visibleConfirm } = this.state;

    return (
      <>
        <AnimatedModal
          onClose={() => this.setState({ visibleConfirm: true })}
          open={visible}
          style={{ padding: 20, paddingBottom: 0, maxWidth: 810 }}
        >
          <div style={{ textAlign: 'center' }}>
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => this.setState({ crop: percentCrop })}
              onComplete={(c) => this.setState({ completedCrop: c })}
              aspect={width / height}
            >
              <img
                ref={(ref) => { this.imgRef = ref; }}
                alt="Original"
                src={imgSrc}
                onLoad={this.onImageLoad.bind(this)}
              />
            </ReactCrop>
          </div>

          <div style={{ opacity: 0, position: 'absolute', bottom: -2000, right: -2000, zIndex: 1 }}>
            <canvas
              ref={(ref) => { this.previewCanvasRef = ref; }}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop ? completedCrop.width : 0,
                height: completedCrop ? completedCrop.height : 0
              }}
            />
          </div>

          <div style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              content={i18n.save_btn['es']}
              style={{ backgroundColor: BLUE, color: WHITE, zIndex: 100 }}
              onClick={() => this.onSaveClick()}
              disabled={this.getDisabledButton()}
            />
          </div>
        </AnimatedModal>

        <AnimatedModal
          onClose={() => this.setState({ visibleConfirm: false })}
          open={visibleConfirm}
          style={{ width: 400 }}
        >
          <p
            style={{ fontWeight: 'bold', fontFamily: "bebas-neue", fontSize: 28 }}
          >
            {i18n.closing_modal_title['es']}
          </p>

          <span>{i18n.cuation_message['es']}</span>

          <div style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              basic
              content={i18n.back_close_modal_btn['es']}
              style={{ color: GRAY }}
              onClick={() => this.setState({ visibleConfirm: false })}
            />

            <Button
              content={i18n.confirm_close_modal_btn['es']}
              style={{ backgroundColor: BLUE, color: WHITE  }}
              onClick={() => this.onCloseHandler()}
            />
          </div>
        </AnimatedModal>
      </>
    );
  }
}

ImageCropper.propTypes = {};

export { ImageCropper };
