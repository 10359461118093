import axios from 'axios';
import { API_URL } from '../../../../utils/constants';



export function getPayrollApplications(user_id) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/applications/employee/get-all-nomina?user_id=${user_id}`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

export function updatePayrollApplication(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/addresses/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getCapitalApplications(user_id) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/applications/employee/get-all-CH?user_id=${user_id}`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

export function updateCapitalApplication(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/addresses/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};


export function getCaprepaApplications() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/applications/employee/get-all-CS`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

export function updateCaprepaApplication(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/applications/employee/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getPayrollPayments() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/financings/employee/get-all-unpay`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

export function updatePayrollPayments(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/financings/employee/payments/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getFinancingsData() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/financings/get-all`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

export function savePayrollPaymentsByFile(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/financings/employee/load-payments`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getFinishedApplications() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/applications/employee/get-all-finish`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

export function updateContractStatus(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/financings/employee/update`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getColabApplications() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/applications/employee/get-all-SC`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
