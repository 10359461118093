import axios from 'axios';
import { API_URL } from '../../../utils/constants';

export function getWarrantyInfo(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getWarrantyDocs(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidenceOfGuarantees/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
}

export function getWarrantyEvidenceUrl(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidenceOfGuarantees/downloadfile`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function validateRequest(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/employee/validate-boss`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getApplicationData(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/applications/employee/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};
