import axios from 'axios';
import { API_URL, MASTER_TOKEN } from '../../../utils/constants';

export function getUserDataBySession(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/sessions/get-user-by-session`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getBranchData(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/branch/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getInvoiceTable() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/warranties/get-all`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export function getInvoiceTableByStatus(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/by-option`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      iOpcion: 1,
      iValBusq: body.status
    }
  });
}

export function getAllWarrantyStatus() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/warrantiesStatus/get-all`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export function getDropdownWarrantyStatus() {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warrantiesStatus/get-status-options`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      sent_to: 1
    }
  });
};

export function getWarrantyDetail(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function changeInvoiceStatus(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/mod`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function changeInvoiceStatusWithDoc(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidenceOfGuarantees/SaveFileEsp`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};


export function getFailures() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/failures/get-all`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export function getSymptoms() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/symptoms/get-all`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export function getAccesories() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/accesories/get-all`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export function getBranches() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/branches/get-all`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export function getWarrantyEvidence(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidenceOfGuarantees/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function uploadWarrantyEvidence(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidenceOfGuarantees/saveFile`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getWarrantyEvidenceUrl(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidenceOfGuarantees/downloadfile`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getWarrantyLogs(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/logWarranties/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function generateQuote(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/modWrCot`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function updateAllWarantyDetail(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/modAllInfo`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getPhoneData(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/get-phone`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function saveInvoiceSwap(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/swap`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getFinancingData(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/financings/get-financing-by-imei`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getDashboardStats(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/warranties/get-count`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getRolesList() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/roles/get-all`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export function saveUser(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/users/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function updateUser(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/users/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getUsersList(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/warranty-users/get-all`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getUserDetail(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/users/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function saveRole(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/roles/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getRoleDetail(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/roles/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getBranchesList(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/branches/get-all`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getBranchDetail(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/branches/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function saveBranch(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/branches/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function saveWarrantyDiagnosis(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/wrDiagnosis`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getInventoryList(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/inventory/get-all`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getAditionalFormData() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/aditional-form/get-all-customers`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': MASTER_TOKEN
    },
  });
};

export function getGestionDetail(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/aditional-form/by-customer`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': MASTER_TOKEN
    },
    data: body
  });
};

export function saveGestionLog(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/aditional-form/save-customer`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': MASTER_TOKEN
    },
    data: body
  });
};

export function getAllRecoveriesPhone() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/recoveries/get-all`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': MASTER_TOKEN
    },
  });
};

export function getPhonesBrandsCategories() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-brand`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

export function getPhonesNamesByBrand(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/model-by-brand`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': MASTER_TOKEN
    },
    data: body
  });
};

export function getRecoveryPhoneById(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/recoveries/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getRecoveryEvidence(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidence/recoveries/by-recovery`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getRecoveryEvidenceUrl(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidence/recoveries/downloadfile`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function uploadRecoveryEvidence(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidence/recoveries/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getRecoveriesLogs(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/log/recoveries/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};


export function changeRecoveryStatus(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/recoveries/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function generateRecoveryQuote(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/recoveries/quotes/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function modRecoveryStatus(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/recoveries/mod-status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function updateRecoveryStatusMultiple(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/recoveries/mod-status-mult`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// ********************************** Develop **********************************
export function getColabApplications() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/applications/employee/get-all-SC`,
    headers: {
      'Content-Type': 'application/json',

    },
  });
};

export function updateCaprepaApplication(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/applications/employee/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getSepomexInfo(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/addresses/by-postalCode`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getPhonesByBrand(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/by-brand`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};


// ************************** Modificacion Rinel **************************
export function getAllBranchWarranties() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/warranty-branch/get-all`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': MASTER_TOKEN
    },
  });
};

// obtiene toda la información de una garantía de sucursal por id
//* Warranty/warranties-branches
export function getBranchWarrantiesDetailById(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranty-branch/by-id`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': MASTER_TOKEN
    },
    data: body
  });
};

// obtiene todos los logs de las evidencias de garantía de sucursal
//* Warranty/warranties-branches
export function getBranchWarrantyLogsById(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/log-warranty-branch/by-id`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': MASTER_TOKEN
    },
    data: body
  });
};

// obtiene todos las evidencias ó documentos de garantía de sucursal por id
//* Warranty/warranties-branches
export function getBranchWarrantyDocumentsEvidenceById(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidence/warranty-branch/by-id`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': MASTER_TOKEN
    },
    data: body
  });
};

// sube una evidencia ó archivo de garantía de sucursal
//* Warranty/warranties-branches
export function uploadBranchWarrantyEvidence(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidence/warranty-branch/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Genera la cotizaciónn de garantías de sucursal
//* Warranty/warranties-branches
export function generateBranchWarrantyQuote(body) { // PENDEIN
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranty-branch/quotes`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Actualiza el status de las garántias de sucursal
//* Warranty/warranties-branches
export function updateBranchWarrantyStatus(body) { // PENDEIN
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranty-branch/mod-status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Actualiza los multiples status de las garántias de sucursal
//* Warranty/warranties-branches
export function updateBranchWarrantyStatusMultiple(body) { // PENDEIN
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranty-branch/mod-status-mult`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};