import axios from 'axios';
import { API_URL } from '../../../../utils/constants';


// export function saveBranch(body) {
//   return () => axios({
//     method: 'post',
//     url: `${API_URL}/branches/save`,
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     data: body
//   });
// };

export function getBrandsList(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/caprepa-brand/get-all`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// export function getBranchDetail(body) {
//   return () => axios({
//     method: 'post',
//     url: `${API_URL}/branches/by-id`,
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     data: body
//   });
// };