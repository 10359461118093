import moment from 'moment';
import { isPlainObject, isFunction } from 'lodash';
import 'moment/locale/es';

const formatter = {
  getNumberOfDay(date) {
    return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
  },
  getNumberOfWeek(date) {
    const tdt = new Date(date.valueOf());
    const dayn = (date.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    const firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);

    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    }

    return 1 + Math.ceil((firstThursday - tdt) / 604800000);
  },
  date(date, formatString = 'D MMMM YYYY') {
    if (!date) return '';
    return moment(date).locale('es-mx').format(formatString);
  },
  longDate(date, formatString = 'DD MMM YYYY - hh:mm a') {
    if (!date) return '';
    return moment(date).locale('es-mx').format(formatString);
  },
  shortDate(date, formatString = 'D/MMM/YY') {
    if (!date) return '';

    moment.locale('en');
    return moment(date).locale('es-mx').format(formatString);
  },
  time(date, formatString = 'hh:mm a') {
    if (!date) return '';

    moment.locale('es');
    return moment(date).format(formatString);
  },
  currency(number = 0) {
    const formattedNumber = `$${number.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
    const arrayNumber = formattedNumber.split('.');
    return arrayNumber[1] === '00' ? `${arrayNumber[0]}.00` : formattedNumber;
  },
  currencyInt(number = 0) {
    const formattedNumber = `$${number.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
    const arrayNumber = formattedNumber.split('.');
    return arrayNumber[1] === '00' ? `${arrayNumber[0]}.00` : formattedNumber;
  },
  thousandSeparator(number = 0) {
    const formattedNumber = `${number.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
    const arrayNumber = formattedNumber.split('.');
    return arrayNumber[1] === '00' ? arrayNumber[0] : formattedNumber;
  },
  capitalize(text = '', type) {
    if (!text) return '';

    if (text && !text.length) {
      return text;
    }

    if (text.length === 1) {
      return text.toUpperCase();
    }

    if (type === 'sub') {
      return text;
    }

    const firstLetter = text.substring(0, 1);
    const remainingText = text.substring(1);
    return `${firstLetter.toUpperCase()}${remainingText.toLowerCase()}`;
  },
  clean(object) {
    const result = {};
    const objectAttributes = Object.keys(object);

    objectAttributes.forEach((attribute) => {
      const attributeValue = object[attribute];

      if (isPlainObject(attributeValue)) {
        result[attribute] = formatter.clean(attributeValue);
      } else if (!isFunction(attributeValue)) {
        result[attribute] = attributeValue;
      }
    });

    return result;
  },
  applyMask(mask = '', number = '') {
    const maskArray = mask.split('');
    const numericValueArray = number.toString().split('');
    let maskedValue = '';
    let nextIndex = 0;

    maskArray.forEach((character) => {
      if (character === 'x') {
        maskedValue += numericValueArray[nextIndex];
        nextIndex += 1;
      } else {
        maskedValue += character;
      }
    });

    return maskedValue;
  },
  subString(text = '', maxLength = 50) {
    let shortString = text;

    if (shortString.length > maxLength) {
      shortString = `${shortString.substring(0, maxLength)}...`;
    }

    return shortString;
  },
  monthOfYear: (index = 0) => {
    const months = [
      'Enero',
      'Febrebro',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Augosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Deciembre'
    ];

    return months[index]
  },
  monthOfYearShort: (index = 0) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];

    return months[index]
  },
  slugify(rawText) {
    let str = rawText;
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-") // collapse dashes
      .replace(/^-+/, "") // trim - from start of text
      .replace(/-+$/, ""); // trim - from end of text

    return str;
  },
};


export default formatter;
