import { call, put } from 'redux-saga/effects';
import notifications from '../../../utils/notifications';
import eventManager from '../../../utils/eventManager';
import * as actionsTypes from '../store/types';
import * as actionsTypesColab from '../../colab/store/types';
import * as calls from './calls';
import { CAPREPA_COOKIES } from '../../../utils/constants';

//USER LOGIN
export function* userLogin(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'login', value: true } });

    const request = calls.userLogin(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'login', value: false } });
      yield put({ type: actionsTypes.SET_USERDATA, payload: serverResponse.data });
      eventManager.emit('user_login', {...serverResponse.data, type: action.payload.type});
    } else {
      // notifications.error(serverResponse.message);
      notifications.error('Error al iniciar sesion');
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'login', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}

export function* caprepaLogin(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'caprepaLogin', value: true } });

    const request = calls.caprepaLogin(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.data.success) {
      if (serverResponse.data.data && serverResponse.data.data.length) {
        const currentData = serverResponse.data.data[0];

        let currentUser = {
          employee_id: currentData[CAPREPA_COOKIES.EMPLOYEE_ID],
          first_name: currentData[CAPREPA_COOKIES.FIRST_NAME],
          middle_name: currentData[CAPREPA_COOKIES.MIDDLE_NAME],
          last_name: currentData[CAPREPA_COOKIES.LAST_NAME],
          second_last_name: currentData[CAPREPA_COOKIES.SECOND_LAST_NAME],
          curp: currentData[CAPREPA_COOKIES.CURP],
          created_at: currentData[CAPREPA_COOKIES.ADMISSION_DATE],
          email: currentData[CAPREPA_COOKIES.EMAIL],

          branch: currentData[CAPREPA_COOKIES.BRANCH],
          brand: currentData[CAPREPA_COOKIES.BRAND],
          brand_id: currentData[CAPREPA_COOKIES.BRAND_ID],

          neighborhood_id: currentData[CAPREPA_COOKIES.NEIGHBORHOOD_ID],
          location: currentData[CAPREPA_COOKIES.LOCATION],
          location_num: currentData[CAPREPA_COOKIES.NUMBER],
          postal_code: currentData[CAPREPA_COOKIES.POSTAL_CODE],
        };

        yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'caprepaLogin', value: false } });
        yield put({ type: actionsTypesColab.SET_USERDATA, payload: currentUser });
        eventManager.emit('caprepa_user_login', currentUser);
      };
    } else {
      notifications.error(serverResponse.data.message);
    }
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'caprepaLogin', value: false } });
  } catch (error) {
    console.log(error.message);
  }
}
