import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Icon } from "semantic-ui-react";

const { Column } = Grid;
class ViewHeader extends Component {

  renderBackButton() {
    const { backRoute } = this.props;
    if (backRoute) {
      return (
        <Icon
          name="angle left"
          style={{ cursor: 'pointer' }}
          onClick={() => this.props.navigate(backRoute)}
        />
      );
    }
  };

  renderSubtitle() {
    const { subtitle } = this.props;

    if (subtitle) {
      return (
        <p style={{ opacity: 0.5, fontFamily: 'poppins-regular' }}>
          {subtitle}
        </p>
      )
    }

    return null;
  };

  render() {
    const { title, fullWidth } = this.props;

    if (fullWidth) {
      return (
        <Grid>
          <Column width={16}>
            <div style={{ marginTop: 30, marginBottom: 10 }}>
              <div style={{ fontSize: 20, float: 'left' }}>
                {this.renderBackButton()}
                <span style={{ fontFamily: 'poppins-bold' }}>
                  {title}
                </span>
              </div>
            </div>
          </Column>
        </Grid>
      );
    };

    return (
      <Grid>
        <Column width={4}>
          <div style={{ marginTop: 30, marginBottom: 10 }}>
            <div style={{ fontSize: 20, float: 'left' }}>
              {this.renderBackButton()}
              <span style={{ fontFamily: 'poppins-bold' }}>
                {title}
              </span>
              {this.renderSubtitle()}
            </div>
          </div>
        </Column>
        {this.props.children}
      </Grid>
    );
  };
};


ViewHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  fullWidth: PropTypes.bool,
  children: PropTypes.any,
  backRoute: PropTypes.string,
  navigate: PropTypes.func,
};

export { ViewHeader };
