import * as actionsTypes from './types';


const INITIAL_STATE = {
  selectedView: '',

  userData: null,
  branches: [],
  dashboardStats: null,
  sepomexInfo: {},

  customerData: null,
  customerFinancingData: null,
  bioData: null,
  saveCustomerScore: null,
  customerScore: null,

  financingData: null,
  financingByImei: null,
  financingBranchData: null,
  financingUserData: null,
  enrolledPhones: null,
  financingApplication: null,
  financingPayments: null,
  banksList: null,
  financingDetail: null,

  phonesData: [],
  phonesFullData: [],
  phoneData: null,
  phoneByImei: null, //? NUEVO
  phoneDetail: null,
  phonesDetail: [],
  phoneByScore: null,
  phonesByBrand: [],
  brands: [],
  warrantyBrands: [],
  storages: [],
  rear_cameras: [],
  frontal_cameras: [],
  SIMs: [],
  OSs: [],
  screens: [],
  services: [],
  categoryphonebybrandData: null,

  recoveriesData: [],
  recoveryphoneById: null,
  recoveryphoneByImei: null,
  recoveryEvidence: null,
  recoveryLogs: [],
  recoveriesPhoneData: null,

  allWarrantyStatus: null,
  invoiceTableByStatus: [],
  warrantyDetail: null,
  warrantyEvidence: null,
  warrantyLogs: [],
  branchWarranties: null,
  branchWarranty: null, //? NUEVO
  customerWarranty: null, //? NUEVO
  branchLogs: null,
  BranchWarrantyByImei: null,
  warrantySwapData: null,
  accesories: [],
  failures: [],
  symptoms: [],
  phoneByPk: null,
  phoneRecovery: null,

  loadings: {
    userData: false,
    branches: false,
    dashboardStats: false,
    sepomexInfo: false,
    gettingOTP: false,
    checkingOTP: false,

    customerData: false,
    customerFinancingData: false,
    savingCustomerData: false,
    savingApplication: false,
    saveBioData: false,
    customerScore: false,
    saveCustomerScore: false,

    savingAditionalForm: false,
    savingFinancingData: false,
    financingData: false,
    financingByImei: false,
    financingUserData: false,
    financingBranchData: false,
    enrolledPhones: false,
    financingApplication: false,
    savingFinancingApplication: false,
    financingPayments: false,
    savePayment: false,
    banksList: false,
    financingsTable: false,
    financingDetail: false,

    phonesData: false,
    phonesFullData: false,
    phoneData: false,
    phoneByImei: false, //? NUEVO
    phoneDetail: false,
    phonesDetail: false,
    phoneByScore: false,
    phonesByBrand: false,
    brands: false,
    warrantyBrands: false,
    storages: false,
    frontal_cameras: false,
    rear_cameras: false,
    SIMs: false,
    OSs: false,
    screens: false,
    services: false,
    savePhone: false,
    savingPhoneData: false,

    saveRecovery: false,
    recoveriesData: false,
    recoveryEvidence: false,
    modRecoveryStatus: false,
    recoveryLogs: false,
    updateRecoveryStatus: false,

    saveInvoice: false,
    allWarrantyStatus: false,
    invoiceTableByStatus: false,
    updateWarrantiesSatus: false,
    saveInvoiceSwap: false,
    warrantyDetail: false,
    warrantyEvidence: false,
    warrantyEvidenceUpload: false,
    warrantyEvidenceURL: false,
    warrantyLogs: false,
    branchWarranties: false,
    branchWarranty: false, //? NUEVO
    customerWarranty: false, //? NUEVO
    branchLogs: false,
    warrantySwapData: false,
    saveWarrantyBranch: false,
    accesories: false,
    failures: false,
    symptoms: false,
    updateWarranty: false,
  },
};


const setLoading = (state, action) => {
  const { prop, value } = action.payload;
  const loadings = { ...state.loadings };

  loadings[prop] = value;
  return { ...state, loadings };
};

const setSepomexInfo = (state, action) => {
  const { id, data } = action.payload;
  const copy = { ...state.sepomexInfo };
  copy[id] = data;
  return { ...state, sepomexInfo: copy };
}

const unsetAddSepomexInfo = (state) => {
  const copy = { ...state.sepomexInfo };
  copy.new = [];
  return { ...state, sepomexInfo: copy };
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  // ==================== COMMON ==========================
  case actionsTypes.SET_LOADING:
    return setLoading(state, action);
  case actionsTypes.SET_SELECTED_VIEW:
    return { ...state, selectedView: action.payload };


    // ==================== BRANCH ==========================
  case actionsTypes.SET_USERDATA:
    return { ...state, userData: action.payload };
  case actionsTypes.UNSET_USERDATA:
    return { ...state, userData: null };
  case actionsTypes.SET_BRANCHES:
    return { ...state, branches: action.payload };
  case actionsTypes.SET_DASHBOARD_STATS:
    return { ...state, dashboardStats: action.payload };
  case actionsTypes.UNSET_DASHBOARD_STATS:
    return { ...state, dashboardStats: null };
  case actionsTypes.SET_SEPOMEX_INFO:
    return setSepomexInfo(state, action);
  case actionsTypes.UNSET_ADD_SEPOMEX_INFO:
    return unsetAddSepomexInfo(state, action);
  case actionsTypes.UNSET_SEPOMEX_INFO:
    return { ...state, sepomexInfo: {} };


    // =================== CUSTOMERS =========================
  case actionsTypes.SET_CUSTOMER_DATA:
    return { ...state, customerData: action.payload };
  case actionsTypes.UNSET_CUSTOMER_DATA:
    return { ...state, customerData: null };
  case actionsTypes.SET_CUSTOMER_FINANCING_DATA:
    return { ...state, customerFinancingData: action.payload };
  case actionsTypes.SET_BIO_DATA:
    return { ...state, bioData: action.payload };
  case actionsTypes.UNSET_BIO_DATA:
    return { ...state, bioData: {} };
  case actionsTypes.SAVE_CUSTOMER_SCORE:
    return { ...state, saveCustomerScore: action.payload };
  case actionsTypes.SET_CUSTOMER_SCORE:
    return { ...state, customerScore: action.payload };


    // =================== FINANCING =========================
  case actionsTypes.SET_FINANCING_DATA:
    return { ...state, financingData: action.payload };

  case actionsTypes.UNSET_FINANCING_DATA:
    return { ...state, financingData: null };
  case actionsTypes.SET_FINANCING_BY_IMEI:
    return { ...state, financingByImei: action.payload };
  case actionsTypes.UNSET_FINANCING_BY_IMEI:
    return { ...state, financingByImei: null };
  case actionsTypes.SET_FINANCING_USER_DATA:
    return { ...state, financingUserData: action.payload };
  case actionsTypes.SET_ENRROLLED_PHONES:
    return { ...state, enrolledPhones: action.payload };
  case actionsTypes.SET_FINANCING_APPLICATION:
    return { ...state, financingApplication: action.payload };
  case actionsTypes.SET_FINANCING_PAYMENTS:
    return { ...state, financingPayments: action.payload };
  case actionsTypes.SAVE_PAYMENT:
    return { ...state, savePayment: action.payload };
  case actionsTypes.SET_BANKS_LIST:
    return { ...state, banksList: action.payload };
  case actionsTypes.SET_FINANCING_TABLE:
    return { ...state, financingsTable: action.payload };
  case actionsTypes.SET_FINANCING_DETAIL:
    return { ...state, financingDetail: action.payload };

    // ==================== PHONES ==========================
  case actionsTypes.SET_PHONES:
    return { ...state, phonesData: action.payload };
  case actionsTypes.SET_ALL_PHONES:
    return { ...state, phonesFullData: action.payload };
  case actionsTypes.SET_PHONE_DATA:
    return { ...state, phoneData: action.payload };
  case actionsTypes.SET_PHONE_BY_IMEI:
    return { ...state, phoneByImei: action.payload };
  case actionsTypes.UNSET_PHONE_BY_IMEI:
    return { ...state, phoneByImei: null };
  case actionsTypes.SET_PHONE_DETAIL:
    return { ...state, phoneDetail: action.payload };
  case actionsTypes.SET_PHONE_DETAIL_ARRAY:
    return { ...state, phonesDetail: action.payload };
  case actionsTypes.SET_PHONE_BY_SCORE:
    return { ...state, phoneByScore: action.payload };
  case actionsTypes.SET_PHONES_BY_BRAND:
    return { ...state, phonesByBrand: action.payload };
  case actionsTypes.SET_PHONES_BRANDS:
    return { ...state, brands: action.payload };
  case actionsTypes.SET_WARRANTY_PHONES_BRANDS:
    return { ...state, warrantyBrands: action.payload };
  case actionsTypes.SET_PHONES_STORAGES:
    return { ...state, storages: action.payload };
  case actionsTypes.SET_PHONES_REAR_CAMERAS:
    return { ...state, rear_cameras: action.payload };
  case actionsTypes.SET_PHONES_FRONTAL_CAMERAS:
    return { ...state, frontal_cameras: action.payload };
  case actionsTypes.SET_PHONES_SIMS:
    return { ...state, SIMs: action.payload };
  case actionsTypes.SET_PHONES_OS:
    return { ...state, OSs: action.payload };
  case actionsTypes.SET_PHONES_SCREENS:
    return { ...state, screens: action.payload };
  case actionsTypes.SET_PHONES_SERVICES:
    return { ...state, services: action.payload };
  case actionsTypes.SAVE_PHONE:
    return { ...state, savePhoneStatus: action.payload };
  case actionsTypes.SET_CAT_PHONE_BY_BRAND:
    return { ...state, categoryphonebybrandData: action.payload }


    // =================== RECOVERIES =========================
  case actionsTypes.SET_ALL_RECOVERIES:
    return { ...state, recoveriesData: action.payload };
  case actionsTypes.SET_RECOVERY_PHONE_BY_ID:
    return { ...state, recoveryphoneById: action.payload };
  case actionsTypes.SET_RECOVERY_PHONE_BY_IMEI:
    return { ...state, recoveryphoneByImei: action.payload };
  case actionsTypes.UNSET_RECOVERY_PHONE_BY_IMEI:
    return { ...state, recoveryphoneByImei: null };
  case actionsTypes.SET_RECOVERY_EVIDENCE:
    return { ...state, recoveryEvidence: action.payload };
  case actionsTypes.SET_RECOVERY_LOGS:
    return { ...state, recoveryLogs: action.payload };


    // =================== WARRANTIES =========================
  case actionsTypes.SET_ALL_WARRANTY_STATUS:
    return { ...state, allWarrantyStatus: action.payload };
  case actionsTypes.SET_INVOICE_TABLE_BY_STATUS:
    return { ...state, invoiceTableByStatus: action.payload };
  case actionsTypes.UNSET_INVOICE_TABLE_BY_STATUS:
    return { ...state, invoiceTableByStatus: [] };
  case actionsTypes.SET_WARRANTY_DETAIL:
    return { ...state, warrantyDetail: action.payload };
  case actionsTypes.UNSET_WARRANTY_DETAIL:
    return { ...state, warrantyDetail: null };
  case actionsTypes.SET_WARRANTY_EVIDENCE:
    return { ...state, warrantyEvidence: action.payload };
  case actionsTypes.UNSET_WARRANTY_EVIDENCE:
    return { ...state, warrantyEvidence: null };
  case actionsTypes.SET_WARRANTY_LOGS:
    return { ...state, warrantyLogs: action.payload };
  case actionsTypes.SET_BRANCH_WARRANTIES:
    return { ...state, branchWarranties: action.payload };
  case actionsTypes.UNSET_BRANCH_WARRANTIES:
    return { ...state, branchWarranties: null };
  case actionsTypes.SET_BRANCH_WARRANTY:
    return { ...state, branchWarranty: action.payload }; //? NUEVO
  case actionsTypes.UNSET_BRANCH_WARRANTY:
    return { ...state, branchWarranty: null }; //? NUEVO
  case actionsTypes.SET_CUSTOMER_WARRANTY:
    return { ...state, customerWarranty: action.payload }; //? NUEVO
  case actionsTypes.UNSET_CUSTOMER_WARRANTY:
    return { ...state, customerWarranty: null }; //? NUEVO
  case actionsTypes.SET_BRANCH_LOGS:
    return { ...state, branchLogs: action.payload };
  case actionsTypes.UNSET_BRANCH_LOGS:
    return { ...state, branchLogs: null };
  case actionsTypes.SAVE_WARRANTY_BRANCH:
    return { ...state, saveWarrantyBranch: action.payload };
  case actionsTypes.SET_BRANCH_WARRANTY_BY_IMEI:
    return { ...state, BranchWarrantyByImei: action.payload };
  case actionsTypes.UNSET_BRANCH_WARRANTY_BY_IMEI:
    return { ...state, BranchWarrantyByImei: null };
  case actionsTypes.SET_SWAP_WARRANTY:
    return { ...state, warrantySwapData: action.payload };
  case actionsTypes.UNSET_SWAP_WARRANTY:
    return { ...state, warrantySwapData: null };
  case actionsTypes.SET_ACCESORIES:
    return { ...state, accesories: action.payload };
  case actionsTypes.SET_FAILURES:
    return { ...state, failures: action.payload };
  case actionsTypes.SET_SYMPTOMS:
    return { ...state, symptoms: action.payload };

  default:
    return state;
  }
};
