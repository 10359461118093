import * as actionsTypes from './types';


const INITIAL_STATE = {
  selectedView: '',
  userData: null,
  caprepaUserData: null,
  loadings: {
    login: false,
    caprepaLogin: false
  },
};


const setLoading = (state, action) => {
  const { prop, value } = action.payload;
  const loadings = { ...state.loadings };

  loadings[prop] = value;
  return { ...state, loadings };
};


export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionsTypes.SET_LOADING:
    return setLoading(state, action);
  case actionsTypes.SET_SELECTED_VIEW:
    return { ...state, selectedView: action.payload };
  case actionsTypes.SET_USERDATA:
    return { ...state, userData: action.payload };
  case actionsTypes.SET_CAPREPA_USERDATA:
    return { ...state, caprepaUserData: action.payload };

  default:
    return state;
  }
};
