import React, { Component } from 'react';
import PropTypes from 'prop-types';
import formatter from '../utils/formatter';
import notifications from '../utils/notifications';
import { isEqual } from 'lodash';
import { Icon } from 'semantic-ui-react';
import { CONTAINERS_BOX_SHADOW, PALE_BLUE, WHITE } from '../colors';

const MAX_SIZE = 5120;

class FilePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovering: false,
      muted: true,
      play: true
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !isEqual(nextState, this.state) || !isEqual(clean(nextProps), clean(this.props));
  }

  onChangeFile(e) {
    if (e.target.files.length) {
      const currentFile = e.target.files[0];
      const size = currentFile.size / 1024;

      if (size > MAX_SIZE) {
        notifications.error(`El archivo super el limite de: ${this.getMaxSizeLabel()}`);
      } else {
        this.getBase64(currentFile, (base64) => {
          if (this.props.onChange) {
            this.props.onChange(base64);
          }
        });
      }
    }
  }

  onDelete() {
    this.inputRef.value = null;

    if (this.props.onChange) {
      this.props.onChange(null);
    }

    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  onClickHandle() {
    const { muted } = this.state;
    this.setState({ muted: !muted, play: true });
  }

  getMaxSizeLabel() {
    return `${MAX_SIZE / 1024} MB`;
  }

  getBase64(currentFile, callback) {
    const reader = new FileReader();
    let base64;

    reader.onloadend = () => {
      base64 = reader.result;
      callback(base64);
    };

    reader.readAsDataURL(currentFile);
  }

  render() {
    const { title, color, titleSize, subTitleSize, disabled, type } = this.props;
    const id = formatter.slugify(title);

    let accept = '*';

    switch (type) {
    case 'image':
      accept = "image/*"
      break;
    case 'pdf':
      accept = "/.pdf"
      break;
    default:
      break;
    }

    return (
      <label htmlFor={id}>
        <div style={{ backgroundColor: WHITE, boxShadow: CONTAINERS_BOX_SHADOW, marginBottom: 0, borderRadius: 10, color: color ? color : PALE_BLUE, cursor: disabled ? '' : 'pointer', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 35, marginBottom: 10 }}>
            <div style={{ paddingBottom: 10 }}>
              <Icon
                name="cloud upload"
                size="huge"
              />
            </div>

            <div>
              <span style={{ fontSize: titleSize ? titleSize :  16 }}>
                { disabled ? "Archivo/s Listos" : "Da click para cargar tu documento" }
              </span>
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ color: color ? color : PALE_BLUE, marginBottom: 12 }}>
            <span style={{ fontSize: subTitleSize ? subTitleSize : 14, fontFamily: 'poppins-regular' }}>
              La carga de documentos no puede superar los 5MB
            </span>
          </div>
        </div>

        <input
          disabled={disabled}
          ref={((inputRef) => { this.inputRef = inputRef; })}
          type="file"
          hidden
          accept={accept}
          name={id}
          id={id}
          style={{ visibility: false }}
          onChange={this.onChangeFile.bind(this)}
        />
      </label>
    );
  }
}


FilePicker.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  color: PropTypes.string,
  titleSize: PropTypes.number,
  subTitleSize: PropTypes.number,
  disabled: PropTypes.bool,
  type: PropTypes.any
};


export { FilePicker };
