export const SET_LOADING = 'admin/SET_LOADING';
export const SET_SELECTED_VIEW = 'admin/SET_SELECTED_VIEW';

export const GET_USER_BY_SESSION = 'admin/GET_USER_BY_SESSION';
export const SET_USERDATA = 'admin/SET_USERDATA';
export const UNSET_USERDATA = 'admin/UNSET_USERDATA';

export const GET_BRANCH_DATA = 'admin/GET_BRANCH_DATA';
export const SET_BRANCH_DATA = 'admin/SET_BRANCH_DATA';

export const GET_INVOICE_TABLE = 'admin/GET_INVOICE_TABLE';
export const SET_INVOICE_TABLE = 'admin/SET_INVOICE_TABLE';

export const SAVE_SUPPLIER = 'admin/SAVE_SUPPLIER';

export const SAVE_SUPPLIER_ADDRESS = 'admin/SAVE_SUPPLIER_ADDRESS';
export const DELETE_SUPPLIER_ADDRESS = 'admin/DELETE_SUPPLIER_ADDRESS';

export const SAVE_SUPPLIER_CONTACT = 'admin/SAVE_SUPPLIER_CONTACT';
export const DELETE_SUPPLIER_CONTACT = 'admin/DELETE_SUPPLIER_CONTACT';

export const UPDATE_SUPPLIER_ADDRESSES = 'admin/UPDATE_SUPPLIER_ADDRESSES';

export const UPDATE_SUPPLIER_CONTACTS = 'admin/UPDATE_SUPPLIER_CONTACTS';

export const GET_SUPPLIERS_TABLE = 'admin/GET_SUPPLIERS_TABLE';
export const SET_SUPPLIERS_TABLE = 'admin/SET_SUPPLIERS_TABLE';
export const UNSET_SUPPLIERS_TABLE = 'admin/UNSET_SUPPLIERS_TABLE';

export const GET_SUPPLIER_DETAIL = 'admin/GET_SUPPLIER_DETAIL';
export const SET_SUPPLIER_DETAIL = 'admin/SET_SUPPLIER_DETAIL';
export const UNSET_SUPPLIER_DETAIL = 'admin/UNSET_SUPPLIER_DETAIL';

export const SAVE_SUPPLIERS_TYPES = 'admin/SAVE_SUPPLIERS_TYPES';

export const GET_SUPPLIERS_TYPES_DETAIL = 'admin/GET_SUPPLIERS_TYPES_DETAIL';
export const SET_SUPPLIERS_TYPES_DETAIL = 'admin/SET_SUPPLIERS_TYPES_DETAIL';
export const UNSET_SUPPLIERS_TYPES_DETAIL = 'admin/UNSET_SUPPLIERS_TYPES_DETAIL';

export const GET_SUPPLIERS_TYPES = 'admin/GET_SUPPLIERS_TYPES';
export const SET_SUPPLIERS_TYPES = 'admin/SET_SUPPLIERS_TYPES';
export const UNSET_SUPPLIERS_TYPES = 'admin/UNSET_SUPPLIERS_TYPES';
export const DELETE_SUPPLIERS_TYPES = 'admin/DELETE_SUPPLIERS_TYPES';

export const GET_ALL_SUPPLIERS_CATEGORIES = 'admin/GET_ALL_SUPPLIERS_CATEGORIES';
export const SET_ALL_SUPPLIERS_CATEGORIES = 'admin/SET_ALL_SUPPLIERS_CATEGORIES';
export const UNSET_ALL_SUPPLIERS_CATEGORIES = 'admin/UNSET_ALL_SUPPLIERS_CATEGORIES';
export const SAVE_SUPPLIERS_CATEGORIES = 'admin/SAVE_SUPPLIERS_CATEGORIES';
export const ADD_SUPPLIERS_CATEGORIES = 'admin/ADD_SUPPLIERS_CATEGORIES';
export const DELETE_SUPPLIERS_CATEGORIES = 'admin/DELETE_SUPPLIERS_CATEGORIES';

export const GET_SUPPLIERS_CATEGORY_DETAIL = 'admin/GET_SUPPLIERS_CATEGORY_DETAIL';
export const SET_SUPPLIERS_CATEGORY_DETAIL = 'admin/SET_SUPPLIERS_CATEGORY_DETAIL';
export const UNSET_SUPPLIERS_CATEGORY_DETAIL = 'admin/UNSET_SUPPLIERS_CATEGORY_DETAIL';

export const GET_SUPPLIERS_CATEGORIES = 'admin/GET_SUPPLIERS_CATEGORIES';
export const SET_SUPPLIERS_CATEGORIES = 'admin/SET_SUPPLIERS_CATEGORIES';
export const UNSET_SUPPLIERS_CATEGORIES = 'admin/UNSET_SUPPLIERS_CATEGORIES';

export const GET_SUPPLIERS_SUBCATEGORIES = 'admin/GET_SUPPLIERS_SUBCATEGORIES';
export const SET_SUPPLIERS_SUBCATEGORIES = 'admin/SET_SUPPLIERS_SUBCATEGORIES';
export const UNSET_SUPPLIERS_SUBCATEGORIES = 'admin/UNSET_SUPPLIERS_SUBCATEGORIES';
export const SAVE_SUPPLIERS_SUBCATEGORIES = 'admin/SAVE_SUPPLIERS_SUBCATEGORIES';
export const ADD_SUPPLIERS_SUBCATEGORIES = 'admin/ADD_SUPPLIERS_SUBCATEGORIES';
export const DELETE_SUPPLIERS_SUBCATEGORIES = 'admin/DELETE_SUPPLIERS_SUBCATEGORIES';

export const GET_ALL_SUPPLIERS_SUBCATEGORIES = 'admin/GET_ALL_SUPPLIERS_SUBCATEGORIES';
export const SET_ALL_SUPPLIERS_SUBCATEGORIES = 'admin/SET_ALL_SUPPLIERS_SUBCATEGORIES';
export const UNSET_ALL_SUPPLIERS_SUBCATEGORIES = 'admin/UNSET_ALL_SUPPLIERS_SUBCATEGORIES';

export const GET_SUPPLIER_ADDRESSES = 'admin/GET_SUPPLIER_ADDRESSES';
export const SET_SUPPLIER_ADDRESSES = 'admin/SET_SUPPLIER_ADDRESSES';
export const ADD_SUPPLIER_ADDRESSES = 'admin/ADD_SUPPLIER_ADDRESSES';
export const REMOVE_SUPPLIER_ADDRESSES = 'admin/REMOVE_SUPPLIER_ADDRESSES';
export const UNSET_SUPPLIER_ADDRESSES = 'admin/UNSET_SUPPLIER_ADDRESSES';

export const GET_SEPOMEX_INFO = 'admin/GET_SEPOMEX_INFO';
export const SET_SEPOMEX_INFO = 'admin/SET_SEPOMEX_INFO';
export const UNSET_ADD_SEPOMEX_INFO = 'admin/UNSET_ADD_SEPOMEX_INFO';
export const UNSET_SEPOMEX_INFO = 'admin/UNSET_SEPOMEX_INFO';

export const GET_SUPPLIER_CONTACTS = 'admin/GET_SUPPLIER_CONTACTS';
export const SET_SUPPLIER_CONTACTS = 'admin/SET_SUPPLIER_CONTACTS';
export const ADD_SUPPLIER_CONTACTS = 'admin/ADD_SUPPLIER_CONTACTS';
export const REMOVE_SUPPLIER_CONTACTS = 'admin/REMOVE_SUPPLIER_CONTACTS';
export const UNSET_SUPPLIER_CONTACTS = 'admin/UNSET_SUPPLIER_CONTACTS';

export const SAVE_CONTACT_TYPE = 'admin/SAVE_CONTACT_TYPE';

export const GET_CONTACT_TYPES = 'admin/GET_CONTACT_TYPES';
export const SET_CONTACT_TYPES = 'admin/SET_CONTACT_TYPES';
export const UNSET_CONTACT_TYPES = 'admin/UNSET_CONTACT_TYPES';

export const GET_ADITIONAL_FORM = 'admin/GET_ADITIONAL_FORM';
export const SET_ADITIONAL_FORM = 'admin/SET_ADITIONAL_FORM';
export const UNSET_ADITIONAL_FORM = 'admin/UNSET_ADITIONAL_FORM';

export const GET_INVENTORY = 'admin/GET_INVENTORY';
export const SET_INVENTORY = 'admin/SET_INVENTORY';

export const GET_TIERS = 'admin/GET_TIERS';
export const SET_TIERS = 'admin/SET_TIERS';

export const SAVE_TIERS = 'admin/SAVE_TIERS';

export const GET_BRANDS = 'admin/GET_BRANDS';
export const SET_BRANDS = 'admin/SET_BRANDS';

export const GET_MODELS = 'admin/GET_MODELS';
export const SET_MODELS = 'admin/SET_MODELS';

export const GET_PHONE_DETAIL = 'admin/GET_PHONE_DETAIL';
export const SET_PHONE_DETAIL = 'admin/SET_PHONE_DETAIL';

export const SAVE_PHONE = 'admin/SAVE_PHONE';

export const DELETE_PHONE_IMAGE = 'admin/DELETE_PHONE_IMAGE';

export const SAVE_BRAND = 'admin/SAVE_BRAND';

// ===============================================
export const GET_PAYROLL_APPLICATIONS = 'admin/GET_PAYROLL_APPLICATIONS';
export const SET_PAYROLL_APPLICATIONS = 'admin/SET_PAYROLL_APPLICATIONS';

export const UPDATE_PAYROLL_APPLICATION = 'admin/UPDATE_PAYROLL_APPLICATION';
// ===============================================
export const GET_CAPITAL_APPLICATIONS = 'admin/GET_CAPITAL_APPLICATIONS';
export const SET_CAPITAL_APPLICATIONS = 'admin/SET_CAPITAL_APPLICATIONS';

export const UPDATE_CAPITAL_APPLICATION = 'admin/UPDATE_CAPITAL_APPLICATION';

// ===============================================
export const GET_CAPREPA_APPLICATIONS = 'admin/GET_CAPREPA_APPLICATIONS';
export const SET_CAPREPA_APPLICATIONS = 'admin/SET_CAPREPA_APPLICATIONS';

export const UPDATE_CAPREPA_APPLICATION = 'admin/UPDATE_CAPREPA_APPLICATION';
// ===============================================
export const GET_PAYROLL_PAYMENTS = 'admin/GET_PAYROLL_PAYMENTS';
export const SET_PAYROLL_PAYMENTS = 'admin/SET_PAYROLL_PAYMENTS';

export const UPDATE_PAYROLL_PAYMENTS = 'admin/UPDATE_PAYROLL_PAYMENTS';
export const SAVE_PAYROLL_PAYMENTS_BY_FILE = 'admin/SAVE_PAYROLL_PAYMENTS_BY_FILE';

export const GET_PHONES_STORAGES = 'admin/GET_PHONES_STORAGES';
export const SET_PHONES_STORAGES = 'admin/SET_PHONES_STORAGES';

export const GET_PHONES_FRONTAL_CAMERAS = 'admin/GET_PHONES_FRONTAL_CAMERAS';
export const SET_PHONES_FRONTAL_CAMERAS = 'admin/SET_PHONES_FRONTAL_CAMERAS';

export const GET_PHONES_REAR_CAMERAS = 'admin/GET_PHONES_REAR_CAMERAS';
export const SET_PHONES_REAR_CAMERAS = 'admin/SET_PHONES_REAR_CAMERAS';

export const GET_PHONES_SIMS = 'admin/GET_PHONES_SIMS';
export const SET_PHONES_SIMS = 'admin/SET_PHONES_SIMS';

export const GET_PHONES_OS = 'admin/GET_PHONES_OS';
export const SET_PHONES_OS = 'admin/SET_PHONES_OS';

export const GET_PHONES_SCREENS = 'admin/GET_PHONES_SCREENS';
export const SET_PHONES_SCREENS = 'admin/SET_PHONES_SCREENS';

export const GET_PHONES_SERVICES = 'admin/GET_PHONES_SERVICES';
export const SET_PHONES_SERVICES = 'admin/SET_PHONES_SERVICES';

export const GET_USER_DETAIL = 'admin/GET_USER_DETAIL';
export const SET_USER_DETAIL = 'admin/SET_USER_DETAIL';
export const UNSET_USER_DETAIL = 'admin/UNSET_USER_DETAIL';

export const GET_EMAIL_USER_DETAIL = 'admin/GET_EMAIL_USER_DETAIL';
export const SET_EMAIL_USER_DETAIL = 'admin/SET_EMAIL_USER_DETAIL';
export const UNSET_EMAIL_USER_DETAIL = 'admin/UNSET_EMAIL_USER_DETAIL';

export const GET_BRANDS_LIST = 'admin/GET_BRANDS_LIST';
export const SET_BRANDS_LIST = 'admin/SET_BRANDS_LIST';
export const UNSET_BRANDS_LIST = 'admin/UNSET_BRANDS_LIST';

export const SAVE_USER = 'admin/SAVE_USER';

export const SAVE_EMAIL_USER = 'admin/SAVE_EMAIL_USER';

export const GET_BRANCHES_LIST = 'admin/GET_BRANCHES_LIST';
export const SET_BRANCHES_LIST = 'admin/SET_BRANCHES_LIST';
export const UNSET_BRANCHES_LIST = 'admin/UNSET_BRANCHES_LIST';
export const SAVE_BRANCH = 'admin/SAVE_BRANCH';

export const GET_ROLES_LIST = 'admin/GET_ROLES_LIST';
export const SET_ROLES_LIST = 'admin/SET_ROLES_LIST';
export const UNSET_ROLES_LIST = 'admin/UNSET_ROLES_LIST';

export const GET_ROLE_DETAIL = 'admin/GET_ROLE_DETAIL';
export const SET_ROLE_DETAIL = 'admin/SET_ROLE_DETAIL';
export const UNSET_ROLE_DETAIL = 'admin/UNSET_ROLE_DETAIL';


export const GET_BRANCH_DETAIL = 'admin/GET_BRANCH_DETAIL';
export const SET_BRANCH_DETAIL = 'admin/SET_BRANCH_DETAIL';
export const UNSET_BRANCH_DETAIL = 'admin/UNSET_BRANCH_DETAIL';


export const GET_ALL_WARRANTY_STATUS = 'admin/GET_ALL_WARRANTY_STATUS';
export const SET_ALL_WARRANTY_STATUS = 'admin/SET_ALL_WARRANTY_STATUS';

export const SAVE_ROLE = 'admin/SAVE_ROLE';

export const GET_REGION_LIST = 'admin/GET_REGION_LIST';
export const SET_REGION_LIST = 'admin/SET_REGION_LIST';
export const UNSET_REGION_LIST = 'admin/UNSET_REGION_LIST';

export const GET_FINANCINGS_DATA = 'admin/GET_FINANCINGS_DATA';
export const SET_FINANCINGS_DATA = 'admin/SET_FINANCINGS_DATA';
export const UNSET_FINANCINGS_DATA = 'admin/UNSET_FINANCINGS_DATA';

export const SAVE_INVENTORY_BY_FILE = 'admin/SAVE_INVENTORY_BY_FILE';

export const GET_FINISHED_APPLICATIONS = 'admin/GET_FINISHED_APPLICATIONS';
export const SET_FINISHED_APPLICATIONS = 'admin/SET_FINISHED_APPLICATIONS';
export const UNSET_FINISHED_APPLICATIONS = 'admin/UNSET_FINISHED_APPLICATIONS';

export const UPDATE_CONTRACT_STATUS = 'admin/UPDATE_CONTRACT_STATUS';

export const GET_COLAB_APPLICATIONS = 'admin/GET_COLAB_APPLICATIONS';
export const SET_COLAB_APPLICATIONS = 'admin/SET_COLAB_APPLICATIONS';
