import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { CONTAINERS_BOX_SHADOW_LIGHT, DARK_BLUE, WHITE } from '../colors'
import * as XLSX from "xlsx";
import formatter from '../utils/formatter';
import moment from 'moment';

const lengths = {
  invoice: [
    10, 25, 17, 20, 15,
    15, 25, 20, 30, 15,
    15, 25, 20, 30, 20,
    20, 40, 30
  ],
  aditional_form: [
    30, 30, 30, 30, 30,
    30, 30, 30, 30, 30,
    30, 30
  ],
  payments: [
    10, 10, 30, 30, 30, 60
  ]
}

const buttonStyle = {
  fontFamily: 'poppins-bold',
  backgroundColor: WHITE,
  borderRadius: 20,
  boxShadow: CONTAINERS_BOX_SHADOW_LIGHT,
  color: DARK_BLUE
}
class ExportButtons extends Component {

  onExportClick() {
    const rawData = [ ...this.props.data];
    const book = XLSX.utils.book_new();
    let props = [];
    let table = [
      {
        A: "Folio",
        B: "Status",
        C: "# Financiamiento",
        D: "Sucursal",
        E: "Marca",

        F: "Modelo",
        G: "Titular",
        H: "IMEI",
        I: "Correo",
        J: "Telefono #1",

        K: "Telefono #2",
        L: "Falla",
        M: "Fecha de venta",
        N: "Tipo de Garantía",
        O: "Swap",

        P: "Fecha del Folio",
        Q: "Sitomas",
        R: "Tipo de Recuperacion"
      }
    ];

    rawData.forEach(currentData => {
      const failures = currentData.failure;
      let content = [];

      if (failures) {
        failures.forEach(item => {
          content.push(`${item.description} `)
        });
      };

      let additionalPhone = '-';
      if (currentData.additional_phone_wr) {
        additionalPhone = formatter.applyMask('(xxx) xxx xxxx', currentData.additional_phone_wr);
      };

      table.push({
        A: currentData.warranty_id,
        B: currentData.status_wr.name,
        C: currentData.financing_wr ? currentData.financing_wr.financing_id : 'N/A',
        D: `${currentData.branch_in_wr.postal_code} - ${currentData.branch_in_wr.name}`,
        E: currentData.phone.brand,

        F: currentData.phone.model,
        G: `${currentData.financing_wr ? currentData.financing_wr.name_customer : 'N/A'} ${currentData.financing_wr ? currentData.financing_wr.last_name_customer : ''}`,
        H: `${currentData.phone.imei}`,
        I: currentData.financing_wr ? currentData.financing_wr.email_customer : 'N/A',
        J: currentData.financing_wr ? formatter.applyMask('(xxx) xxx xxxx', currentData.financing_wr.phone_customer) : 'N/A',

        K: additionalPhone,
        L: `${content}`,
        M: currentData.financing_wr ? formatter.date(currentData.financing_wr.created_at) : 'N/A',
        N: currentData.type_wr,
        O: currentData.swap ? `${currentData.swap}` : 'N/A',

        P: formatter.date(currentData.created_at),
        Q: currentData.symptoms,
        R: currentData.recovery_type ? currentData.recovery_type : 'N/A'
      })
    });

    const sheet = XLSX.utils.json_to_sheet(table, { skipHeader: true });

    lengths.invoice.forEach((col) => {
      props.push({
        width: col,
        textAlign: 'center'
      });
    });

    sheet["!cols"] = props;

    XLSX.utils.book_append_sheet(book, sheet, "Garantias");

    XLSX.writeFile(book, "Garantias.xlsx");
  };

  onExportInventoryClick() {
    const rawData = [ ...this.props.data];
    const book = XLSX.utils.book_new();
    let props = [];
    let table = [
      {
        A: "SKU",
        B: "Marca",
        C: "Nombre",
        D: "Color",
        E: "Color específico",
        F: "Precio",
        G: "Precio final",
        H: "Precio de empleado",
        I: "Gama",
        J: "Stock",
        K: "Nivel de riesgo",
      }
    ];

    rawData.forEach(currentData => {
      table.push({
        A: currentData.sku,
        B: currentData.brand,
        C: currentData.name,
        D: currentData.color_cat,
        E: currentData.color_name,
        F: currentData.price,
        G: currentData.price_final,
        H: currentData.employee_price,
        I: currentData.tier,
        J: currentData.stock,
        K: currentData.risk_level,
      })
    });

    const sheet = XLSX.utils.json_to_sheet(table, { skipHeader: true });

    lengths.invoice.forEach((col) => {
      props.push({
        width: col,
        textAlign: 'center'
      });
    });

    sheet["!cols"] = props;

    XLSX.utils.book_append_sheet(book, sheet, "Inventario");

    XLSX.writeFile(book, "Inventario.xlsx");
  };

  onExportAditionalClick() {
    const rawData = [ ...this.props.data];
    const book = XLSX.utils.book_new();

    let props = [];
    let table = [
      {
        A: "Nombre",
        B: "Apellido",
        C: "Teléfono principal",
        D: "Teléfono de trabajo",
        E: "Teléfono de casa",

        F: "Teléfono Referido",
        G: "Nombre Familiar Directo",
        H: "Parentesco",
        I: "Teléfono Familiar Directo",
        J: "Nombre Familiar o conocido",

        K: "Parentesco",
        L: "Teléfono Familiar o conocido",
      }
    ];

    rawData.forEach(currentData => {
      table.push({
        A: currentData.name,
        B: currentData.last_name,
        C: formatter.applyMask('(xxx) xxx xxxx', currentData.phone ? currentData.phone : '000000000'),
        D: formatter.applyMask('(xxx) xxx xxxx', currentData.phone_work ? currentData.phone_work : '000000000'),
        E: formatter.applyMask('(xxx) xxx xxxx', currentData.phone_home ? currentData.phone_home : '000000000'),

        F: formatter.applyMask('(xxx) xxx xxxx', currentData.phone_referral ? currentData.phone_referral : '000000000'),
        G: currentData.family_name,
        H: currentData.family_type,
        I: formatter.applyMask('(xxx) xxx xxxx', currentData.family_phone ? currentData.family_phone : '000000000'),
        J: currentData.associate_name,

        K: currentData.associate_type,
        L: formatter.applyMask('(xxx) xxx xxxx', currentData.associate_phone ? currentData.associate_phone : '000000000'),
      })
    });

    const sheet = XLSX.utils.json_to_sheet(table, { skipHeader: true });

    lengths.aditional_form.forEach((col) => {
      props.push({
        width: col,
        textAlign: 'center'
      });
    });

    sheet["!cols"] = props;

    XLSX.utils.book_append_sheet(book, sheet, "Contactos");

    XLSX.writeFile(book, "Contactos.xlsx");
  }

  onExportPaymentsCaprepaClick(){
    const rawData = [ ...this.props.data];
    const book = XLSX.utils.book_new();

    let props = [];
    let table = [
      {
        A: 'ID ',
        B: 'Unidad de Negocio ',
        C: "# Empleado",
        D: "Nombre",
        E: "Apellido",
        F: "Pago Quincenal",
        G: "Saldo Pendiente",
        H: "Fecha correspondiente",
        I: "Se hace pago (Marcar con 1 si se hace y 0 si no se hace pago)",
      }
    ];

    rawData.forEach(currentData => {
      const part_payment = currentData.mount;

      let extra = 0;

      if (currentData.has_warranty_1 && currentData.has_warranty_1.length && currentData.val_first_pay === 0) {
        extra = currentData.has_warranty_1[0].warranty_value;
      }

      table.push({
        A: currentData.employee_financing_id,
        B: currentData.business_unit,
        C: currentData.employee.employee_id,
        D: `${formatter.capitalize(currentData.employee.first_name)} ${formatter.capitalize(currentData.employee.middle_name)}`,
        E: `${formatter.capitalize(currentData.employee.last_name)} ${formatter.capitalize(currentData.employee.second_last_name)}`,
        F: (part_payment+extra).toFixed(0),
        G: (currentData.pending_mount).toFixed(0),
        H: this.props.dateValue,
        I: '',
      })
    });

    const sheet = XLSX.utils.json_to_sheet(table, { skipHeader: true });

    lengths.aditional_form.forEach((col) => {
      props.push({
        width: col,
        textAlign: 'center'
      });
    });

    sheet["!cols"] = props;

    XLSX.utils.book_append_sheet(book, sheet, "Contactos");

    XLSX.writeFile(book, `Relacion_pagos_paycel_${moment().format('DD_MM_YYYY')}.xlsx`);

  }

  render() {
    const { type, data } = this.props;

    if (!data) {
      return null
    }

    let button = (
      <Button
        style={{...buttonStyle, marginRight: 10}}
        onClick={() => this.onExportClick()}
      >
        <Icon name="file"/>
          DESCARGAR EXCEL
      </Button>
    );

    if (type && data && data) {
      switch (type) {
      case 'aditional-form':
        button = (
          <Button
            style={{...buttonStyle, marginRight: 10}}
            onClick={() => this.onExportAditionalClick()}
          >
            <Icon name="file"/>
              DESCARGAR EXCEL
          </Button>
        );
        break;
      case 'payments-caprepa':
        button = (
          <Button
            style={{...buttonStyle, marginRight: 10}}
            onClick={() => this.onExportPaymentsCaprepaClick()}
          >
            <Icon name="file excel"/>
              DESCARGAR EXCEL
          </Button>
        );
        break;
      case 'supplychain-inventory':
        button = (
          <Button
            style={{...buttonStyle, marginRight: 10}}
            onClick={() => this.onExportInventoryClick()}
          >
            <Icon name="file excel"/>
              DESCARGAR EXCEL
          </Button>
        );
        break;


      default:
        break;
      }
    }

    return (
      <div>
        { button }
      </div>
    )
  }
};


ExportButtons.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string,
  dateValue: PropTypes.string
};

export { ExportButtons };
