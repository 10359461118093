import { call, put } from 'redux-saga/effects';
import * as calls from '../calls/index.js';
import * as actionsTypes from '../../store/types';
import notifications from '../../../../utils/notifications';
import eventManager from '../../../../utils/eventManager';
import formatter from '../../../../utils/formatter.js';


function fixFailures(failures){
  const failuresToShow = failures.map(element => {
    return {
      id: element.failure_id,
      text: formatter.capitalize(element.name),
      value: element.failure_id
    };
  });

  return failuresToShow;
};

function fixSymptoms(symptoms){
  const symptomsToShow = symptoms.map(element => {
    return {
      id: element.idSymptoms,
      text: formatter.capitalize(element.name),
      value: element.idSymptoms,
    };
  });
  return symptomsToShow;
};

function fixAccesories(accesories){
  const accesoriesToShow = accesories.map(element => {
    return {
      id: element.accesory_id,
      text: formatter.capitalize(element.name),
      value: element.accesory_id,
    };
  });
  return accesoriesToShow;
};


export function* saveInvoice(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveInvoice', value: true } });

    const request = calls.saveInvoice(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid && serverResponse.message) {
      notifications.error(serverResponse.message);
    } else if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveInvoice', value: false } });
      eventManager.emit('saved_invoice', serverResponse.data);
    } else {
      notifications.error('Error al guardar folio');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveInvoice', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getAllWarrantyStatus(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'allWarrantyStatus', value: true } });

    const request = calls.getAllWarrantyStatus(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'allWarrantyStatus', value: false } });
      yield put({ type: actionsTypes.SET_ALL_WARRANTY_STATUS, payload: serverResponse.data });
    } else {
      notifications.error('Status no encontrados');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'allWarrantyStatus', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getInvoiceTableByStatus(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'invoiceTableByStatus', value: true } });

    const request = calls.getInvoiceTableByStatus(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'invoiceTableByStatus', value: false } });
      yield put({ type: actionsTypes.SET_INVOICE_TABLE_BY_STATUS, payload: serverResponse.data });
    } else {
      notifications.error('Tabla no encontrada');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'invoiceTableByStatus', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* changeInvoiceStatus(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateWarrantiesSatus', value: true } });

    const request = calls.changeInvoiceStatus(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateWarrantiesSatus', value: false } });
      eventManager.emit('updated_invoices');
      notifications.success('Folio de Garantía actualizado correctamente.');
    } else {
      notifications.error('Error al cambiar folios de status');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateWarrantiesSatus', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* saveInvoiceSwap(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveInvoiceSwap', value: true } });

    const request = calls.saveInvoiceSwap(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveInvoiceSwap', value: false } });
      eventManager.emit('saved_invoice_swap');

    } else {
      notifications.error('Error al realizar el SWAP');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveInvoiceSwap', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getWarrantyDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyDetail', value: true } });

    const request = calls.getWarrantyDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyDetail', value: false } });
      if (!Array.isArray(serverResponse.data)) {
        yield put({ type: actionsTypes.SET_WARRANTY_DETAIL, payload: serverResponse.data });
      } else {
        yield put({ type: actionsTypes.UNSET_WARRANTY_DETAIL });
        notifications.error('Detalles no encontrados');
      }
    } else {
      notifications.error('Detalles no encontrados');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyDetail', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getWarrantyEvidence(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidence', value: true } });

    const request = calls.getWarrantyEvidence(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidence', value: false } });
      yield put({ type: actionsTypes.SET_WARRANTY_EVIDENCE, payload: serverResponse.data });
      eventManager.emit('warranty_evidence', serverResponse.data);

    } else {
      notifications.error('Documentos no encontrados');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidence', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* uploadWarrantyEvidence(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidenceUpload', value: true } });

    const request = calls.uploadWarrantyEvidence(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidenceUpload', value: false } });
      eventManager.emit('uploaded_warranty_file' );

    } else {
      notifications.error('Error al subir documento');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidenceUpload', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getWarrantyEvidenceUrl(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidenceURL', value: true } });

    const request = calls.getWarrantyEvidenceUrl(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidenceURL', value: false } });
      eventManager.emit('warranty_evidence_url', serverResponse.data);
    } else {
      notifications.error('Url no encontrada');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyEvidenceURL', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getWarrantyLogs(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyLogs', value: true } });

    const request = calls.getWarrantyLogs(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyLogs', value: false } });
      yield put({ type: actionsTypes.SET_WARRANTY_LOGS, payload: serverResponse.data });

    } else {
      notifications.error('Logs no encontrados');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantyLogs', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getBranchWarranties(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchWarranties', value: true } });

    const request = calls.getBranchWarranties(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchWarranties', value: false } });
      yield put({ type: actionsTypes.SET_BRANCH_WARRANTIES, payload: serverResponse.data });
    } else {
      notifications.error('Documentos no encontrados');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchWarranties', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getBranchWarranty(action) { //? NUEVO
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchWarranty', value: true } });

    const request = calls.getBranchWarranty(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchWarranty', value: false } });
      yield put({ type: actionsTypes.SET_BRANCH_WARRANTY, payload: serverResponse.data });
    } else {
      notifications.error(serverResponse.message);
      eventManager.emit('registrable-imei')
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchWarranty', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getCustomerWarranty(action) { //? NUEVO
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'customerWarranty', value: true } });

    const request = calls.getCustomerWarranty(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid && serverResponse.message) {
      notifications.warning(serverResponse.message)
      eventManager.emit('existing-customer-warranty')
    } else if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'customerWarranty', value: false } });
      yield put({ type: actionsTypes.SET_CUSTOMER_WARRANTY, payload: serverResponse.data });
    } else {
      notifications.error(serverResponse.message);
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'customerWarranty', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getBranchLogs(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchLogs', value: true } });

    const request = calls.getBranchLogs(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchLogs', value: false } });
      yield put({ type: actionsTypes.SET_BRANCH_LOGS, payload: serverResponse.data });
    } else {
      notifications.error('Documentos no encontrados');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branchLogs', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* saveWarrantyBranch(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveWarrantyBranch', value: true } });

    const request = calls.saveWarrantyBranch(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveWarrantyBranch', value: false } });
      eventManager.emit('branch-warranty-saved')
      notifications.success('Se ha guardado la garantía de sucursal')
    } else {
      notifications.error('Algo sucedio intenta nuevamente');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'saveWarrantyBranch', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getBranchWarrantyByImei(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'BranchWarrantyByImei', value: true } });

    const request = calls.getBranchWarrantyByImei(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'BranchWarrantyByImei', value: false } });
      yield put({ type: actionsTypes.SET_BRANCH_WARRANTY_BY_IMEI, payload: serverResponse.data });
      eventManager.emit('enable-button');
    } else {
      notifications.error(serverResponse.message);
      eventManager.emit('created-new-phone');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'BranchWarrantyByImei', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getWarrantySwap(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantySwapData', value: true } });

    const request = calls.getWarrantySwap(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantySwapData', value: false } });
      yield put({ type: actionsTypes.SET_SWAP_WARRANTY, payload: serverResponse.data });
    } else {
      notifications.error('Garantía no encontrada');
      yield put({ type: actionsTypes.UNSET_SWAP_WARRANTY, payload: serverResponse.data });
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'warrantySwapData', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getAccesories(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'accesories', value: true } });

    const request = calls.getAccesories(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      const fixedArray = fixAccesories(serverResponse.data);
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'accesories', value: false } });
      yield put({ type: actionsTypes.SET_ACCESORIES, payload: fixedArray });
    } else {
      notifications.error('Accesorios no encontrados');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'accesories', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getFailures(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'failures', value: true } });

    const request = calls.getFailures(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      const fixedArray = fixFailures(serverResponse.data);
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'failures', value: false } });
      yield put({ type: actionsTypes.SET_FAILURES, payload: fixedArray });
    } else {
      notifications.error('Fallas no encontradas');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'failures', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getSymptoms(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'symptoms', value: true } });

    const request = calls.getSymptoms(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      const fixedArray = fixSymptoms(serverResponse.data);
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'symptoms', value: false } });
      yield put({ type: actionsTypes.SET_SYMPTOMS, payload: fixedArray });
    } else {
      notifications.error('Sintomas no encontrados');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'symptoms', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* updateWarrantyDetail(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateWarranty', value: true } });

    const request = calls.updateWarrantyDetail(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateWarranty', value: false } });
      eventManager.emit('updated_warranty')
    } else {
      notifications.error('Detalles no encontrados');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'updateWarranty', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};
