import React, { Component } from "react";
import PropTypes from "prop-types";
import formatter from "../../utils/formatter.js"
import "./index.css"
import { Divider, Icon } from "semantic-ui-react";
import { DARK_BLUE } from "../../colors.js";

class TimeLine extends Component {
  // ------------------------
  // ---- ON HANDLERS   ----
  // ------------------------

  // ------------------------
  // ---- render methods ----
  // ------------------------

  renderListItems() {
    const { data } = this.props;
    const content = data.map(item => {
      return (
        <li key={item.warranty_log_id} className="listItem">
          <div>
            <span className={'circle-timeline'}></span>
            <div className='timeline-content'>
              <label className="timeline-label">{'Folio #'+item.warranty_id+' - '+item.stage}</label>
              <p className="timeline-text">
                <Icon name="time" style={{ color: DARK_BLUE }} />
                {formatter.longDate(item.created_at)}
              </p>
            </div>
          </div>
          <Divider />
        </li>
      );
    })

    return content;
  }

  render() {
    return (
      <div style={{ height: 350, overflowY: "auto" }}>
        <ul id='timeline'>
          {this.renderListItems()}
        </ul>
      </div>
    )
  }
}


TimeLine.propTypes = {
  data: PropTypes.array
};

export { TimeLine };
