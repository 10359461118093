export const SET_LOADING = 'warranty/SET_LOADING';
export const SET_SELECTED_VIEW = 'warranty/SET_SELECTED_VIEW';

export const GET_USER_BY_SESSION = 'warranty/GET_USER_BY_SESSION';
export const SET_USERDATA = 'warranty/SET_USERDATA';
export const UNSET_USERDATA = 'warranty/UNSET_USERDATA';

export const GET_BRANCH_DATA = 'warranty/GET_BRANCH_DATA';
export const SET_BRANCH_DATA = 'warranty/SET_BRANCH_DATA';

export const GET_INVOICE_TABLE = 'warranty/GET_INVOICE_TABLE';
export const SET_INVOICE_TABLE = 'warranty/SET_INVOICE_TABLE';
export const UNSET_INVOICE_TABLE = 'warranty/UNSET_INVOICE_TABLE';

export const GET_INVOICE_TABLE_BY_STATUS = 'warranty/GET_INVOICE_TABLE_BY_STATUS';
export const SET_INVOICE_TABLE_BY_STATUS = 'warranty/SET_INVOICE_TABLE_BY_STATUS';
export const UNSET_INVOICE_TABLE_BY_STATUS = 'warranty/UNSET_INVOICE_TABLE_BY_STATUS';

export const GET_ALL_WARRANTY_STATUS = 'warranty/GET_ALL_WARRANTY_STATUS';
export const SET_ALL_WARRANTY_STATUS = 'warranty/SET_ALL_WARRANTY_STATUS';

export const GET_WARRANTY_DETAIL = 'warranty/GET_WARRANTY_DETAIL';
export const SET_WARRANTY_DETAIL = 'warranty/SET_WARRANTY_DETAIL';
export const UNSET_WARRANTY_DETAIL = 'warranty/UNSET_WARRANTY_DETAIL';

export const UPDATE_ALL_WARRANTY_DETAIL = 'warranty/UPDATE_ALL_WARRANTY_DETAIL';

export const GET_DROPDOWN_WARRANTY_STATUS = 'warranty/GET_DROPDOWN_WARRANTY_STATUS';
export const SET_DROPDOWN_WARRANTY_STATUS = 'warranty/SET_DROPDOWN_WARRANTY_STATUS';

export const UPDATE_WARRANTIES_STATUS = 'warranty/UPDATE_WARRANTIES_STATUS';
export const UPDATE_WARRANTIES_STATUS_DOC = 'warranty/UPDATE_WARRANTIES_STATUS_DOC';

export const GET_FAILURES = 'warranty/GET_FAILURES';
export const SET_FAILURES = 'warranty/SET_FAILURES';

export const GET_SYMPTOMS = 'warranty/GET_SYMPTOMS';
export const SET_SYMPTOMS = 'warranty/SET_SYMPTOMS';

export const GET_ACCESORIES = 'warranty/GET_ACCESORIES';
export const SET_ACCESORIES = 'warranty/SET_ACCESORIES';

export const GET_BRANCHES = 'warranty/GET_BRANCHES';
export const SET_BRANCHES = 'warranty/SET_BRANCHES';

export const GET_WARRANTY_EVIDENCE = 'warranty/GET_WARRANTY_EVIDENCE';
export const SET_WARRANTY_EVIDENCE = 'warranty/SET_WARRANTY_EVIDENCE';

export const GET_WARRANTY_LOGS = 'warranty/GET_WARRANTY_LOGS';
export const SET_WARRANTY_LOGS = 'warranty/SET_WARRANTY_LOGS';

export const SAVE_WARRANTY_EVIDENCE = 'warranty/SAVE_WARRANTY_EVIDENCE';
export const SAVE_WARRANTY_QUOTE = 'warranty/SAVE_WARRANTY_QUOTE';
export const SAVE_WARRANTY_DIAGNOSIS = 'warranty/SAVE_WARRANTY_DIAGNOSIS';

export const GET_WARRANTY_EVIDENCE_URL = 'warranty/GET_WARRANTY_EVIDENCE_URL';

export const GET_PHONE_DATA = 'warranty/GET_PHONE_DATA';
export const SET_PHONE_DATA = 'warranty/SET_PHONE_DATA';
export const UNSET_PHONE_DATA = 'warranty/UNSET_PHONE_DATA';

export const SAVE_INVOICE_SWAP = 'warranty/SAVE_INVOICE_SWAP';

export const GET_FINANCING_DATA = 'warranty/GET_FINANCING_DATA';
export const SET_FINANCING_DATA = 'warranty/SET_FINANCING_DATA';
export const UNSET_FINANCING_DATA = 'warranty/UNSET_FINANCING_DATA';

export const GET_DASHBOARD_STATS = 'warranty/GET_DASHBOARD_STATS';
export const SET_DASHBOARD_STATS = 'warranty/SET_DASHBOARD_STATS';

export const GET_ROLES_LIST = 'warranty/GET_ROLES_LIST';
export const SET_ROLES_LIST = 'warranty/SET_ROLES_LIST';
export const UNSET_ROLES_LIST = 'warranty/UNSET_ROLES_LIST';

export const SAVE_USER = 'warranty/SAVE_USER';

export const GET_USERS_LIST = 'warranty/GET_USERS_LIST';
export const SET_USERS_LIST = 'warranty/SET_USERS_LIST';
export const UNSET_USERS_LIST = 'warranty/UNSET_USERS_LIST';

export const GET_USER_DETAIL = 'warranty/GET_USER_DETAIL';
export const SET_USER_DETAIL = 'warranty/SET_USER_DETAIL';
export const UNSET_USER_DETAIL = 'warranty/UNSET_USER_DETAIL';

export const SAVE_ROLE = 'warranty/SAVE_ROLE';

export const GET_ROLE_DETAIL = 'warranty/GET_ROLE_DETAIL';
export const SET_ROLE_DETAIL = 'warranty/SET_ROLE_DETAIL';
export const UNSET_ROLE_DETAIL = 'warranty/UNSET_ROLE_DETAIL';

export const GET_BRANCHES_LIST = 'warranty/GET_BRANCHES_LIST';
export const SET_BRANCHES_LIST = 'warranty/SET_BRANCHES_LIST';
export const UNSET_BRANCHES_LIST = 'warranty/UNSET_BRANCHES_LIST';

export const GET_BRANCH_DETAIL = 'warranty/GET_BRANCH_DETAIL';
export const SET_BRANCH_DETAIL = 'warranty/SET_BRANCH_DETAIL';
export const UNSET_BRANCH_DETAIL = 'warranty/UNSET_BRANCH_DETAIL';

export const SAVE_BRANCH = 'warranty/SAVE_BRANCH';

export const GET_INVENTORY_LIST = 'warranty/GET_INVENTORY_LIST';
export const SET_INVENTORY_LIST = 'warranty/SET_INVENTORY_LIST';
export const UNSET_INVENTORY_LIST = 'warranty/UNSET_INVENTORY_LIST';

export const GET_ADITIONAL_FORM = 'warranty/GET_ADITIONAL_FORM';
export const SET_ADITIONAL_FORM = 'warranty/SET_ADITIONAL_FORM';
export const UNSET_ADITIONAL_FORM = 'warranty/UNSET_ADITIONAL_FORM';

export const GET_GESTION_DETAIL = 'warranty/GET_GESTION_DETAIL';
export const SET_GESTION_DETAIL = 'warranty/SET_GESTION_DETAIL';
export const UNSET_GESTION_DETAIL = 'warranty/UNSET_GESTION_DETAIL';

export const SAVE_GESTION_LOG = 'warranty/SAVE_GESTION_LOG';

export const SET_ALL_RECOVERIES_PHONES = 'warranty/SET_ALL_RECOVERIES_PHONES';
export const GET_ALL_RECOVERIES_PHONES = 'warranty/GET_ALL_RECOVERIES_PHONES';

export const GET_PHONES_BRANDS = 'warranty/GET_PHONES_BRANDS';
export const SET_PHONES_BRANDS = 'warranty/SET_PHONES_BRANDS';

export const GET_PHONE_NAMES_BY_BRAND = 'warranty/GET_PHONE_NAMES_BY_BRAND';
export const SET_PHONE_NAMES_BY_BRAND = 'warranty/SET_PHONE_NAMES_BY_BRAND';

export const GET_RECOVERY_PHONE_BY_ID = 'warranty/GET_RECOVERY_PHONE_BY_ID';
export const SET_RECOVERY_PHONE_BY_ID = 'warranty/SET_RECOVERY_PHONE_BY_ID';

export const GET_RECOVERY_EVIDENCE = 'warranty/GET_RECOVERY_EVIDENCE';
export const SET_RECOVERY_EVIDENCE = 'warranty/SET_RECOVERY_EVIDENCE';

export const GET_RECOVERY_EVIDENCE_URL = 'warranty/GET_RECOVERY_EVIDENCE_URL';

export const SAVE_RECOVERY_EVIDENCE = 'warranty/SAVE_RECOVERY_EVIDENCE';

export const GET_RECOVERY_LOGS = 'warranty/GET_RECOVERY_LOGS';
export const SET_RECOVERY_LOGS = 'warranty/SET_RECOVERY_LOGS';

export const UPDATE_RECOVERY_STATUS = 'warranty/UPDATE_RECOVERY_STATUS';

export const SAVE_RECOVERY_QUOTE = 'warranty/SAVE_RECOVERY_QUOTE';

export const MOD_RECOVERY_STATUS = 'warranty/MOD_RECOVERY_STATUS';

export const GET_ALL_BRANCH_WARRANTIES = 'warranty/GET_ALL_BRANCH_WARRANTIES';
export const SET_ALL_BRANCH_WARRANTIES = 'warranty/SET_ALL_BRANCH_WARRANTIES';
export const GET_COLAB_APPLICATIONS = 'warranty/GET_COLAB_APPLICATIONS';
export const SET_COLAB_APPLICATIONS = 'warranty/SET_COLAB_APPLICATIONS';

export const UPDATE_CAPREPA_APPLICATION = 'warranty/UPDATE_CAPREPA_APPLICATION';

export const GET_SEPOMEX_INFO = 'warranty/GET_SEPOMEX_INFO';
export const SET_SEPOMEX_INFO = 'warranty/SET_SEPOMEX_INFO';

export const GET_PHONES_BY_BRAND = 'branch/GET_PHONES_BY_BRAND';
export const SET_PHONES_BY_BRAND = 'branch/SET_PHONES_BY_BRAND';
export const UNSET_PHONES_BY_BRAND = 'branch/UNSET_PHONES_BY_BRAND';

export const UPDATE_RECOVERY_STATUS_MULTIPLE = 'warranty/UPDATE_RECOVERY_STATUS_MULTIPLE';

export const GET_BRANCH_WARRANTY_DETAIL_BY_ID = 'warranty/GET_BRANCH_WARRANTY_DETAIL_BY_ID';
export const SET_BRANCH_WARRANTY_DETAIL_BY_ID = 'warranty/SET_BRANCH_WARRANTY_DETAIL_BY_ID';

export const GET_BRANCH_WARRANTY_LOGS_BY_ID = 'warranty/GET_BRANCH_WARRANTY_LOGS_BY_ID';
export const SET_BRANCH_WARRANTY_LOGS_BY_ID = 'warranty/SET_BRANCH_WARRANTY_LOGS_BY_ID';

export const GET_BRANCH_WARRANTY_DOCUMENTS_EVIDENCE_BY_ID = 'warranty/GET_BRANCH_WARRANTY_DOCUMENTS_EVIDENCE_BY_ID';
export const SET_BRANCH_WARRANTY_DOCUMENTS_EVIDENCE_BY_ID = 'warranty/SET_BRANCH_WARRANTY_DOCUMENTS_EVIDENCE_BY_ID';

export const SAVE_BRANCH_WARRANTY_EVIDENCE = 'warranty/SAVE_BRANCH_WARRANTY_EVIDENCE';

export const SAVE_BRANCH_WARRANTY_QUOTE = 'warranty/SAVE_BRANCH_WARRANTY_QUOTE';

export const UPDATE_BRANCH_WARRANTY_STATUS = 'warranty/UPDATE_BRANCH_WARRANTY_STATUS';

export const UPDATE_BRANCH_WARRANTY_STATUS_MULTIPLE = 'warranty/UPDATE_BRANCH_WARRANTY_STATUS_MULTIPLE';

export const SET_SELECTED_FIELDS_MASTER = 'warranty/SET_SELECTED_FIELDS_MASTER';
export const SET_SELECTED_PAGE_MASTER = 'warranty/SET_SELECTED_PAGE_MASTER';
