import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Image, Segment, Placeholder, PlaceholderImage, PlaceholderLine } from 'semantic-ui-react';
import formatter from '../utils/formatter';
import { CONTAINERS_BOX_SHADOW, GRADIENT_TO_BOTTOM_DARK_BLUE, GRADIENT_TO_BOTTOM_DARK_RED, GRADIENT_TO_BOTTOM_LIGHT_PURPLE, LIGHT_GRAY, WHITE } from '../colors';

class ItemsCaroussel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTopeLeft: false,
      isTopeRight: false,
    };
  }

  onClickPrev() {
    const scroll = document.getElementById('containerImages');
    const currentSizeScroll = scroll.scrollLeft;

    if (currentSizeScroll === 0) {
      this.setState({ isTopeLeft: true });
    } else {
      this.setState({ isTopeRight: false });
      scroll.scrollLeft -= 300;
    }
  }

  onClickNext() {
    const scroll = document.getElementById('containerImages');
    const currentSizeScroll = scroll.scrollLeft;
    const scrollMax = scroll.scrollWidth - scroll.clientWidth;

    if (currentSizeScroll >= scrollMax) {
      this.setState({ isTopeRight: true });
    } else {
      this.setState({ isTopeLeft: false });
      scroll.scrollLeft += 300;
    }
  }

  renderUsedLabel(item) {
    const { mobile } = this.props;

    if (item.condition !== 'used') {
      return null;
    }

    if (mobile) {
      return (
        <div
          style={{
            backgroundColor: LIGHT_GRAY,
            position: 'absolute',
            top: 0,
            left: 0,
            padding: 0,
            borderRadius: '10px 0px 0px 0px',
            width: 135,
            textAlign: 'center'
          }}
        >
          <span>Usado</span>
        </div>
      );
    }

    return (
      <div style={{ backgroundColor: LIGHT_GRAY, position: 'absolute', top: 0, left: 0, paddingInline: 15, paddingBlock: 5, borderRadius: '0px 0px 10px 0px', }} >
        <span>USADO</span>
      </div>
    );
  }

  renderEmpty() {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 20 }}>
        <p>No hay datos para mostrar, prueba cambiando los filtros.</p>
      </div>
    )
  }

  renderPlaceHolders() {
    const items = 5;
    let content = [];

    for (let index = 0; index < items; index++) {
      content.push(
        <Segment key={index} raised style={{ height: 200, width: 200, margin: 0, borderRadius: 15 }}>
          <Placeholder>
            <PlaceholderImage />
            <PlaceholderLine />
            <PlaceholderLine />
            <PlaceholderLine />
          </Placeholder>
        </Segment>
      )
    };

    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 20 }}>
        {content}
      </div>
    );
  }

  render() {
    const { items, title, mobile, loading, isValid, score, selectedProducts } = this.props;
    const { isTopeLeft, isTopeRight } = this.state;
    let riskArray = [];

    if (score) {
      riskArray = items.filter(phone => phone.risk_level <= score.score)
    }

    if (!items.length && !loading) {
      return this.renderEmpty();
    }

    if (loading) {
      return this.renderPlaceHolders();
    }

    let minThree = false;

    if (items.length > 6 && !mobile) {
      minThree = true;
    }

    if (mobile) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30 }}>
          {items.map((item, i) =>
            <div
              key={i}
              onClick={() => isValid ? null : this.props.onDetailClick(item)}
              style={{ border: 'solid 1px #E6E6E6', backgroundColor: WHITE, marginBottom: 10, borderRadius: 10, height: 350, minWidth: 170 }}
            >
              <div
                style={{ marginRight: items.length === i + 1 ? 0 : 10, padding: 10 }}
              >
                <Image centered style={{ height: 300, objectFit: 'cover' }} src={item.image_4 || item.logo} />
                { this.renderUsedLabel(item) }
              </div>
              <div style={{ background: GRADIENT_TO_BOTTOM_LIGHT_PURPLE, borderRadius: '0px 0px 10px 10px', boxShadow: CONTAINERS_BOX_SHADOW,padding: 5, color: WHITE }}>
                <div style={{ textAlign: 'left', textDecoration: 'none', fontWeight: 'bold' }}>
                  <p>
                    { formatter.subString(item.name, 35) }
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }

    return (
      <div>
        <div style={{ marginBottom: 10 }}>
          <span style={{ fontWeight: 'bold', color: 'black' }}>{title}</span>
        </div>

        <div style={{ position: 'relative', display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: minThree ? 'flex-start' : 'space-between' }}>
          {minThree ? (
            <div style={{ position: 'absolute', left: -23, zIndex: 100 }}>
              <Icon circular name='angle left' size='large' color='blue' style={{ cursor: 'pointer', marginRight: 20, backgroundColor: WHITE }} onClick={this.onClickPrev.bind(this)} disabled={isTopeLeft} />
            </div>
          ) : null}

          <div className='hide-scroll' id='containerImages' style={{ display: 'flex', overflowX: 'auto', scrollBehavior: 'smooth' }}>
            {items.map((item, i) =>
              <div
                key={i}
                style={{
                  border: 'solid 1px #E6E6E6',
                  backgroundColor: WHITE,
                  borderRadius: 10,
                  marginRight: items.length === i + 1 ? 0 : 10,
                  marginBottom: 10
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingInline: 5, paddingTop: 5 }}>
                  <span>
                    { formatter.subString(item.name, 35) }
                  </span>
                  <Button
                    compact
                    circular
                    icon='horizontal ellipsis'
                    onClick={() => this.props.onDetailClick(item)}
                  />
                </div>

                <div
                  style={{
                    height: 200,
                    width: 177,
                    minWidth: 170,
                    padding: 10,
                    position: 'relative'
                  }}
                >
                  <Image
                    height={125}
                    centered
                    style={{ height: '100%', objectFit: 'cover' }}
                    src={item.image_4 || item.logo}
                  />

                  {/* { this.renderUsedLabel(item) } */}
                </div>

                <div style={{ background: riskArray && riskArray.length && !riskArray.includes(item) ? GRADIENT_TO_BOTTOM_DARK_RED : GRADIENT_TO_BOTTOM_DARK_BLUE, borderRadius: '0px 0px 10px 10px', padding: 5, color: WHITE, cursor: 'pointer', opacity: selectedProducts && selectedProducts.length && selectedProducts.includes(item) ? 0.7 : null }}
                  onClick={() => !isValid ? null : this.props.onSelectionClick(item)}
                >
                  <div style={{ textAlign: 'center', textDecoration: 'none', fontWeight: 'bold' }}>
                    <p>
                      { formatter.capitalize('Selección') }
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          {minThree ? (
            <div style={{ position: 'absolute', right: -28, zIndex: 100, borderRadius: 50 }}>
              <Icon
                circular
                name='angle right'
                size='large'
                color='blue'
                disabled={isTopeRight}
                style={{
                  cursor: 'pointer',
                  marginLeft: 20,
                  backgroundColor: WHITE
                }}
                onClick={this.onClickNext.bind(this)}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}


ItemsCaroussel.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  onDetailClick: PropTypes.func,
  onSelectionClick: PropTypes.func,
  mobile: PropTypes.bool,
  loading: PropTypes.bool,
  isValid: PropTypes.bool,
  score: PropTypes.object,
  selectedProducts: PropTypes.array
};


export { ItemsCaroussel };
