import axios from 'axios';
import { API_URL } from '../../../../utils/constants';

//* Vistas que lo utilizan

// Guarda una nueva recuperación
//* Warranty/Recoveries/AddRecoveries
export function saveRecovery(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/recoveries/save`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: body
  });
};

// Trae un listado con todas las recuperaciones registradas
//* Warranty/Recoveries/AddRecoveries
export function getAllRecoveries(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/recoveries/types/get-all`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: body
  });
};

// Trae los datos de una recuperación asociados al ID proporcionado
//* Warranty/Recoveries/Detail
export function getRecoveryById(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/recoveries/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae los datos de una recuperación asociados al IMEI proporcionado
//* Warranty/Recoveries
export function getRecoveryByImei(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/recoveries/by-imei`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: body
  });
};

// Trae los datos de la recuperación de un equipo mediante el IMEI asociado al equipo,
// en este worker se valida si un teléfono cuenta con una recuperación ó garantía de sucursal
//* Warranty/Recoveries
export function getRecoveryPhoneByIMEI(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/recoveries/phone/validate-search`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae el listado de URLs de los archivos asociados a una recuperación
//* Warranty/Recoveries/Documents
export function getRecoveryEvidence(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidence/recoveries/by-recovery`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Sube un archivo a la lista de evidencias de recuperación de un equipo
//* Warranty/Recoveries/Documents
export function uploadRecoveryEvidence(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidence/recoveries/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Descarga un archivo de evidencia de recuperación mediante la proporción de un URL
//* Warranty/Recoveries/Documents
export function getRecoveryEvidenceUrl(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/evidence/recoveries/downloadfile`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Modifica el status de una recuperación
//* Warranty/Recoveries/Detail
export function modRecoveryStatus(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/recoveries/mod-status`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae el listado de todos los registros de movimientos asociados a una recuperación
//* Warranty/Recoveries/Historic
export function getRecoveriesLogs(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/log/recoveries/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};






// Cambia el status de la recuperación
//! NO SE USA
export function changeRecoveryStatus(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/recoveries/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};
