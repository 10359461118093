import { call, put } from 'redux-saga/effects';
import * as calls from '../calls/index.js';
import * as actionsTypes from '../../store/types';
import notifications from '../../../../utils/notifications';
import eventManager from '../../../../utils/eventManager';


function fixBranches(branches){
  const branchesToShow = branches.map(element => {
    return {
      id: element.branch_id,
      text: `${element.postal_code} - ${element.name}`,
      value: element.branch_id,
      key: element.branch_id
    };
  });
  return branchesToShow;
};

export function* getUserDataBySession(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'userData', value: true } });

    const request = calls.getUserDataBySession(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'userData', value: false } });
      yield put({ type: actionsTypes.SET_USERDATA, payload: serverResponse.data });
      eventManager.emit('valid_token', serverResponse.data.token );
    } else {
      notifications.error('Error al traer datos de usuario');
      eventManager.emit('invalid_token');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'userData', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getBranches(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branches', value: true } });

    const request = calls.getBranches(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      const fixedArray = fixBranches(serverResponse.data);
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branches', value: false } });
      yield put({ type: actionsTypes.SET_BRANCHES, payload: fixedArray });
    } else {
      notifications.error('Sucursales no encontradas');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'branches', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getDashboardStats(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'dashboardStats', value: true } });

    const request = calls.getDashboardStats(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'dashboardStats', value: false } });
      yield put({ type: actionsTypes.SET_DASHBOARD_STATS, payload: serverResponse.data });
    } else {
      notifications.error('Documentos no encontrados');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'dashboardStats', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* getSepomexInfo(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'sepomexInfo', value: true } });

    const request = calls.getSepomexInfo(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'sepomexInfo', value: false } });
      yield put({ type: actionsTypes.SET_SEPOMEX_INFO, payload: { data: serverResponse.data, id: action.payload.id } });
    } else {
      notifications.error('Error al traer informacion');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'sepomexInfo', value: false } });
  } catch (error) {
    notifications.error('Error al traer informacion');
  };
};

export function* getOtp(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'gettingOTP', value: true } });

    const request = calls.getOtp(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'gettingOTP', value: false } });
      eventManager.emit('otp_recived', serverResponse.data);
      notifications.success('Token enviado exitosamente')
    } else {
      notifications.error('Error al enviar el token');
    };

    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'gettingOTP', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};

export function* validateOtp(action) {
  try {
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'checkingOTP', value: true } });

    const request = calls.validateOtp(action.payload);
    const response = yield call(request);
    const serverResponse = response.data;

    if (serverResponse.isValid) {
      yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'checkingOTP', value: false } });
      eventManager.emit('valid_otp', serverResponse.data);
      notifications.success('Token correcto')
    } else {
      notifications.error('Token incorrecto');
    };
    yield put({ type: actionsTypes.SET_LOADING, payload: { prop: 'checkingOTP', value: false } });
  } catch (error) {
    console.log(error.message);
  };
};
