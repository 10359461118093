import axios from 'axios';
import { API_URL } from '../../../../utils/constants';

export function getSuppliersTypes() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/suppliers/type/get-all`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

export function saveSupplierType(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/type/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getSuppliersCategories(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/category/by-type`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getSuppliersSubcategories(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/subcategory/by-category`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getAllSuppliersCategories(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/suppliers/category/get-all`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function saveSupplierCategory(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/category/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getSupplierCategoryDetail(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/category/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function getAllSuppliersSubCategories(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/suppliers/subcategory/get-all`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function saveSupplierSubCategory(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/subcategory/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function deleteSupplierType(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/type/delete`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function deleteSupplierCategory(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/category/delete`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

export function deleteSupplierSubCategory(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/suppliers/subcategory/delete`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};
