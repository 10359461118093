import * as actionsTypes from './types';
import { MOBILE_BREAKPOINT } from '../../../utils/constants';


const INITIAL_STATE = {
  scrollPosition: 0,
  mobile: window.innerWidth < MOBILE_BREAKPOINT,
  incode: null,
  incodeSession: null
};


export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case actionsTypes.SET_MOBILE:
    return { ...state, mobile: action.payload };
  case actionsTypes.SET_SCROLL_POSITION:
    return { ...state, scrollPosition: action.payload };
  case actionsTypes.SET_INCODE:
    return { ...state, incode: action.payload };
  case actionsTypes.SET_INCODE_SESSION:
    return { ...state, incodeSession: action.payload };

  default:
    return state;
  }
};
