import { takeLatest, all } from 'redux-saga/effects';
import * as actionsTypes from '../store/types';
import * as workers from './workers';

export default function* watcherSaga() {
  yield all([
    takeLatest(actionsTypes.GET_WARRANTY_INFO, workers.getWarrantyInfo),
    takeLatest(actionsTypes.GET_WARRANTY_DOCS, workers.getWarrantyDocs),
    takeLatest(actionsTypes.GET_WARRANTY_EVIDENCE_URL, workers.getWarrantyEvidenceUrl),
    takeLatest(actionsTypes.VALIDATE_REQUEST, workers.validateRequest),
    takeLatest(actionsTypes.GET_APPLICATION, workers.getApplicationData),
  ]);
}
