import axios from 'axios';
import { API_URL } from '../../../../utils/constants';

//* Vistas que lo utilizan

// Guarda los datos de un formulario de aplicación de financiamiento
//* Financing/AdditionalForm
export function saveAditionalForm(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/aditional-form/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Guarda un nuevo financiamiento
//* Financing/FinancingCalculator_v2/Detail
//* Warranty/ConfirmClient
//* Warranty/ConfirmClient/ClientNotFound
export function saveFinancingData(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/financings/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae los datos asociados a un financiamiento mediante la especificación de un IMEI
//* Sales/Payments
//* Warranty/AddRequest
//* Warranty/ConfirmClient
//* ConfirmClient
//* Warranty/Request
//* Warranty/Search
//* Warranty/Swap
export function getFinancingData(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/warranties/V2/get-financing-by-imei`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae la lista de bancos disponibles para pago con tarjeta
//* Sales/Payments
export function getBanksList() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/payments/get-banks`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};


// Trae los datos para el pago asociados a un financiamiento mediante la especificación de un IMEI
//* Sales/Payments
export function getFinancingDataPayment(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/financings/get-financing-by-imei-Payment`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};


// Trae los datos asociados a un financiamiento mediante la especificación de un IMEI
//* Warranty/Swap
export function getFinancingByImei(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/financings/get-financing-by-imei`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae los datos de los equipos enrrolados, si se manda un imei busca uno en específico
//* Warranty/Swap
export function getEnrrolledPhones(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/phones/getAllDivices`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Guarda un nuevo financiamiento
//* Financing/FinancingCalculator_v2/Detail
export function saveFinancingApplication(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/customers/aplication/save-phone`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Guarda un nuevo financiamiento
//* Financing/FinancingCalculator_v2/Detail
export function getFinancingApplication(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/customers/aplication/save-phone`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae los datos de los pagos asociados a un financiamiento mediante la especificación de un IMEI
//* Warranty/Swap
// export function getFinancingPayments(body) {
//   return () => axios({
//     method: 'post',
//     url: `${API_URL}/payments/payment-by-imei`,
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     data: body
//   });
// };

// Guarda un nuevo pago para un financiamiento
// export function savePayment(body) {
//   return () => axios({
//     method: 'post',
//     url: `${API_URL}/payments/save-payment`,
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     data: body
//   });
// };

// Trae los datos asociados a un financiamiento mediante diferentes datos
//* Sales/Payments
export function getFinancingTable(params) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/financings/get-financing-by-search?type=${params.type}&search_value=${params.search_value}`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

// Trae el detalle de los datos asociados a un financiamiento
//* Sales/Payments
export function getFinancingDetail(params) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/financings/get-financing-payment-info?financing_id=${params.financing_id}`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};
