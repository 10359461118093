import React, { Component, lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { Image } from 'semantic-ui-react';
import NotificationSystem from 'react-notification-system';
import configureStore from '../configureStore';
import eventManager from '../utils/eventManager';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { SHOW_NOTIFICATION } from '../utils/constants';
import { WHITE } from '../colors';
import loader from '../assets/main-loader.gif';
import 'react-image-crop/dist/ReactCrop.css';
import { ImageCropper } from '../components';

// const DashboardLayout = lazy(() => import('../containers/dashboard/layout'));
const LoginLayout = lazy(() => import('../containers/login/layout'));
const WarrantyLayout = lazy(() => import('../containers/warranty/layout'));
const BranchLayout = lazy(() => import('../containers/branch/layout'));
const AdminLayout = lazy(() => import('../containers/admin/layout'));
const ColabLayout = lazy(() => import('../containers/colab/layout'));
const WebLayout = lazy(() => import('../containers/web/layout'));

const store = configureStore();

class App extends Component {
  componentDidMount() {
    this.callbackID = eventManager.on(SHOW_NOTIFICATION, (data) => {
      if (this.notificationSystem) {
        this.notificationSystem.addNotification({
          level: data.type,
          message: data.message
        });
      }
    });
  }

  componentWillUnmount() {
    eventManager.unsubscribe(SHOW_NOTIFICATION, this.callbackID);
  }

  renderFallbackView() {
    return (
      <div style={{ background: '#1A1A1A', color: WHITE, height: '100vh', width: '100%', position: 'relative' }}>
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Image
            src={loader}
            style={{ width: 200, height: 200 }}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <Suspense fallback={this.renderFallbackView()}>
            <Switch>
            <Route path="/warranty" render={(props) => <WarrantyLayout {...props} />} />
            <Route path="/branch" render={(props) => <BranchLayout {...props} />} />
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
            <Route path="/colab" render={(props) => <ColabLayout {...props} />} />
            <Route path="/seguimiento" render={(props) => <WebLayout {...props} />} />
            <Route path="/" render={(props) => <LoginLayout {...props} />} />
            {/* <Route path="/dashboard" render={(props) => <DashboardLayout {...props} />} /> */}
            </Switch>
          </Suspense>
        </Router>

        <ImageCropper />
        <NotificationSystem ref={(ref) => { this.notificationSystem = ref; }} />
      </Provider>
    );
  }
}



export default App;
