import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { CONTAINERS_BORDER, DARK_BLUE, WHITE } from '../colors';
import formatter from '../utils/formatter';
import { Button, Icon } from 'semantic-ui-react';

const styles = {
  sidebar: {
    zIndex: -100,
    width: 600,
    height: '100%',
    padding: 0,
    right: 0,
    position: 'fixed',
    background: WHITE,
    top: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    transition: 'all 600ms ease-in-out',
    opacity: 0,
    transform: 'translateX(100%)'
  },
  headerContainer: {
    border: CONTAINERS_BORDER,
    left: 0,
    top: 0,
    position: 'sticky',
    width: '100%',
    zIndex: 100,
    background: WHITE
  },
  header: {
    fontFamily: 'roboto-condensed-regular',
    fontSize: 28,
    background: '#F5F8F9',
    padding: '15px 10px',
    width: '100%'
  },
  footer: {
    border: CONTAINERS_BORDER,
    bottom: 0,
    position: 'fixed',
    padding: 10,
    width: '100%',
    zIndex: 100,
    background: WHITE
  },
  closeButton: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    textAlign: 'center',
    fontSize: 25,
    marginTop: 16,
    marginRight: 15,
    position: 'absolute',
    top: 5,
    right: 10,
    cursor: 'pointer'
  }
};

class SidebarModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      contentVisible: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { clean } = formatter;
    return !isEqual(nextState, this.state) || !isEqual(clean(nextProps), clean(this.props));
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.toogleSidebar();
    }

    if (!this.props.visible && prevProps.visible) {
      this.toogleSidebar();
    }
  }

  toogleSidebar() {
    const { contentVisible } = this.state;

    if (!contentVisible) {
      this.setState({ contentVisible: true });
    } else {
      this.setState({ contentVisible: false });
    }
  }

  render() {
    const { contentVisible } = this.state;
    const { children, onClose, onClickActionButton, visible } = this.props;

    const sidebarStyle = { ...styles.sidebar, ...this.props.style };
    let overlayStyle = {};

    if (contentVisible) {
      sidebarStyle.transform = 'translateX(0%)';
      sidebarStyle.opacity = 1;
      sidebarStyle.zIndex = 1000000;

      overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: contentVisible ? 999 : -1,
        transition: 'background-color 600ms ease-in-out'
      };
    }

    return (
      <div>
        <div style={overlayStyle} onClick={onClose} />

        <div style={sidebarStyle}>
          <div style={styles.headerContainer}>
            <div style={styles.header}>
              <p style={{ color: DARK_BLUE, fontFamily: 'poppins-bold', marginLeft: 20 }}>{this.props.title}</p>
            </div>

            <div onClick={onClose} style={styles.closeButton}>
              <Icon name='close' />
            </div>
          </div>

          <div style={{ padding: '20px 10px' }}>
            {visible ? children : null}
          </div>

          <div style={styles.footer}>
            <Button
              style={{ width: 145, float: 'right', backgroundColor: DARK_BLUE, color: WHITE }}
              onClick={onClickActionButton}
              loading={this.props.loading}
            >
              {this.props.labelActionButton}
            </Button>
          </div>
        </div>
      </div>
    );
  }
};

SidebarModal.propTypes = {
  visible: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.any,
  onClose: PropTypes.any,
  onClickActionButton: PropTypes.func,
  labelActionButton: PropTypes.string,
  title: PropTypes.any,
  loading: PropTypes.bool,

};

export { SidebarModal };
