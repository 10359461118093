
export default () => {
  if (global.window) {
    return global.window;
  }

  return {
    localStorage: {
      getItem() {},
      setItem() {},
      removeItem() {}
    },
    document: {
      documentElement: {},
      getElementById() {}
    },
    scrollTo() {}
  };
};
