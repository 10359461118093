import axios from 'axios';
import { API_URL } from '../../../../utils/constants';


export function getEmailsUsersDetail() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/users/emails/get-all`,
    headers: {
      'Content-Type': 'application/json'
    },

  });
};

export function saveEmailUser(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/users/emails/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

