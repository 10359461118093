import axios from 'axios';
import { API_URL } from '../../../../utils/constants';

//* Vistas que lo utilizan

// Trae todos los equipos del catálogo
//* Financing/FinancingCalculator
//* Financing/FinancingCalculator_v2
//* Sales/Sale
export function getPhonesCat() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-financing`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

//* Warranty/Branch
//* Warranty/Recoveries
export function getPhonesFullCat() {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/get-all-phones`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

// Trae los datos de un equipo mediante su IMEI asociado
//* Warranty/ConfirmClient/ClientNotFound
//* Warranty/ConfirmClient
//* Warranty/Recoveries/AddRecoveries
//* Warranty/Swap
export function getPhoneByImei(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/phones/by-imei`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae los datos de un equipo del catálogo mediante su SKU asociado
//* Financing/FinancingCalculator
//* Financing/FinancingCalculator_v2
export function getPhonesArray(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/array/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae los datos de un equipo mediante su ID asociado
//* Financing/FinancingCalculator
//* Financing/FinancingCalculator_v2
//* Financing/Identification/Biometrics
//* Warranty/ConfirmClient
//* Warranty/ConfirmClient/ClientNotFound
export function getPhoneDetail(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/by-id`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae todos los equipos del catálogo por nivel de riesgo
//* Financing/FinancingCalculator_v2
export function getPhonesByScore(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/by-risk`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { risk: body.risk }
  });
};

// Trae los equipos del catálogo que pertenezcan a la marca especificada
//* Warranty/ConfirmClient
//* Warranty/ConfirmClient/ClientNotFound
//* Warranty/ConfirmClient/ConfirmClient
export function getPhonesByBrand(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/by-brand`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae el listado de todas las marcas de los equipos del catálogo
//* Financing/FinancingCalculator
//* Financing/FinancingCalculator_v2
//* Warranty/Branch
//* Warranty/ConfirmClient
//* Warranty/Recoveries
export function getPhonesBrandsCat() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-brand`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

export function getPhonesBrandsWarr() {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-brand-warranty`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};

// Trae el listado de todas las capacidades de memoria de los equipos del catálogo
//* Financing/FinancingCalculator
//* Financing/FinancingCalculator_v2
export function getPhonesStoragesCat(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-rom`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae el listado de todas las resoluciones de cámara/s traseras de los equipos del catálogo
//* Financing/FinancingCalculator
//* Financing/FinancingCalculator_v2
export function getPhonesRearCamerasCat(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-rear-camera`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae el listado de todas las resoluciones de cámara/s frontales de los equipos del catálogo
//* Financing/FinancingCalculator
//* Financing/FinancingCalculator_v2
export function getPhonesFrontalCamerasCat(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-frontal-camera`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae el listado de todos los tipos de SIM de los equipos del catálogo
//* Financing/FinancingCalculator
//* Financing/FinancingCalculator_v2
export function getPhonesSIMsCat(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-sim`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae el listado de todos los sistemas operativos de los equipos del catálogo
//* Financing/FinancingCalculator
//* Financing/FinancingCalculator_v2
export function getPhonesOSsCat(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-os`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae el listado de todos los tamaños de pantalla de los equipos del catálogo
//* Financing/FinancingCalculator
//* Financing/FinancingCalculator_v2
export function getPhonesScreensCat(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-screen`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae el listado de todos los tipos de cobertura movil de los equipos del catálogo
//* Financing/FinancingCalculator
//* Financing/FinancingCalculator_v2
export function getPhonesServicesCat(body) {
  return () => axios({
    method: 'get',
    url: `${API_URL}/cat-phones/get-all-service`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Guarda un nuevo teléfono
//* Financing/FinancingCalculator/Detail
//* Financing/FinancingCalculator_v2/Detail
//* Warranty/Branch
//* Warranty/Recoveries
export function savePhone(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/phones/save`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: body
  });
};

// Guarda un nuevo teléfono
//* Warranty/ConfirmClient
//* Warranty/ConfirmClient/ClientNotFound
export function savePhoneData(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/phones/save`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};





// Trae los equipos del catálogo que pertenezcan a la marca especificada
//! NO SE USA
export function getCategoryPhonesByBrand(body) {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/by-brand`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  });
};

// Trae todos los equipos del catálogo por nivel de riesgo
//! NO SE USA
export function getPhonesCatByRisk() {
  return () => axios({
    method: 'post',
    url: `${API_URL}/cat-phones/by-risk`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
};
